.CallBtn-con{
    /* border: 1px solid red; */
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    color: white !important;
    position: fixed;
  }
  
  .CallBtn{
    /* border: 1px solid blue; */
    padding: 12px 14px;
    background-color:#25D366;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .CallBtn a{
    text-decoration: none;
    color: white !important;
  }
  
  .CallBtn a svg{
  font-size: 25px;
  }
  .whatsapp-svg{
    color: white;
  }
