 .navigation li a {
    position: relative;
    display: block;
    text-align: left;
    text-transform: uppercase;
    opacity: 1;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 28px;
    color: #242424;
    font-weight: 700;
    font-family: "Roboto";
}

.navigation li a:hover {
    color: var(--theme-color);
}