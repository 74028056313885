.news-block .inner-box {
    border-radius: 10px;
}
.news-block .lower-content {
    padding: 25px 35px 40px;
    margin-left: 25px;
    filter: drop-shadow(0px 12px 12.5px rgba(0,0,0,0.04));
    background-color: #ffffff;
    margin-top: -50px;
    border-radius: 10px;

}