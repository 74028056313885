.Errorpage {
    align-items: center;
    background: rgba(0,0,0,.81);
    display: flex;
    font-family: Arvo,serif;
    justify-content: center;
    min-height: 100vh;
    transition: 1s;
}
.error {
    -webkit-animation: fadein 1.2s ease-in;
    animation: fadein 1.2s ease-in;
    box-shadow: 0 5px 0 -2px #EABC4C;
    color: #EABC4C;
    text-align: center;
}
.error>.code {
    font-size: 10.5em;
    margin: 0;
    text-shadow: 0 6px 1px rgba(0,0,0,.098), 0 0 5px rgba(0,0,0,.098), 0 1px 3px rgba(0,0,0,.298), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.247), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.149);
}
.error>.desc {
    font-weight: 400;
    text-shadow: 0 3px 5px rgba(0,0,0,.5), 0 6px 20px rgba(0,0,0,.3);
}
h2{
    margin-top: 60px;
    color: white;
}
a{
    color: white;
}