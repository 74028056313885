/* ========= smile-section ======= */
/* .smile-section{
    background-color: #f2f2f2 ;
  }
   */
   .smile-img{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .smile-img img{
    width: 100%;
    height: 100%;
    min-width: 120px;
    min-height: 120px;
    border-radius: 10px;
  }
  
  .smile-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 10px;
  }
  .smile-icon svg{
    font-size: 50px;
    color: var(---lightColor);
  }
  
  .smile-img:hover .smile-icon {
    opacity: 1;
  }
  .sec-title .lower-text{
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 1.7em;
    font-weight: 400;
    color: #9b9fa6;
    padding: 25px 0px 0px;
  }
  .sec-title.centered{
    max-width: 550px;
    margin: 0 auto;
    margin-bottom: 60px;
    text-align: center;
  }
  