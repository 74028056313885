.float {
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 26px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  
  .my-float {
    margin-top: 16px;
  }
  
  /* Style for Instagram icon */
  .float.instagram {
    background-color: #e4405f; /* Instagram color */
  }
  
  /* Style for Facebook icon */
  .float.facebook {
    background-color: #3b5998; /* Facebook color */
  }
  
  /* WhatsApp icon style (unchanged) */
  .float.whatsapp {
    background-color: #25d366;
  }
  
  /* Hover effect for all icons */
  .float:hover {
    background-color: #128c7e; /* WhatsApp hover color for example */
  }
  
  /* Additional margin for Instagram and Facebook icons */
  .float.instagram .my-float,
  .float.facebook .my-float {
    margin-top: 14px;
  }
  .float-facebook{
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 125px;
    right: 40px;
    background-color: #0866FF;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 26px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  .float-instagram{
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 70px;
    right: 40px;
    background-color: #DD2A7B;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 26px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  .float-whatsapp{
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 9px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 26px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
/* .flot-icons{
  visibility: hidden;
} */
/* @media (max-width:992px) { */
  .flot-icons{
    visibility: visible  !important;
    color: #fff;
  }
/* } */
.my-float svg{
  color: #fff !important;
}