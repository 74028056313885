.cofeemargin{
    margin-top: 200px;
    margin-bottom: 100px;
    /* color: ; */
    
    
    }
    .hade1{
        margin-top: 90px;
     
        color: black;
    }
    .border1{
        background-color: #EABC4C;
    }
    .back11{
        background-color: #eb5b9a;
        margin: 23px;
        color: white;
    }
    .codfeimage{
    width: 100%;
    }
    @media (max-width: 535px) {
        .back11{
            margin: 0px;
      margin-top: 10px;
         
        }
        .codfeimage{
            width: 100%;
            margin-top: 10px;
            }
      }
      .list-group{
        background-color: #EABC4C !important;
      }
      .list-group-numbered{
        font-size: 21px;
      }