/* bootstrap */
@charset "UTF-8";
/* @import url('fontawesome-all.css'); */
/* @import url('animate.css'); */
/* @import url('custom-animate.css'); */
/* @import url('jquery-ui-1.9.2.custom.min.css'); */
/* @import url('nice-select.css'); */
/* @import url('flaGlacialIndifference-Regular.otticon.css'); */
/* @import url('owl.css'); */
/* @import url('jquery.fancybox.min.css'); */
/* @import url('scrollbar.css'); */
/* @import url('swiper.min.css'); */
/* @import url('rtl.css'); */
/* @import url('elpath.css'); */
/* # sourceMappingURL=bootstrap.css.map
color.css */

/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)

 */
 :root {
  --swiper-theme-color: #EABC4C !important;
}
 :root {
  ---font1: "font-family: 'Playfair Display', serif";
  ---font2:"font-family: GlacialIndifference-Regular";
  ---title-color:#0e2a4e;
  ---head-title-color:#ffc107;
 }
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff !important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
  color: var(--bs-yellow);
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 1px;
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  vertical-align: middle;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: none;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0a58ca;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[style] {
  right: auto !important;
}

.dropdown-menu-start {
  --bs-position: start;
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem /* rtl:ignore */;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button.collapsed {
  border-bottom-width: 0;
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[data-popper-placement^=top] {
  margin-bottom: 0.5rem !important;
}
.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end, .bs-popover-auto[data-popper-placement^=right] {
  margin-left: 0.5rem !important;
}
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[data-popper-placement^=bottom] {
  margin-top: 0.5rem !important;
}
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start, .bs-popover-auto[data-popper-placement^=left] {
  margin-right: 0.5rem !important;
}
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators li {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }

  .fs-sm-1 {
    font-size: 2.5rem !important;
  }

  .fs-sm-2 {
    font-size: 2rem !important;
  }

  .fs-sm-3 {
    font-size: 1.75rem !important;
  }

  .fs-sm-4 {
    font-size: 1.5rem !important;
  }

  .fs-md-1 {
    font-size: 2.5rem !important;
  }

  .fs-md-2 {
    font-size: 2rem !important;
  }

  .fs-md-3 {
    font-size: 1.75rem !important;
  }

  .fs-md-4 {
    font-size: 1.5rem !important;
  }

  .fs-lg-1 {
    font-size: 2.5rem !important;
  }

  .fs-lg-2 {
    font-size: 2rem !important;
  }

  .fs-lg-3 {
    font-size: 1.75rem !important;
  }

  .fs-lg-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}


:root {
    --theme-color: #EABC4C !important;;
}
/* elpath.css */
/* Elementpath css */

/* 
el_01 
color
*/
.color_blue {
    color: #0d6efd;
}
.color_purple {
    color: #6f42c1;
}
.color_pink {
    color: #d63384;
}
.color_red {
    color: #dc3545;
}
.color_orange {
    color: #fd7e14;
}
.color_yellow {
    color: #ffc107;
}
.color_green {
    color: #198754;
}
.color_gray {
    color: #6c757d;
}
.color_gray_dark {
    color: #343a40;
}
.color_light {
    color: #f8f9fa;
}
.color_dark {
    color: #212529;
}
/* 
el_02 
background
*/
.bg_blue {
    background-color: #0d6efd;
}
.bg_purple {
    background-color: #6f42c1;
}
.bg_pink {
    background-color: #d63384;
}
.bg_red {
    background-color: #dc3545;
}
.bg_orange {
    background-color: #fd7e14;
}
.bg_yellow {
    background-color: #ffc107;
}
.bg_green {
    background-color: #198754;;
}
.bg_gray {
    background-color: #6c757d;
}
.bg_gray-dark {
    background-color: #343a40;
}
.bg_light {
    background-color: #f8f9fa;
}
.bg_dark {
    background-color: #212529;
}
.bg_cover {
    background-size: cover;
}
.bg_contain {
    background-size: contain;
}
.bg_fixed {
    background-attachment: fixed;
}
.bg_top {
    background-position: top;
}
.bg_right {
    background-position: right;
}
.bg_bottom {
    background-position: bottom;
}
.bg_left {
    background-position: left;
}
.bg_center {
    background-position: center;
}
/* 
el_03 
display
*/
.d_block {
    display: block;
}
.d_iblock {
    display: inline-block;
}
.d_flex {
    display: flex;
}
.d_table {
    display: table;
}
.d_tcell {
    display: table-cell;
}
.d_none {
    display: none;
}
/* 
el_04 
position
*/
.p_relative {
    position: relative;
}
.p_absolute {
    position: absolute;
}
.p_fixed {
    position: fixed;
}
/* 
el_05 
Border
*/
.border {
    border: 1px solid #E3E3E3;
}
.border_top {
    border-top: 1px solid #E3E3E3;
}
.border_right {
    border-right: 1px solid #E3E3E3;
}
.border_bottom {
    border-bottom: 1px solid #E3E3E3;
}
.border_left {
    border-left: 1px solid #E3E3E3;
}
/* 
el_06 
Transition
*/
.tran_3 {
    transition: .3s;
}
.tran_4 {
    transition: .4s;
}
.tran_5 {
    transition: .5s;
}
/* 
el_07 
Font size
*/
.fs_0 {
    font-size: 0;
}
.fs_1 {
    font-size: 1px;
}
.fs_2 {
    font-size: 2px;
}
.fs_3 {
    font-size: 3px;
}
.fs_4 {
    font-size: 4px;
}
.fs_5 {
    font-size: 5px;
}
.fs_6 {
    font-size: 6px;
}
.fs_7 {
    font-size: 7px;
}
.fs_8 {
    font-size: 8px;
}
.fs_9 {
    font-size: 9px;
}
.fs_10 {
    font-size: 10px;
}
.fs_11 {
    font-size: 11px;
}
.fs_12 {
    font-size: 12px;
}
.fs_13 {
    font-size: 13px;
}
.fs_14 {
    font-size: 14px;
}
.fs_15 {
    font-size: 15px;
}
.fs_16 {
    font-size: 16px;
}
.fs_17 {
    font-size: 17px;
}
.fs_18 {
    font-size: 18px;
}
.fs_19 {
    font-size: 19px;
}
.fs_20 {
    font-size: 20px;
}
.fs_21 {
    font-size: 21px;
}
.fs_22 {
    font-size: 22px;
}
.fs_23 {
    font-size: 23px;
}
.fs_24 {
    font-size: 24px;
}
.fs_25 {
    font-size: 25px;
}
.fs_26 {
    font-size: 26px;
}
.fs_27 {
    font-size: 27px;
}
.fs_28 {
    font-size: 28px;
}
.fs_29 {
    font-size: 29px;
}
.fs_30 {
    font-size: 30px;
}
.fs_31 {
    font-size: 31px;
}
.fs_32 {
    font-size: 32px;
}
.fs_33 {
    font-size: 33px;
}
.fs_34 {
    font-size: 34px;
}
.fs_35 {
    font-size: 35px;
}
.fs_36 {
    font-size: 36px;
}
.fs_37 {
    font-size: 37px;
}
.fs_38 {
    font-size: 38px;
}
.fs_39 {
    font-size: 39px;
}
.fs_40 {
    font-size: 40px;
}
.fs_41 {
    font-size: 41px;
}
.fs_42 {
    font-size: 42px;
}
.fs_43 {
    font-size: 43px;
}
.fs_44 {
    font-size: 44px;
}
.fs_45 {
    font-size: 45px;
}
.fs_46 {
    font-size: 46px;
}
.fs_47 {
    font-size: 47px;
}
.fs_48 {
    font-size: 48px;
}
.fs_49 {
    font-size: 49px;
}
.fs_50 {
    font-size: 50px;
}
.fs_55 {
    font-size: 55px;
}
.fs_60 {
    font-size: 60px;
}
.fs_65 {
    font-size: 65px;
}
.fs_70 {
    font-size: 70px;
}
.fs_75 {
    font-size: 75px;
}
.fs_80 {
    font-size: 80px;
}
.fs_85 {
    font-size: 85px;
}
.fs_90 {
    font-size: 90px;
}
.fs_95 {
    font-size: 95px;
}
.fs_100 {
    font-size: 100px;
}
.fs_110 {
    font-size: 110px;
}
.fs_120 {
    font-size: 120px;
}
.fs_130 {
    font-size: 130px;
}
.fs_140 {
    font-size: 140px;
}
.fs_150 {
    font-size: 150px;
}
.fs_160 {
    font-size: 160px;
}
.fs_170 {
    font-size: 170px;
}
.fs_180 {
    font-size: 180px;
}
.fs_190 {
    font-size: 190px;
}
.fs_200 {
    font-size: 200px;
}
/* 
el_08 
Font wieght
*/
.fw_light {
    font-weight: 300;
}
.fw_normal {
    font-weight: 400;
}
.fw_medium {
    font-weight: 500;
}
.fw_sbold {
    font-weight: 600;
}
.fw_bold {
    font-weight: 700;
}
.fw_exbold {
    font-weight: 800;
}
/* 
el_09 
Float
*/
.float_left {
    float: left;
}
.float_right {
    float: right;
}
/* 
el_10 
Width
*/
.w_0 {
    width: 0;
}
.w_1 {
    width: 1px;
}
.w_2 {
    width: 2px;
}
.w_3 {
    width: 3px;
}
.w_4 {
    width: 4px;
}
.w_5 {
    width: 5px;
}
.w_6 {
    width: 6px;
}
.w_7 {
    width: 7px;
}
.w_8 {
    width: 8px;
}
.w_9 {
    width: 9px;
}
.w_10 {
    width: 10px;
}
.w_11 {
    width: 11px;
}
.w_12 {
    width: 12px;
}
.w_13 {
    width: 13px;
}
.w_14 {
    width: 14px;
}
.w_15 {
    width: 15px;
}
.w_16 {
    width: 16px;
}
.w_17 {
    width: 17px;
}
.w_18 {
    width: 18px;
}
.w_19 {
    width: 19px;
}
.w_20 {
    width: 20px;
}
.w_25 {
    width: 25px;
}
.w_30 {
    width: 30px;
}
.w_35 {
    width: 35px;
}
.w_40 {
    width: 40px;
}
.w_45 {
    width: 45px;
}
.w_50 {
    width: 50px;
}
.w_55 {
    width: 55px;
}
.w_60 {
    width: 60px;
}
.w_65 {
    width: 65px;
}
.w_70 {
    width: 70px;
}
.w_75 {
    width: 75px;
}
.w_80 {
    width: 80px;
}
.w_85 {
    width: 85px;
}
.w_90 {
    width: 90px;
}
.w_95 {
    width: 95px;
}
.w_100 {
    width: 100px;
}
.w_110 {
    width: 110px;
}
.w_120 {
    width: 120px;
}
.w_130 {
    width: 130px;
}
.w_140 {
    width: 140px;
}
.w_150 {
    width: 150px;
}
.w_160 {
    width: 160px;
}
.w_170 {
    width: 170px;
}
.w_180 {
    width: 180px;
}
.w_190 {
    width: 190px;
}
.w_200 {
    width: 200px;
}
.mw_0 {
    max-width: 0;
}
.mw_1 {
    max-width: 1px;
}
.mw_2 {
    max-width: 2px;
}
.mw_3 {
    max-width: 3px;
}
.mw_4 {
    max-width: 4px;
}
.mw_5 {
    max-width: 5px;
}
.mw_6 {
    max-width: 6px;
}
.mw_7 {
    max-width: 7px;
}
.mw_8 {
    max-width: 8px;
}
.mw_9 {
    max-width: 9px;
}
.mw_10 {
    max-width: 10px;
}
.mw_11 {
    max-width: 11px;
}
.mw_12 {
    max-width: 12px;
}
.mw_13 {
    max-width: 13px;
}
.mw_14 {
    max-width: 14px;
}
.mw_15 {
    max-width: 15px;
}
.mw_16 {
    max-width: 16px;
}
.mw_17 {
    max-width: 17px;
}
.mw_18 {
    max-width: 18px;
}
.mw_19 {
    max-width: 19px;
}
.mw_20 {
    max-width: 20px;
}
.mw_25 {
    max-width: 25px;
}
.mw_30 {
    max-width: 30px;
}
.mw_35 {
    max-width: 35px;
}
.mw_40 {
    max-width: 40px;
}
.mw_45 {
    max-width: 45px;
}
.mw_50 {
    max-width: 50px;
}
.mw_55 {
    max-width: 55px;
}
.mw_60 {
    max-width: 60px;
}
.mw_65 {
    max-width: 65px;
}
.mw_70 {
    max-width: 70px;
}
.mw_75 {
    max-width: 75px;
}
.mw_80 {
    max-width: 80px;
}
.mw_85 {
    max-width: 85px;
}
.mw_90 {
    max-width: 90px;
}
.mw_95 {
    max-width: 95px;
}
.mw_100 {
    max-width: 100px;
}
.mw_110 {
    max-width: 110px;
}
.mw_120 {
    max-width: 120px;
}
.mw_130 {
    max-width: 130px;
}
.mw_140 {
    max-width: 140px;
}
.mw_150 {
    max-width: 150px;
}
.mw_160 {
    max-width: 160px;
}
.mw_170 {
    max-width: 170px;
}
.mw_180 {
    max-width: 180px;
}
.mw_190 {
    max-width: 190px;
}
.mw_200 {
    max-width: 200px;
}
/* 
el_11
height
*/
.h_0 {
    height: 0;
}
.h_1 {
    height: 1px;
}
.h_2 {
    height: 2px;
}
.h_3 {
    height: 3px;
}
.h_4 {
    height: 4px;
}
.h_5 {
    height: 5px;
}
.h_6 {
    height: 6px;
}
.h_7 {
    height: 7px;
}
.h_8 {
    height: 8px;
}
.h_9 {
    height: 9px;
}
.h_10 {
    height: 10px;
}
.h_11 {
    height: 11px;
}
.h_12 {
    height: 12px;
}
.h_13 {
    height: 13px;
}
.h_14 {
    height: 14px;
}
.h_15 {
    height: 15px;
}
.h_16 {
    height: 16px;
}
.h_17 {
    height: 17px;
}
.h_18 {
    height: 18px;
}
.h_19 {
    height: 19px;
}
.h_20 {
    height: 20px;
}
.h_25 {
    height: 25px;
}
.h_30 {
    height: 30px;
}
.h_35 {
    height: 35px;
}
.h_40 {
    height: 40px;
}
.h_45 {
    height: 45px;
}
.h_50 {
    height: 50px;
}
.h_55 {
    height: 55px;
}
.h_60 {
    height: 60px;
}
.h_65 {
    height: 65px;
}
.h_70 {
    height: 70px;
}
.h_75 {
    height: 75px;
}
.h_80 {
    height: 80px;
}
.h_85 {
    height: 85px;
}
.h_90 {
    height: 90px;
}
.h_95 {
    height: 95px;
}
.h_100 {
    height: 100px;
}
.h_110 {
    height: 110px;
}
.h_120 {
    height: 120px;
}
.h_130 {
    height: 130px;
}
.h_140 {
    height: 140px;
}
.h_150 {
    height: 150px;
}
.h_160 {
    height: 160px;
}
.h_170 {
    height: 170px;
}
.h_180 {
    height: 180px;
}
.h_190 {
    height: 190px;
}
.h_200 {
    height: 200px;
}
.mh_0 {
    max-height: 0;
}
.mh_1 {
    max-height: 1px;
}
.mh_2 {
    max-height: 2px;
}
.mh_3 {
    max-height: 3px;
}
.mh_4 {
    max-height: 4px;
}
.mh_5 {
    max-height: 5px;
}
.mh_6 {
    max-height: 6px;
}
.mh_7 {
    max-height: 7px;
}
.mh_8 {
    max-height: 8px;
}
.mh_9 {
    max-height: 9px;
}
.mh_10 {
    max-height: 10px;
}
.mh_11 {
    max-height: 11px;
}
.mh_12 {
    max-height: 12px;
}
.mh_13 {
    max-height: 13px;
}
.mh_14 {
    max-height: 14px;
}
.mh_15 {
    max-height: 15px;
}
.mh_16 {
    max-height: 16px;
}
.mh_17 {
    max-height: 17px;
}
.mh_18 {
    max-height: 18px;
}
.mh_19 {
    max-height: 19px;
}
.mh_20 {
    max-height: 20px;
}
.mh_25 {
    max-height: 25px;
}
.mh_30 {
    max-height: 30px;
}
.mh_35 {
    max-height: 35px;
}
.mh_40 {
    max-height: 40px;
}
.mh_45 {
    max-height: 45px;
}
.mh_50 {
    max-height: 50px;
}
.mh_55 {
    max-height: 55px;
}
.mh_60 {
    max-height: 60px;
}
.mh_65 {
    max-height: 65px;
}
.mh_70 {
    max-height: 70px;
}
.mh_75 {
    max-height: 75px;
}
.mh_80 {
    max-height: 80px;
}
.mh_85 {
    max-height: 85px;
}
.mh_90 {
    max-height: 90px;
}
.mh_95 {
    max-height: 95px;
}
.mh_100 {
    max-height: 100px;
}
.mh_110 {
    max-height: 110px;
}
.mh_120 {
    max-height: 120px;
}
.mh_130 {
    max-height: 130px;
}
.mh_140 {
    max-height: 140px;
}
.mh_150 {
    max-height: 150px;
}
.mh_160 {
    max-height: 160px;
}
.mh_170 {
    max-height: 170px;
}
.mh_180 {
    max-height: 180px;
}
.mh_190 {
    max-height: 190px;
}
.mh_200 {
    max-height: 200px;
}
/* 
el_12
Line height
*/
.lh_0 {
    line-height: 0;
}
.lh_1 {
    line-height: 1px;
}
.lh_2 {
    line-height: 2px;
}
.lh_3 {
    line-height: 3px;
}
.lh_4 {
    line-height: 4px;
}
.lh_5 {
    line-height: 5px;
}
.lh_6 {
    line-height: 6px;
}
.lh_7 {
    line-height: 7px;
}
.lh_8 {
    line-height: 8px;
}
.lh_9 {
    line-height: 9px;
}
.lh_10 {
    line-height: 10px;
}
.lh_11 {
    line-height: 11px;
}
.lh_12 {
    line-height: 12px;
}
.lh_13 {
    line-height: 13px;
}
.lh_14 {
    line-height: 14px;
}
.lh_15 {
    line-height: 15px;
}
.lh_16 {
    line-height: 16px;
}
.lh_17 {
    line-height: 17px;
}
.lh_18 {
    line-height: 18px;
}
.lh_19 {
    line-height: 19px;
}
.lh_20 {
    line-height: 20px;
}
.lh_25 {
    line-height: 25px;
}
.lh_30 {
    line-height: 30px;
}
.lh_35 {
    line-height: 35px;
}
.lh_40 {
    line-height: 40px;
}
.lh_45 {
    line-height: 45px;
}
.lh_50 {
    line-height: 50px;
}
.lh_55 {
    line-height: 55px;
}
.lh_60 {
    line-height: 60px;
}
.lh_65 {
    line-height: 65px;
}
.lh_70 {
    line-height: 70px;
}
.lh_75 {
    line-height: 75px;
}
.lh_80 {
    line-height: 80px;
}
.lh_85 {
    line-height: 85px;
}
.lh_90 {
    line-height: 90px;
}
.lh_95 {
    line-height: 95px;
}
.lh_100 {
    line-height: 100px;
}
.lh_110 {
    line-height: 110px;
}
.lh_120 {
    line-height: 120px;
}
.lh_130 {
    line-height: 130px;
}
.lh_140 {
    line-height: 140px;
}
.lh_150 {
    line-height: 150px;
}
.lh_160 {
    line-height: 160px;
}
.lh_170 {
    line-height: 170px;
}
.lh_180 {
    line-height: 180px;
}
.lh_190 {
    line-height: 190px;
}
.lh_200 {
    line-height: 200px;
}
/* 
el_13
Margin
*/
.m_0 {
    margin: 0;
}
.m_1 {
    margin: 1px;
}
.m_2 {
    margin: 2px;
}
.m_3 {
    margin: 3px;
}
.m_4 {
    margin: 4px;
}
.m_5 {
    margin: 5px;
}
.m_6 {
    margin: 6px;
}
.m_7 {
    margin: 7px;
}
.m_8 {
    margin: 8px;
}
.m_9 {
    margin: 9px;
}
.m_10 {
    margin: 10px;
}
.m_11 {
    margin: 11px;
}
.m_12 {
    margin: 12px;
}
.m_13 {
    margin: 13px;
}
.m_14 {
    margin: 14px;
}
.m_15 {
    margin: 15px;
}
.m_16 {
    margin: 16px;
}
.m_17 {
    margin: 17px;
}
.m_18 {
    margin: 18px;
}
.m_19 {
    margin: 19px;
}
.m_20 {
    margin: 20px;
}
.m_25 {
    margin: 25px;
}
.m_30 {
    margin: 30px;
}
.m_35 {
    margin: 35px;
}
.m_40 {
    margin: 40px;
}
.m_45 {
    margin: 45px;
}
.m_50 {
    margin: 50px;
}
.m_55 {
    margin: 55px;
}
.m_60 {
    margin: 60px;
}
.m_65 {
    margin: 65px;
}
.m_70 {
    margin: 70px;
}
.m_75 {
    margin: 75px;
}
.m_80 {
    margin: 80px;
}
.m_85 {
    margin: 85px;
}
.m_90 {
    margin: 90px;
}
.m_95 {
    margin: 95px;
}
.m_100 {
    margin: 100px;
}
.m_110 {
    margin: 110px;
}
.m_120 {
    margin: 120px;
}
.m_130 {
    margin: 130px;
}
.m_140 {
    margin: 140px;
}
.m_150 {
    margin: 150px;
}
.m_160 {
    margin: 160px;
}
.m_170 {
    margin: 170px;
}
.m_180 {
    margin: 180px;
}
.m_190 {
    margin: 190px;
}
.m_200 {
    margin: 200px;
}
.mx_0 {
    margin-left: 0;
    margin-right: 0;
}
.mx_1 {
    margin-left: 1px;
    margin-right: 1px;
}
.mx_2 {
    margin-left: 2px;
    margin-right: 2px;
}
.mx_3 {
    margin-left: 3px;
    margin-right: 3px;
}
.mx_4 {
    margin-left: 4px;
    margin-right: 4px;
}
.mx_5 {
    margin-left: 5px;
    margin-right: 5px;
}
.mx_6 {
    margin-left: 6px;
    margin-right: 6px;
}
.mx_7 {
    margin-left: 7px;
    margin-right: 7px;
}
.mx_8 {
    margin-left: 8px;
    margin-right: 8px;
}
.mx_9 {
    margin-left: 9px;
    margin-right: 9px;
}
.mx_10 {
    margin-left: 10px;
    margin-right: 10px;
}
.mx_11 {
    margin-left: 11px;
    margin-right: 11px;
}
.mx_12 {
    margin-left: 12px;
    margin-right: 12px;
}
.mx_13 {
    margin-left: 13px;
    margin-right: 13px;
}
.mx_14 {
    margin-left: 14px;
    margin-right: 14px;
}
.mx_15 {
    margin-left: 15px;
    margin-right: 15px;
}
.mx_16 {
    margin-left: 16px;
    margin-right: 16px;
}
.mx_17 {
    margin-left: 17px;
    margin-right: 17px;
}
.mx_18 {
    margin-left: 18px;
    margin-right: 18px;
}
.mx_19 {
    margin-left: 19px;
    margin-right: 19px;
}
.mx_20 {
    margin-left: 20px;
    margin-right: 20px;
}
.mx_25 {
    margin-left: 25px;
    margin-right: 25px;
}
.mx_30 {
    margin-left: 30px;
    margin-right: 30px;
}
.mx_35 {
    margin-left: 35px;
    margin-right: 35px;
}
.mx_40 {
    margin-left: 40px;
    margin-right: 40px;
}
.mx_45 {
    margin-left: 45px;
    margin-right: 45px;
}
.mx_50 {
    margin-left: 50px;
    margin-right: 50px;
}
.mx_55 {
    margin-left: 55px;
    margin-right: 55px;
}
.mx_60 {
    margin-left: 60px;
    margin-right: 60px;
}
.mx_65 {
    margin-left: 65px;
    margin-right: 65px;
}
.mx_70 {
    margin-left: 70px;
    margin-right: 70px;
}
.mx_75 {
    margin-left: 75px;
    margin-right: 75px;
}
.mx_80 {
    margin-left: 80px;
    margin-right: 80px;
}
.mx_85 {
    margin-left: 85px;
    margin-right: 85px;
}
.mx_90 {
    margin-left: 90px;
    margin-right: 90px;
}
.mx_95 {
    margin-left: 95px;
    margin-right: 95px;
}
.mx_100 {
    margin-left: 100px;
    margin-right: 100px;
}
.mx_110 {
    margin-left: 110px;
    margin-right: 110px;
}
.mx_120 {
    margin-left: 120px;
    margin-right: 120px;
}
.mx_130 {
    margin-left: 130px;
    margin-right: 130px;
}
.mx_140 {
    margin-left: 140px;
    margin-right: 140px;
}
.mx_150 {
    margin-left: 150px;
    margin-right: 150px;
}
.mx_160 {
    margin-left: 160px;
    margin-right: 160px;
}
.mx_170 {
    margin-left: 170px;
    margin-right: 170px;
}
.mx_180 {
    margin-left: 180px;
    margin-right: 180px;
}
.mx_190 {
    margin-left: 190px;
    margin-right: 190px;
}
.mx_200 {
    margin-left: 200px;
    margin-right: 200px;
}
.my_0 {
    margin-top: 0;
    margin-bottom: 0;
}
.my_1 {
    margin-top: 1px;
    margin-bottom: 1px;
}
.my_2 {
    margin-top: 2px;
    margin-bottom: 2px;
}
.my_3 {
    margin-top: 3px;
    margin-bottom: 3px;
}
.my_4 {
    margin-top: 4px;
    margin-bottom: 4px;
}
.my_5 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.my_6 {
    margin-top: 6px;
    margin-bottom: 6px;
}
.my_7 {
    margin-top: 7px;
    margin-bottom: 7px;
}
.my_8 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.my_9 {
    margin-top: 9px;
    margin-bottom: 9px;
}
.my_10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.my_11 {
    margin-top: 11px;
    margin-bottom: 11px;
}
.my_12 {
    margin-top: 12px;
    margin-bottom: 12px;
}
.my_13 {
    margin-top: 13px;
    margin-bottom: 13px;
}
.my_14 {
    margin-top: 14px;
    margin-bottom: 14px;
}
.my_15 {
    margin-top: 15px;
    margin-bottom: 15px;
}
.my_16 {
    margin-top: 16px;
    margin-bottom: 16px;
}
.my_17 {
    margin-top: 17px;
    margin-bottom: 17px;
}
.my_18 {
    margin-top: 18px;
    margin-bottom: 18px;
}
.my_19 {
    margin-top: 19px;
    margin-bottom: 19px;
}
.my_20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.my_25 {
    margin-top: 25px;
    margin-bottom: 25px;
}
.my_30 {
    margin-top: 30px;
    margin-bottom: 30px;
}
.my_35 {
    margin-top: 35px;
    margin-bottom: 35px;
}
.my_40 {
    margin-top: 40px;
    margin-bottom: 40px;
}
.my_45 {
    margin-top: 45px;
    margin-bottom: 45px;
}
.my_50 {
    margin-top: 50px;
    margin-bottom: 50px;
}
.my_55 {
    margin-top: 55px;
    margin-bottom: 55px;
}
.my_60 {
    margin-top: 60px;
    margin-bottom: 60px;
}
.my_65 {
    margin-top: 65px;
    margin-bottom: 65px;
}
.my_70 {
    margin-top: 70px;
    margin-bottom: 70px;
}
.my_75 {
    margin-top: 75px;
    margin-bottom: 75px;
}
.my_80 {
    margin-top: 80px;
    margin-bottom: 80px;
}
.my_85 {
    margin-top: 85px;
    margin-bottom: 85px;
}
.my_90 {
    margin-top: 90px;
    margin-bottom: 90px;
}
.my_95 {
    margin-top: 95px;
    margin-bottom: 95px;
}
.my_100 {
    margin-top: 100px;
    margin-bottom: 100px;
}
.my_110 {
    margin-top: 110px;
    margin-bottom: 110px;
}
.my_120 {
    margin-top: 120px;
    margin-bottom: 120px;
}
.my_130 {
    margin-top: 130px;
    margin-bottom: 130px;
}
.my_140 {
    margin-top: 140px;
    margin-bottom: 140px;
}
.my_150 {
    margin-top: 150px;
    margin-bottom: 150px;
}
.my_160 {
    margin-top: 160px;
    margin-bottom: 160px;
}
.my_170 {
    margin-top: 170px;
    margin-bottom: 170px;
}
.my_180 {
    margin-top: 180px;
    margin-bottom: 180px;
}
.my_190 {
    margin-top: 190px;
    margin-bottom: 190px;
}
.my_200 {
    margin-top: 200px;
    margin-bottom: 200px;
}
.mt_0 {
    margin-top: 0;
}
.mt_1 {
    margin-top: 1px;
}
.mt_2 {
    margin-top: 2px;
}
.mt_3 {
    margin-top: 3px;
}
.mt_4 {
    margin-top: 4px;
}
.mt_5 {
    margin-top: 5px;
}
.mt_6 {
    margin-top: 6px;
}
.mt_7 {
    margin-top: 7px;
}
.mt_8 {
    margin-top: 8px;
}
.mt_9 {
    margin-top: 9px;
}
.mt_10 {
    margin-top: 10px;
}
.mt_11 {
    margin-top: 11px;
}
.mt_12 {
    margin-top: 12px;
}
.mt_13 {
    margin-top: 13px;
}
.mt_14 {
    margin-top: 14px;
}
.mt_15 {
    margin-top: 15px;
}
.mt_16 {
    margin-top: 16px;
}
.mt_17 {
    margin-top: 17px;
}
.mt_18 {
    margin-top: 18px;
}
.mt_19 {
    margin-top: 19px;
}
.mt_20 {
    margin-top: 20px;
}
.mt_25 {
    margin-top: 25px;
}
.mt_30 {
    margin-top: 30px;
}
.mt_35 {
    margin-top: 35px;
}
.mt_40 {
    margin-top: 40px;
}
.mt_45 {
    margin-top: 45px;
}
.mt_50 {
    margin-top: 50px;
}
.mt_55 {
    margin-top: 55px;
}
.mt_60 {
    margin-top: 60px;
}
.mt_65 {
    margin-top: 65px;
}
.mt_70 {
    margin-top: 70px;
}
.mt_75 {
    margin-top: 75px;
}
.mt_80 {
    margin-top: 80px;
}
.mt_85 {
    margin-top: 85px;
}
.mt_90 {
    margin-top: 90px;
}
.mt_95 {
    margin-top: 95px;
}
.mt_100 {
    margin-top: 100px;
}
.mt_110 {
    margin-top: 110px;
}
.mt_120 {
    margin-top: 120px;
}
.mt_130 {
    margin-top: 130px;
}
.mt_140 {
    margin-top: 140px;
}
.mt_150 {
    margin-top: 150px;
}
.mt_160 {
    margin-top: 160px;
}
.mt_170 {
    margin-top: 170px;
}
.mt_180 {
    margin-top: 180px;
}
.mt_190 {
    margin-top: 190px;
}
.mt_200 {
    margin-top: 200px;
}
.mr_0 {
    margin-right: 0;
}
.mr_1 {
    margin-right: 1px;
}
.mr_2 {
    margin-right: 2px;
}
.mr_3 {
    margin-right: 3px;
}
.mr_4 {
    margin-right: 4px;
}
.mr_5 {
    margin-right: 5px;
}
.mr_6 {
    margin-right: 6px;
}
.mr_7 {
    margin-right: 7px;
}
.mr_8 {
    margin-right: 8px;
}
.mr_9 {
    margin-right: 9px;
}
.mr_10 {
    margin-right: 10px;
}
.mr_11 {
    margin-right: 11px;
}
.mr_12 {
    margin-right: 12px;
}
.mr_13 {
    margin-right: 13px;
}
.mr_14 {
    margin-right: 14px;
}
.mr_15 {
    margin-right: 15px;
}
.mr_16 {
    margin-right: 16px;
}
.mr_17 {
    margin-right: 17px;
}
.mr_18 {
    margin-right: 18px;
}
.mr_19 {
    margin-right: 19px;
}
.mr_20 {
    margin-right: 20px;
}
.mr_25 {
    margin-right: 25px;
}
.mr_30 {
    margin-right: 30px;
}
.mr_35 {
    margin-right: 35px;
}
.mr_40 {
    margin-right: 40px;
}
.mr_45 {
    margin-right: 45px;
}
.mr_50 {
    margin-right: 50px;
}
.mr_55 {
    margin-right: 55px;
}
.mr_60 {
    margin-right: 60px;
}
.mr_65 {
    margin-right: 65px;
}
.mr_70 {
    margin-right: 70px;
}
.mr_75 {
    margin-right: 75px;
}
.mr_80 {
    margin-right: 80px;
}
.mr_85 {
    margin-right: 85px;
}
.mr_90 {
    margin-right: 90px;
}
.mr_95 {
    margin-right: 95px;
}
.mr_100 {
    margin-right: 100px;
}
.mr_110 {
    margin-right: 110px;
}
.mr_120 {
    margin-right: 120px;
}
.mr_130 {
    margin-right: 130px;
}
.mr_140 {
    margin-right: 140px;
}
.mr_150 {
    margin-right: 150px;
}
.mr_160 {
    margin-right: 160px;
}
.mr_170 {
    margin-right: 170px;
}
.mr_180 {
    margin-right: 180px;
}
.mr_190 {
    margin-right: 190px;
}
.mr_200 {
    margin-right: 200px;
}
.mb_0 {
    margin-bottom: 0;
}
.mb_1 {
    margin-bottom: 1px;
}
.mb_2 {
    margin-bottom: 2px;
}
.mb_3 {
    margin-bottom: 3px;
}
.mb_4 {
    margin-bottom: 4px;
}
.mb_5 {
    margin-bottom: 5px;
}
.mb_6 {
    margin-bottom: 6px;
}
.mb_7 {
    margin-bottom: 7px;
}
.mb_8 {
    margin-bottom: 8px;
}
.mb_9 {
    margin-bottom: 9px;
}
.mb_10 {
    margin-bottom: 10px;
}
.mb_11 {
    margin-bottom: 11px;
}
.mb_12 {
    margin-bottom: 12px;
}
.mb_13 {
    margin-bottom: 13px;
}
.mb_14 {
    margin-bottom: 14px;
}
.mb_15 {
    margin-bottom: 15px;
}
.mb_16 {
    margin-bottom: 16px;
}
.mb_17 {
    margin-bottom: 17px;
}
.mb_18 {
    margin-bottom: 18px;
}
.mb_19 {
    margin-bottom: 19px;
}
.mb_20 {
    margin-bottom: 20px;
}
.mb_25 {
    margin-bottom: 25px;
}
.mb_30 {
    margin-bottom: 30px;
}
.mb_35 {
    margin-bottom: 35px;
}
.mb_40 {
    margin-bottom: 40px;
}
.mb_45 {
    margin-bottom: 45px;
}
.mb_50 {
    margin-bottom: 50px;
}
.mb_55 {
    margin-bottom: 55px;
}
.mb_60 {
    margin-bottom: 60px;
}
.mb_65 {
    margin-bottom: 65px;
}
.mb_70 {
    margin-bottom: 70px;
}
.mb_75 {
    margin-bottom: 75px;
}
.mb_80 {
    margin-bottom: 80px;
}
.mb_85 {
    margin-bottom: 85px;
}
.mb_90 {
    margin-bottom: 90px;
}
.mb_95 {
    margin-bottom: 95px;
}
.mb_100 {
    margin-bottom: 100px;
}
.mb_110 {
    margin-bottom: 110px;
}
.mb_120 {
    margin-bottom: 120px;
}
.mb_130 {
    margin-bottom: 130px;
}
.mb_140 {
    margin-bottom: 140px;
}
.mb_150 {
    margin-bottom: 150px;
}
.mb_160 {
    margin-bottom: 160px;
}
.mb_170 {
    margin-bottom: 170px;
}
.mb_180 {
    margin-bottom: 180px;
}
.mb_190 {
    margin-bottom: 190px;
}
.mb_200 {
    margin-bottom: 200px;
}
.ml_0 {
    margin-left: 0;
}
.ml_1 {
    margin-left: 1px;
}
.ml_2 {
    margin-left: 2px;
}
.ml_3 {
    margin-left: 3px;
}
.ml_4 {
    margin-left: 4px;
}
.ml_5 {
    margin-left: 5px;
}
.ml_6 {
    margin-left: 6px;
}
.ml_7 {
    margin-left: 7px;
}
.ml_8 {
    margin-left: 8px;
}
.ml_9 {
    margin-left: 9px;
}
.ml_10 {
    margin-left: 10px;
}
.ml_11 {
    margin-left: 11px;
}
.ml_12 {
    margin-left: 12px;
}
.ml_13 {
    margin-left: 13px;
}
.ml_14 {
    margin-left: 14px;
}
.ml_15 {
    margin-left: 15px;
}
.ml_16 {
    margin-left: 16px;
}
.ml_17 {
    margin-left: 17px;
}
.ml_18 {
    margin-left: 18px;
}
.ml_19 {
    margin-left: 19px;
}
.ml_20 {
    margin-left: 20px;
}
.ml_25 {
    margin-left: 25px;
}
.ml_30 {
    margin-left: 30px;
}
.ml_35 {
    margin-left: 35px;
}
.ml_40 {
    margin-left: 40px;
}
.ml_45 {
    margin-left: 45px;
}
.ml_50 {
    margin-left: 50px;
}
.ml_55 {
    margin-left: 55px;
}
.ml_60 {
    margin-left: 60px;
}
.ml_65 {
    margin-left: 65px;
}
.ml_70 {
    margin-left: 70px;
}
.ml_75 {
    margin-left: 75px;
}
.ml_80 {
    margin-left: 80px;
}
.ml_85 {
    margin-left: 85px;
}
.ml_90 {
    margin-left: 90px;
}
.ml_95 {
    margin-left: 95px;
}
.ml_100 {
    margin-left: 100px;
}
.ml_110 {
    margin-left: 110px;
}
.ml_120 {
    margin-left: 120px;
}
.ml_130 {
    margin-left: 130px;
}
.ml_140 {
    margin-left: 140px;
}
.ml_150 {
    margin-left: 150px;
}
.ml_160 {
    margin-left: 160px;
}
.ml_170 {
    margin-left: 170px;
}
.ml_180 {
    margin-left: 180px;
}
.ml_190 {
    margin-left: 190px;
}
.ml_200 {
    margin-left: 200px;
}
/* 
el_13 
Padding
*/
.p_0 {
    padding: 0;
}
.p_1 {
    padding: 1px;
}
.p_2 {
    padding: 2px;
}
.p_3 {
    padding: 3px;
}
.p_4 {
    padding: 4px;
}
.p_5 {
    padding: 5px;
}
.p_6 {
    padding: 6px;
}
.p_7 {
    padding: 7px;
}
.p_8 {
    padding: 8px;
}
.p_9 {
    padding: 9px;
}
.p_10 {
    padding: 10px;
}
.p_11 {
    padding: 11px;
}
.p_12 {
    padding: 12px;
}
.p_13 {
    padding: 13px;
}
.p_14 {
    padding: 14px;
}
.p_15 {
    padding: 15px;
}
.p_16 {
    padding: 16px;
}
.p_17 {
    padding: 17px;
}
.p_18 {
    padding: 18px;
}
.p_19 {
    padding: 19px;
}
.p_20 {
    padding: 20px;
}
.p_25 {
    padding: 25px;
}
.p_30 {
    padding: 30px;
}
.p_35 {
    padding: 35px;
}
.p_40 {
    padding: 40px;
}
.p_45 {
    padding: 45px;
}
.p_50 {
    padding: 50px;
}
.p_55 {
    padding: 55px;
}
.p_60 {
    padding: 60px;
}
.p_65 {
    padding: 65px;
}
.p_70 {
    padding: 70px;
}
.p_75 {
    padding: 75px;
}
.p_80 {
    padding: 80px;
}
.p_85 {
    padding: 85px;
}
.p_90 {
    padding: 90px;
}
.p_95 {
    padding: 95px;
}
.p_100 {
    padding: 100px;
}
.p_110 {
    padding: 110px;
}
.p_120 {
    padding: 120px;
}
.p_130 {
    padding: 130px;
}
.p_140 {
    padding: 140px;
}
.p_150 {
    padding: 150px;
}
.p_160 {
    padding: 160px;
}
.p_170 {
    padding: 170px;
}
.p_180 {
    padding: 180px;
}
.p_190 {
    padding: 190px;
}
.p_200 {
    padding: 200px;
}
.px_0 {
    padding-left: 0;
    padding-right: 0;
}
.px_1 {
    padding-left: 1px;
    padding-right: 1px;
}
.px_2 {
    padding-left: 2px;
    padding-right: 2px;
}
.px_3 {
    padding-left: 3px;
    padding-right: 3px;
}
.px_4 {
    padding-left: 4px;
    padding-right: 4px;
}
.px_5 {
    padding-left: 5px;
    padding-right: 5px;
}
.px_6 {
    padding-left: 6px;
    padding-right: 6px;
}
.px_7 {
    padding-left: 7px;
    padding-right: 7px;
}
.px_8 {
    padding-left: 8px;
    padding-right: 8px;
}
.px_9 {
    padding-left: 9px;
    padding-right: 9px;
}
.px_10 {
    padding-left: 10px;
    padding-right: 10px;
}
.px_11 {
    padding-left: 11px;
    padding-right: 11px;
}
.px_12 {
    padding-left: 12px;
    padding-right: 12px;
}
.px_13 {
    padding-left: 13px;
    padding-right: 13px;
}
.px_14 {
    padding-left: 14px;
    padding-right: 14px;
}
.px_15 {
    padding-left: 15px;
    padding-right: 15px;
}
.px_16 {
    padding-left: 16px;
    padding-right: 16px;
}
.px_17 {
    padding-left: 17px;
    padding-right: 17px;
}
.px_18 {
    padding-left: 18px;
    padding-right: 18px;
}
.px_19 {
    padding-left: 19px;
    padding-right: 19px;
}
.px_20 {
    padding-left: 20px;
    padding-right: 20px;
}
.px_25 {
    padding-left: 25px;
    padding-right: 25px;
}
.px_30 {
    padding-left: 30px;
    padding-right: 30px;
}
.px_35 {
    padding-left: 35px;
    padding-right: 35px;
}
.px_40 {
    padding-left: 40px;
    padding-right: 40px;
}
.px_45 {
    padding-left: 45px;
    padding-right: 45px;
}
.px_50 {
    padding-left: 50px;
    padding-right: 50px;
}
.px_55 {
    padding-left: 55px;
    padding-right: 55px;
}
.px_60 {
    padding-left: 60px;
    padding-right: 60px;
}
.px_65 {
    padding-left: 65px;
    padding-right: 65px;
}
.px_70 {
    padding-left: 70px;
    padding-right: 70px;
}
.px_75 {
    padding-left: 75px;
    padding-right: 75px;
}
.px_80 {
    padding-left: 80px;
    padding-right: 80px;
}
.px_85 {
    padding-left: 85px;
    padding-right: 85px;
}
.px_90 {
    padding-left: 90px;
    padding-right: 90px;
}
.px_95 {
    padding-left: 95px;
    padding-right: 95px;
}
.px_100 {
    padding-left: 100px;
    padding-right: 100px;
}
.px_110 {
    padding-left: 110px;
    padding-right: 110px;
}
.px_120 {
    padding-left: 120px;
    padding-right: 120px;
}
.px_130 {
    padding-left: 130px;
    padding-right: 130px;
}
.px_140 {
    padding-left: 140px;
    padding-right: 140px;
}
.px_150 {
    padding-left: 150px;
    padding-right: 150px;
}
.px_160 {
    padding-left: 160px;
    padding-right: 160px;
}
.px_170 {
    padding-left: 170px;
    padding-right: 170px;
}
.px_180 {
    padding-left: 180px;
    padding-right: 180px;
}
.px_190 {
    padding-left: 190px;
    padding-right: 190px;
}
.px_200 {
    padding-left: 200px;
    padding-right: 200px;
}
.py_0 {
    padding-top: 0;
    padding-bottom: 0;
}
.py_1 {
    padding-top: 1px;
    padding-bottom: 1px;
}
.py_2 {
    padding-top: 2px;
    padding-bottom: 2px;
}
.py_3 {
    padding-top: 3px;
    padding-bottom: 3px;
}
.py_4 {
    padding-top: 4px;
    padding-bottom: 4px;
}
.py_5 {
    padding-top: 5px;
    padding-bottom: 5px;
}
.py_6 {
    padding-top: 6px;
    padding-bottom: 6px;
}
.py_7 {
    padding-top: 7px;
    padding-bottom: 7px;
}
.py_8 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.py_9 {
    padding-top: 9px;
    padding-bottom: 9px;
}
.py_10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.py_11 {
    padding-top: 11px;
    padding-bottom: 11px;
}
.py_12 {
    padding-top: 12px;
    padding-bottom: 12px;
}
.py_13 {
    padding-top: 13px;
    padding-bottom: 13px;
}
.py_14 {
    padding-top: 14px;
    padding-bottom: 14px;
}
.py_15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.py_16 {
    padding-top: 16px;
    padding-bottom: 16px;
}
.py_17 {
    padding-top: 17px;
    padding-bottom: 17px;
}
.py_18 {
    padding-top: 18px;
    padding-bottom: 18px;
}
.py_19 {
    padding-top: 19px;
    padding-bottom: 19px;
}
.py_20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.py_25 {
    padding-top: 25px;
    padding-bottom: 25px;
}
.py_30 {
    padding-top: 30px;
    padding-bottom: 30px;
}
.py_35 {
    padding-top: 35px;
    padding-bottom: 35px;
}
.py_40 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.py_45 {
    padding-top: 45px;
    padding-bottom: 45px;
}
.py_50 {
    padding-top: 50px;
    padding-bottom: 50px;
}
.py_55 {
    padding-top: 55px;
    padding-bottom: 55px;
}
.py_60 {
    padding-top: 60px;
    padding-bottom: 60px;
}
.py_65 {
    padding-top: 65px;
    padding-bottom: 65px;
}
.py_70 {
    padding-top: 70px;
    padding-bottom: 70px;
}
.py_75 {
    padding-top: 75px;
    padding-bottom: 75px;
}
.py_80 {
    padding-top: 80px;
    padding-bottom: 80px;
}
.py_85 {
    padding-top: 85px;
    padding-bottom: 85px;
}
.py_90 {
    padding-top: 90px;
    padding-bottom: 90px;
}
.py_95 {
    padding-top: 95px;
    padding-bottom: 95px;
}
.py_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.py_110 {
    padding-top: 110px;
    padding-bottom: 110px;
}
.py_120 {
    padding-top: 120px;
    padding-bottom: 120px;
}
.py_130 {
    padding-top: 130px;
    padding-bottom: 130px;
}
.py_140 {
    padding-top: 140px;
    padding-bottom: 140px;
}
.py_150 {
    padding-top: 150px;
    padding-bottom: 150px;
}
.py_160 {
    padding-top: 160px;
    padding-bottom: 160px;
}
.py_170 {
    padding-top: 170px;
    padding-bottom: 170px;
}
.py_180 {
    padding-top: 180px;
    padding-bottom: 180px;
}
.py_190 {
    padding-top: 190px;
    padding-bottom: 190px;
}
.py_200 {
    padding-top: 200px;
    padding-bottom: 200px;
}
.pt_0 {
    padding-top: 0;
}
.pt_1 {
    padding-top: 1px;
}
.pt_2 {
    padding-top: 2px;
}
.pt_3 {
    padding-top: 3px;
}
.pt_4 {
    padding-top: 4px;
}
.pt_5 {
    padding-top: 5px;
}
.pt_6 {
    padding-top: 6px;
}
.pt_7 {
    padding-top: 7px;
}
.pt_8 {
    padding-top: 8px;
}
.pt_9 {
    padding-top: 9px;
}
.pt_10 {
    padding-top: 10px;
}
.pt_11 {
    padding-top: 11px;
}
.pt_12 {
    padding-top: 12px;
}
.pt_13 {
    padding-top: 13px;
}
.pt_14 {
    padding-top: 14px;
}
.pt_15 {
    padding-top: 15px;
}
.pt_16 {
    padding-top: 16px;
}
.pt_17 {
    padding-top: 17px;
}
.pt_18 {
    padding-top: 18px;
}
.pt_19 {
    padding-top: 19px;
}
.pt_20 {
    padding-top: 20px;
}
.pt_25 {
    padding-top: 25px;
}
.pt_30 {
    padding-top: 30px;
}
.pt_35 {
    padding-top: 35px;
}
.pt_40 {
    padding-top: 40px;
}
.pt_45 {
    padding-top: 45px;
}
.pt_50 {
    padding-top: 50px;
}
.pt_55 {
    padding-top: 55px;
}
.pt_60 {
    padding-top: 60px;
}
.pt_65 {
    padding-top: 65px;
}
.pt_70 {
    padding-top: 70px;
}
.pt_75 {
    padding-top: 75px;
}
.pt_80 {
    padding-top: 80px;
}
.pt_85 {
    padding-top: 85px;
}
.pt_90 {
    padding-top: 90px;
}
.pt_95 {
    padding-top: 95px;
}
.pt_100 {
    padding-top: 100px;
}
.pt_110 {
    padding-top: 110px;
}
.pt_120 {
    padding-top: 120px;
}
.pt_130 {
    padding-top: 130px;
}
.pt_140 {
    padding-top: 140px;
}
.pt_150 {
    padding-top: 150px;
}
.pt_160 {
    padding-top: 160px;
}
.pt_170 {
    padding-top: 170px;
}
.pt_180 {
    padding-top: 180px;
}
.pt_190 {
    padding-top: 190px;
}
.pt_200 {
    padding-top: 200px;
}
.pr_0 {
    padding-right: 0;
}
.pr_1 {
    padding-right: 1px;
}
.pr_2 {
    padding-right: 2px;
}
.pr_3 {
    padding-right: 3px;
}
.pr_4 {
    padding-right: 4px;
}
.pr_5 {
    padding-right: 5px;
}
.pr_6 {
    padding-right: 6px;
}
.pr_7 {
    padding-right: 7px;
}
.pr_8 {
    padding-right: 8px;
}
.pr_9 {
    padding-right: 9px;
}
.pr_10 {
    padding-right: 10px;
}
.pr_11 {
    padding-right: 11px;
}
.pr_12 {
    padding-right: 12px;
}
.pr_13 {
    padding-right: 13px;
}
.pr_14 {
    padding-right: 14px;
}
.pr_15 {
    padding-right: 15px;
}
.pr_16 {
    padding-right: 16px;
}
.pr_17 {
    padding-right: 17px;
}
.pr_18 {
    padding-right: 18px;
}
.pr_19 {
    padding-right: 19px;
}
.pr_20 {
    padding-right: 20px;
}
.pr_25 {
    padding-right: 25px;
}
.pr_30 {
    padding-right: 30px;
}
.pr_35 {
    padding-right: 35px;
}
.pr_40 {
    padding-right: 40px;
}
.pr_45 {
    padding-right: 45px;
}
.pr_50 {
    padding-right: 50px;
}
.pr_55 {
    padding-right: 55px;
}
.pr_60 {
    padding-right: 60px;
}
.pr_65 {
    padding-right: 65px;
}
.pr_70 {
    padding-right: 70px;
}
.pr_75 {
    padding-right: 75px;
}
.pr_80 {
    padding-right: 80px;
}
.pr_85 {
    padding-right: 85px;
}
.pr_90 {
    padding-right: 90px;
}
.pr_95 {
    padding-right: 95px;
}
.pr_100 {
    padding-right: 100px;
}
.pr_110 {
    padding-right: 110px;
}
.pr_120 {
    padding-right: 120px;
}
.pr_130 {
    padding-right: 130px;
}
.pr_140 {
    padding-right: 140px;
}
.pr_150 {
    padding-right: 150px;
}
.pr_160 {
    padding-right: 160px;
}
.pr_170 {
    padding-right: 170px;
}
.pr_180 {
    padding-right: 180px;
}
.pr_190 {
    padding-right: 190px;
}
.pr_200 {
    padding-right: 200px;
}
.pb_0 {
    padding-bottom: 0;
}
.pb_1 {
    padding-bottom: 1px;
}
.pb_2 {
    padding-bottom: 2px;
}
.pb_3 {
    padding-bottom: 3px;
}
.pb_4 {
    padding-bottom: 4px;
}
.pb_5 {
    padding-bottom: 5px;
}
.pb_6 {
    padding-bottom: 6px;
}
.pb_7 {
    padding-bottom: 7px;
}
.pb_8 {
    padding-bottom: 8px;
}
.pb_9 {
    padding-bottom: 9px;
}
.pb_10 {
    padding-bottom: 10px;
}
.pb_11 {
    padding-bottom: 11px;
}
.pb_12 {
    padding-bottom: 12px;
}
.pb_13 {
    padding-bottom: 13px;
}
.pb_14 {
    padding-bottom: 14px;
}
.pb_15 {
    padding-bottom: 15px;
}
.pb_16 {
    padding-bottom: 16px;
}
.pb_17 {
    padding-bottom: 17px;
}
.pb_18 {
    padding-bottom: 18px;
}
.pb_19 {
    padding-bottom: 19px;
}
.pb_20 {
    padding-bottom: 20px;
}
.pb_25 {
    padding-bottom: 25px;
}
.pb_30 {
    padding-bottom: 30px;
}
.pb_35 {
    padding-bottom: 35px;
}
.pb_40 {
    padding-bottom: 40px;
}
.pb_45 {
    padding-bottom: 45px;
}
.pb_50 {
    padding-bottom: 50px;
}
.pb_55 {
    padding-bottom: 55px;
}
.pb_60 {
    padding-bottom: 60px;
}
.pb_65 {
    padding-bottom: 65px;
}
.pb_70 {
    padding-bottom: 70px;
}
.pb_75 {
    padding-bottom: 75px;
}
.pb_80 {
    padding-bottom: 80px;
}
.pb_85 {
    padding-bottom: 85px;
}
.pb_90 {
    padding-bottom: 90px;
}
.pb_95 {
    padding-bottom: 95px;
}
.pb_100 {
    padding-bottom: 100px;
}
.pb_110 {
    padding-bottom: 110px;
}
.pb_120 {
    padding-bottom: 120px;
}
.pb_130 {
    padding-bottom: 130px;
}
.pb_140 {
    padding-bottom: 140px;
}
.pb_150 {
    padding-bottom: 150px;
}
.pb_160 {
    padding-bottom: 160px;
}
.pb_170 {
    padding-bottom: 170px;
}
.pb_180 {
    padding-bottom: 180px;
}
.pb_190 {
    padding-bottom: 190px;
}
.pb_200 {
    padding-bottom: 200px;
}
.pl_0 {
    padding-left: 0;
}
.pl_1 {
    padding-left: 1px;
}
.pl_2 {
    padding-left: 2px;
}
.pl_3 {
    padding-left: 3px;
}
.pl_4 {
    padding-left: 4px;
}
.pl_5 {
    padding-left: 5px;
}
.pl_6 {
    padding-left: 6px;
}
.pl_7 {
    padding-left: 7px;
}
.pl_8 {
    padding-left: 8px;
}
.pl_9 {
    padding-left: 9px;
}
.pl_10 {
    padding-left: 10px;
}
.pl_11 {
    padding-left: 11px;
}
.pl_12 {
    padding-left: 12px;
}
.pl_13 {
    padding-left: 13px;
}
.pl_14 {
    padding-left: 14px;
}
.pl_15 {
    padding-left: 15px;
}
.pl_16 {
    padding-left: 16px;
}
.pl_17 {
    padding-left: 17px;
}
.pl_18 {
    padding-left: 18px;
}
.pl_19 {
    padding-left: 19px;
}
.pl_20 {
    padding-left: 20px;
}
.pl_25 {
    padding-left: 25px;
}
.pl_30 {
    padding-left: 30px;
}
.pl_35 {
    padding-left: 35px;
}
.pl_40 {
    padding-left: 40px;
}
.pl_45 {
    padding-left: 45px;
}
.pl_50 {
    padding-left: 50px;
}
.pl_55 {
    padding-left: 55px;
}
.pl_60 {
    padding-left: 60px;
}
.pl_65 {
    padding-left: 65px;
}
.pl_70 {
    padding-left: 70px;
}
.pl_75 {
    padding-left: 75px;
}
.pl_80 {
    padding-left: 80px;
}
.pl_85 {
    padding-left: 85px;
}
.pl_90 {
    padding-left: 90px;
}
.pl_95 {
    padding-left: 95px;
}
.pl_100 {
    padding-left: 100px;
}
.pl_110 {
    padding-left: 110px;
}
.pl_120 {
    padding-left: 120px;
}
.pl_130 {
    padding-left: 130px;
}
.pl_140 {
    padding-left: 140px;
}
.pl_150 {
    padding-left: 150px;
}
.pl_160 {
    padding-left: 160px;
}
.pl_170 {
    padding-left: 170px;
}
.pl_180 {
    padding-left: 180px;
}
.pl_190 {
    padding-left: 190px;
}
.pl_200 {
    padding-left: 200px;
}
/* 
el_14 
Top
*/
.t_0 {
    top: 0;
}
.t_1 {
    top: 1px;
}
.t_2 {
    top: 2px;
}
.t_3 {
    top: 3px;
}
.t_4 {
    top: 4px;
}
.t_5 {
    top: 5px;
}
.t_6 {
    top: 6px;
}
.t_7 {
    top: 7px;
}
.t_8 {
    top: 8px;
}
.t_9 {
    top: 9px;
}
.t_10 {
    top: 10px;
}
.t_11 {
    top: 11px;
}
.t_12 {
    top: 12px;
}
.t_13 {
    top: 13px;
}
.t_14 {
    top: 14px;
}
.t_15 {
    top: 15px;
}
.t_16 {
    top: 16px;
}
.t_17 {
    top: 17px;
}
.t_18 {
    top: 18px;
}
.t_19 {
    top: 19px;
}
.t_20 {
    top: 20px;
}
.t_25 {
    top: 25px;
}
.t_30 {
    top: 30px;
}
.t_35 {
    top: 35px;
}
.t_40 {
    top: 40px;
}
.t_45 {
    top: 45px;
}
.t_50 {
    top: 50px;
}
.t_55 {
    top: 55px;
}
.t_60 {
    top: 60px;
}
.t_65 {
    top: 65px;
}
.t_70 {
    top: 70px;
}
.t_75 {
    top: 75px;
}
.t_80 {
    top: 80px;
}
.t_85 {
    top: 85px;
}
.t_90 {
    top: 90px;
}
.t_95 {
    top: 95px;
}
.t_100 {
    top: 100px;
}
.t_110 {
    top: 110px;
}
.t_120 {
    top: 120px;
}
.t_130 {
    top: 130px;
}
.t_140 {
    top: 140px;
}
.t_150 {
    top: 150px;
}
.t_160 {
    top: 160px;
}
.t_170 {
    top: 170px;
}
.t_180 {
    top: 180px;
}
.t_190 {
    top: 190px;
}
.t_200 {
    top: 200px;
}
/* 
el_15 
Right
*/
.r_0 {
    right: 0;
}
.r_1 {
    right: 1px;
}
.r_2 {
    right: 2px;
}
.r_3 {
    right: 3px;
}
.r_4 {
    right: 4px;
}
.r_5 {
    right: 5px;
}
.r_6 {
    right: 6px;
}
.r_7 {
    right: 7px;
}
.r_8 {
    right: 8px;
}
.r_9 {
    right: 9px;
}
.r_10 {
    right: 10px;
}
.r_11 {
    right: 11px;
}
.r_12 {
    right: 12px;
}
.r_13 {
    right: 13px;
}
.r_14 {
    right: 14px;
}
.r_15 {
    right: 15px;
}
.r_16 {
    right: 16px;
}
.r_17 {
    right: 17px;
}
.r_18 {
    right: 18px;
}
.r_19 {
    right: 19px;
}
.r_20 {
    right: 20px;
}
.r_25 {
    right: 25px;
}
.r_30 {
    right: 30px;
}
.r_35 {
    right: 35px;
}
.r_40 {
    right: 40px;
}
.r_45 {
    right: 45px;
}
.r_50 {
    right: 50px;
}
.r_55 {
    right: 55px;
}
.r_60 {
    right: 60px;
}
.r_65 {
    right: 65px;
}
.r_70 {
    right: 70px;
}
.r_75 {
    right: 75px;
}
.r_80 {
    right: 80px;
}
.r_85 {
    right: 85px;
}
.r_90 {
    right: 90px;
}
.r_95 {
    right: 95px;
}
.r_100 {
    right: 100px;
}
.r_110 {
    right: 110px;
}
.r_120 {
    right: 120px;
}
.r_130 {
    right: 130px;
}
.r_140 {
    right: 140px;
}
.r_150 {
    right: 150px;
}
.r_160 {
    right: 160px;
}
.r_170 {
    right: 170px;
}
.r_180 {
    right: 180px;
}
.r_190 {
    right: 190px;
}
.r_200 {
    right: 200px;
}
/* 
el_16
Bottom
*/
.b_0 {
    bottom: 0;
}
.b_1 {
    bottom: 1px;
}
.b_2 {
    bottom: 2px;
}
.b_3 {
    bottom: 3px;
}
.b_4 {
    bottom: 4px;
}
.b_5 {
    bottom: 5px;
}
.b_6 {
    bottom: 6px;
}
.b_7 {
    bottom: 7px;
}
.b_8 {
    bottom: 8px;
}
.b_9 {
    bottom: 9px;
}
.b_10 {
    bottom: 10px;
}
.b_11 {
    bottom: 11px;
}
.b_12 {
    bottom: 12px;
}
.b_13 {
    bottom: 13px;
}
.b_14 {
    bottom: 14px;
}
.b_15 {
    bottom: 15px;
}
.b_16 {
    bottom: 16px;
}
.b_17 {
    bottom: 17px;
}
.b_18 {
    bottom: 18px;
}
.b_19 {
    bottom: 19px;
}
.b_20 {
    bottom: 20px;
}
.b_25 {
    bottom: 25px;
}
.b_30 {
    bottom: 30px;
}
.b_35 {
    bottom: 35px;
}
.b_40 {
    bottom: 40px;
}
.b_45 {
    bottom: 45px;
}
.b_50 {
    bottom: 50px;
}
.b_55 {
    bottom: 55px;
}
.b_60 {
    bottom: 60px;
}
.b_65 {
    bottom: 65px;
}
.b_70 {
    bottom: 70px;
}
.b_75 {
    bottom: 75px;
}
.b_80 {
    bottom: 80px;
}
.b_85 {
    bottom: 85px;
}
.b_90 {
    bottom: 90px;
}
.b_95 {
    bottom: 95px;
}
.b_100 {
    bottom: 100px;
}
.b_110 {
    bottom: 110px;
}
.b_120 {
    bottom: 120px;
}
.b_130 {
    bottom: 130px;
}
.b_140 {
    bottom: 140px;
}
.b_150 {
    bottom: 150px;
}
.b_160 {
    bottom: 160px;
}
.b_170 {
    bottom: 170px;
}
.b_180 {
    bottom: 180px;
}
.b_190 {
    bottom: 190px;
}
.b_200 {
    bottom: 200px;
}
/* 
el_17
Top
*/
.l_0 {
    left: 0;
}
.l_1 {
    left: 1px;
}
.l_2 {
    left: 2px;
}
.l_3 {
    left: 3px;
}
.l_4 {
    left: 4px;
}
.l_5 {
    left: 5px;
}
.l_6 {
    left: 6px;
}
.l_7 {
    left: 7px;
}
.l_8 {
    left: 8px;
}
.l_9 {
    left: 9px;
}
.l_10 {
    left: 10px;
}
.l_11 {
    left: 11px;
}
.l_12 {
    left: 12px;
}
.l_13 {
    left: 13px;
}
.l_14 {
    left: 14px;
}
.l_15 {
    left: 15px;
}
.l_16 {
    left: 16px;
}
.l_17 {
    left: 17px;
}
.l_18 {
    left: 18px;
}
.l_19 {
    left: 19px;
}
.l_20 {
    left: 20px;
}
.l_25 {
    left: 25px;
}
.l_30 {
    left: 30px;
}
.l_35 {
    left: 35px;
}
.l_40 {
    left: 40px;
}
.l_45 {
    left: 45px;
}
.l_50 {
    left: 50px;
}
.l_55 {
    left: 55px;
}
.l_60 {
    left: 60px;
}
.l_65 {
    left: 65px;
}
.l_70 {
    left: 70px;
}
.l_75 {
    left: 75px;
}
.l_80 {
    left: 80px;
}
.l_85 {
    left: 85px;
}
.l_90 {
    left: 90px;
}
.l_95 {
    left: 95px;
}
.l_100 {
    left: 100px;
}
.l_110 {
    left: 110px;
}
.l_120 {
    left: 120px;
}
.l_130 {
    left: 130px;
}
.l_140 {
    left: 140px;
}
.l_150 {
    left: 150px;
}
.l_160 {
    left: 160px;
}
.l_170 {
    left: 170px;
}
.l_180 {
    left: 180px;
}
.l_190 {
    left: 190px;
}
.l_200 {
    left: 200px;
}
/* 
el_18
Text align
*/
.align-1 {
    text-align: left;
} 
.align-2 {
    text-align: center;
}
.align-3 {
    text-align: right;
}
/* 
el_19
Z-index
*/
.z_1 {
    z-index: 1;
}
.z_2 {
    z-index: 2;
}
.z_3 {
    z-index: 3;
}
.z_4 {
    z-index: 4;
}
.z_5 {
    z-index: 5;
}
.z_6 {
    z-index: 6;
}
.z_7 {
    z-index: 7;
}
.z_8 {
    z-index: 8;
}
.z_9 {
    z-index: 9;
}
.z_99 {
    z-index: 99;
}
.z_999 {
    z-index: 999;
}
.z_9999 {
    z-index: 9999;
}
.z_99999 {
    z-index: 99999;
}
/* 
el_20
Border radius
*/
.b_radius_0 {
    border-radius: 0;
}
.b_radius_1 {
    border-radius: 1px;
}
.b_radius_2 {
    border-radius: 2px;
}
.b_radius_3 {
    border-radius: 3px;
}
.b_radius_4 {
    border-radius: 4px;
}
.b_radius_5 {
    border-radius: 5px;
}
.b_radius_6 {
    border-radius: 6px;
}
.b_radius_7 {
    border-radius: 7px;
}
.b_radius_8 {
    border-radius: 8px;
}
.b_radius_9 {
    border-radius: 9px;
}
.b_radius_10 {
    border-radius: 10px;
}
.b_radius_11 {
    border-radius: 11px;
}
.b_radius_12 {
    border-radius: 12px;
}
.b_radius_13 {
    border-radius: 13px;
}
.b_radius_14 {
    border-radius: 14px;
}
.b_radius_15 {
    border-radius: 15px;
}
.b_radius_16 {
    border-radius: 16px;
}
.b_radius_17 {
    border-radius: 17px;
}
.b_radius_18 {
    border-radius: 18px;
}
.b_radius_19 {
    border-radius: 19px;
}
.b_radius_20 {
    border-radius: 20px;
}
.b_radius_25 {
    border-radius: 25px;
}
.b_radius_30 {
    border-radius: 30px;
}
.b_radius_50 {
    border-radius: 50%;
}
/* 
el_21
Box shadow
*/
.b_shadow_1 {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.b_shadow_2 {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.b_shadow_3 {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.b_shadow_4 {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.b_shadow_5 {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
}
.b_shadow_6 {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.10);
}
.b_shadow_7 {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}
.b_shadow_8 {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.20);
}
/* 
el_22
Btn
*/
.theme_btn_1{
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    color: #fff !important;
    text-align: center;
    padding: 14.5px 31px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #0d6efd;
    z-index: 1;
    vertical-align: middle;
}
.theme_btn_1:before{
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    background: #272727;
    left: 0px;
    top: 0px;
    z-index: -1;
    transition: all 500ms ease;
}
.theme_btn_1:hover:before{
    width: 100%;
}

.theme_btn_2{
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    color: #061a3a;
    border: 1px solid #e4e8e9;
    text-align: center;
    padding: 16.5px 41.5px;
    border-radius: 4px;
    z-index: 1;
    transition: all 500ms ease;
    vertical-align: middle;
}
.theme_btn_2:hover{
    color: #ffffff;
}
.theme_btn_2:before{
    transition-duration: 800ms;
    position: absolute;
    width: 200%;
    height: 200%;
    content: "";
    top: 110%;
    left: 50%;
    background: #0d6efd;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 50%;
    z-index: -1;
}
.theme_btn_2:hover:before{
    top: -40%;
}
/* 
el_23
Image Hover
*/
.img_hover_1 {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}
.img_hover_1 img{
    width: 100%;
    transition: all 500ms ease;
} 
.img_hover_1:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255,.2);
    border-radius: 0%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
}
.img_hover_1:hover:before {
    -webkit-animation: circle .95s;
    animation: circle .95s;
}
.img_hover_2 {
    position: relative;
    overflow: hidden;
    background-color: #0d6efd;
}
.img_hover_2 img{
    width: 100%;
    transition: all 500ms ease;
} 
.img_hover_2:hover img{
    transform: scale(1.05);
    opacity: .5;
} 
.img_hover_2:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255,.2);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
}
.img_hover_2:hover:before {
    -webkit-animation: circle .95s;
    animation: circle .95s;
}
@-webkit-keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
}
@keyframes circle {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
}
.img_hover_3 {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}
.img_hover_3 img{
    width: 100%;
    transition: all 500ms ease;
} 
.img_hover_3:before{
    position: absolute;
    top: 0;
    left: -75%;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
    opacity: 0;
}
.img_hover_3:hover:before{
    -webkit-animation: shine 1s;
    animation: shine 1s;
    opacity: 1;
}
@-webkit-keyframes shine {
    100% {
      left: 125%;
    }
}
@keyframes shine {
    100% {
      left: 125%;
    }
}
.img_hover_4{
    position: relative;
    overflow: hidden;
}
.img_hover_4 img{
    width: 100%;
    transition: all 500ms ease;
} 
.img_hover_4:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 0%;
    left: 0px;
    top: 0px;
    opacity: 0.8;
    transition: all 500ms ease;
    background-color: #0d6efd;
}
.img_hover_4:hover:before{
    height: 100%;
}
.img_hover_5{
    position: relative;
    display: block;
    overflow: hidden;
}  
.img_hover_5:before{
    position: absolute;
    content: '';
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    left: 10px;
    top: 10px;
    transform: scale(0,0);
    opacity: 0.9;
    z-index: 1;
    transition: all 500ms ease;
    background-color: #0d6efd;
}  
.img_hover_5:hover:before{
    transform: scale(1,1);
}
.img_hover_5 img{
    width: 100%;
    transition: all 500ms ease;
} 
.img_hover_5:hover img{
    transform: scale(1.1);
}
.img_hover_6{
    position: relative;
    display: block;
    overflow: hidden;
    background-color: #0d6efd;
}
.img_hover_6 img{
    width: 100%;
    transition: all 500ms ease;
}
.img_hover_6:hover img{
    transform: scale(1.1);
    opacity: 0.2;
}
.img_hover_7{
    position: relative;
    display: block;
    overflow: hidden;
}
.img_hover_7 img{
    width: 100%;
    transform: scale(1.0);
    transition: all 1700ms ease;    
}
.img_hover_7:hover img{
    transform: scale(1.2);    
}
.img_hover_7:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: #000000;
    opacity: 1.0;
    border-radius: 5px;
    transition: .5s;
    transform: perspective(400px) scaleX(0);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    z-index: 2;
}
.img_hover_7:hover:before{
    opacity: 0.80;
    transform: perspective(400px) scaleX(1.0);
}
.img_hover_8 {
    position: relative;
    display: block;
    overflow: hidden;
}
.img_hover_8 img{
    width: 100%;
    transition: all 1700ms ease;    
}
.img_hover_8:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    -ms-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    z-index: 1;
}
.img_hover_8:hover:before{
    opacity: .65;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
}
.img_hover_9{
    position: relative;
    display: block;
    overflow: hidden;
}
.img_hover_9 img{
    width: 100%;
    transform: scale(1.0);
    transition: all 3500ms ease;   
}
.img_hover_9:hover img{
    transform:scale(1.2) rotate(1deg);	    
}
.img_hover_9:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    opacity: 0;
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    transition-property: all;
    opacity: 1;
    transform-origin: top;
    transform-style: preserve-3d;
    transform: scaleY(0);
    z-index: 1;
    background-color: #0d6efd;
}
.img_hover_9:hover:before{
    opacity: 0.80;
    transform: scaleY(1.0);    
}
.img_hover_9:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    opacity: 1;
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    transition-property: all;
    opacity: 1;
    transform-origin: bottom;
    transform-style: preserve-3d;
    transform: scaleY(0);
    z-index: 1;
    background-color: #0d6efd;
}
.img_hover_9:hover:after{
    opacity: 0.80;
    transform: scaleY(1.0);
}
.img_hover_10{
    position: relative;
    display: block;
    overflow: hidden;
}
.img_hover_10:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: #0d6efd;
    opacity: 1.0;
    transition: .5s;
    transform: perspective(400px) scaleY(0);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    z-index: 1;
}
.img_hover_10:hover:before{
    opacity: 0.50;
    transform: perspective(400px) scaleY(1.0);
}
.img_hover_10 img{
    width: 100%;
    transition: all 3500ms ease;    
}
.img_hover_10:hover img{
    transform: scale(1.3) rotate(2deg);
}

.flexbox_1 {
    display: flex;
    flex-wrap: wrap;
}

.flexbox_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flexbox_3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

/* nice-select.css */
.nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 42px;
    line-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap;
    width: auto; }
    .nice-select:hover {
      border-color: #dbdbdb; }
    .nice-select:active, .nice-select.open, .nice-select:focus {
      border-color: #999; }
    .nice-select:after {
      border-bottom: 2px solid #999;
      border-right: 2px solid #999;
      content: '';
      display: block;
      height: 5px;
      margin-top: -4px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      -webkit-transform-origin: 66% 66%;
          -ms-transform-origin: 66% 66%;
              transform-origin: 66% 66%;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      width: 5px; }
    .nice-select.open:after {
      -webkit-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
              transform: rotate(-135deg); }
    .nice-select.open .list {
      opacity: 1;
      pointer-events: auto;
      -webkit-transform: scale(1) translateY(0);
          -ms-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); }
    .nice-select.disabled {
      border-color: #ededed;
      color: #999;
      pointer-events: none; }
      .nice-select.disabled:after {
        border-color: #cccccc; }
    .nice-select.wide {
      width: 100%; }
      .nice-select.wide .list {
        left: 0 !important;
        right: 0 !important; }
    .nice-select.right {
      float: right; }
      .nice-select.right .list {
        left: auto;
        right: 0; }
    .nice-select.small {
      font-size: 12px;
      height: 36px;
      line-height: 34px; }
      .nice-select.small:after {
        height: 4px;
        width: 4px; }
      .nice-select.small .option {
        line-height: 34px;
        min-height: 34px; }
    .nice-select .list {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
      box-sizing: border-box;
      margin-top: 4px;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      pointer-events: none;
      position: absolute;
      top: 100%;
      left: 0;
      -webkit-transform-origin: 50% 0;
          -ms-transform-origin: 50% 0;
              transform-origin: 50% 0;
      -webkit-transform: scale(0.75) translateY(-21px);
          -ms-transform: scale(0.75) translateY(-21px);
              transform: scale(0.75) translateY(-21px);
      -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
      transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
      z-index: 9; }
      .nice-select .list:hover .option:not(:hover) {
        background-color: transparent !important; }
    .nice-select .option {
      cursor: pointer;
      font-weight: 400;
      line-height: 40px;
      list-style: none;
      min-height: 40px;
      outline: none;
      padding-left: 18px;
      padding-right: 29px;
      text-align: left;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
        background-color: #f6f6f6; }
      .nice-select .option.selected {
        font-weight: bold; }
      .nice-select .option.disabled {
        background-color: transparent;
        color: #999;
        cursor: default; }
  
  .no-csspointerevents .nice-select .list {
    display: none; }
  
  .no-csspointerevents .nice-select.open .list {
    display: block; }
    /* owl.css */
    /**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1; }
    .owl-carousel .owl-stage {
      position: relative;
      -ms-touch-action: pan-Y;
      touch-action: manipulation;
      -moz-backface-visibility: hidden;
      /* fix firefox animation glitch */ }
    .owl-carousel .owl-stage:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    .owl-carousel .owl-stage-outer {
      position: relative;
      overflow: hidden;
      /* fix for flashing background */
      -webkit-transform: translate3d(0px, 0px, 0px); }
    .owl-carousel .owl-wrapper,
    .owl-carousel .owl-item {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0); }
    .owl-carousel .owl-item {
      position: relative;
      min-height: 1px;
      float: left;
      -webkit-backface-visibility: hidden;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none; }
    .owl-carousel .owl-item img {
      display: block;
      width: 100%; }
    .owl-carousel .owl-nav.disabled,
    .owl-carousel .owl-dots.disabled {
      display: none; }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next,
    .owl-carousel .owl-dot {
      cursor: pointer;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next,
    .owl-carousel button.owl-dot {
      background: none;
      color: inherit;
      border: none;
      padding: 0 !important;
      font: inherit; }
    .owl-carousel.owl-loaded {
      display: block; }
    .owl-carousel.owl-loading {
      opacity: 0;
      display: block; }
    .owl-carousel.owl-hidden {
      opacity: 0; }
    .owl-carousel.owl-refresh .owl-item {
      visibility: hidden; }
    .owl-carousel.owl-drag .owl-item {
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .owl-carousel.owl-grab {
      cursor: move;
      cursor: grab; }
    .owl-carousel.owl-rtl {
      direction: rtl; }
    .owl-carousel.owl-rtl .owl-item {
      float: right; }
  
  /* No Js */
  .no-js .owl-carousel {
    display: block; }
  
  /*
   *  Owl Carousel - Animate Plugin
   */
  .owl-carousel .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both; }
  
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  
  .owl-carousel .fadeOut {
    animation-name: fadeOut; }
  
  @keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  
  /*
   *  Owl Carousel - Auto Height Plugin
   */
  .owl-height {
    transition: height 500ms ease-in-out; }
  
  /*
   *  Owl Carousel - Lazy Load Plugin
   */
  .owl-carousel .owl-item {
    /**
        This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
        calculation of the height of the owl-item that breaks page layouts
       */ }
    .owl-carousel .owl-item .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease; }
    .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
      max-height: 0; }
    .owl-carousel .owl-item img.owl-lazy {
      transform-style: preserve-3d; }
  
  /*
   *  Owl Carousel - Video Plugin
   */
  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000; }
  
  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    /* background: url("owl.video.play.png") no-repeat; */
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease; }
  
  .owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
        transform: scale(1.3, 1.3); }
  
  .owl-carousel .owl-video-playing .owl-video-tn,
  .owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none; }
  
  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease; }
  
  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%; 
}
  /* responsive.css */
  @media only screen and (max-width: 7000px){
	.page-wrapper{
		overflow: hidden;
	}
	
}

@media only screen and (max-width: 1560px){
	.check-availability.style-four li.date {
		max-width: none;
		width: auto;
	}
}

@media only screen and (max-width: 1320px){
	section.news-section-two .auto-container,
	section.welcome-section-four .auto-container,
	.main-header.header-style-four .auto-container,
	header.main-header.header-style-three .auto-container,
	.main-header.header-style-eight .auto-container,
	header.main-header.header-style-six .auto-container,
	.main-header.header-style-five .auto-container,
	section.accomodation-section .auto-container,
	.main-header.header-style-two .auto-container,
	.main-header.header-style-one .auto-container {
		padding: 0 15px;
	}
	section.banner-section-three {
		margin: 0;
	}
	header.main-header.header-style-three {
		top: 10px;
	}
	.check-availability.style-two {
		position: relative;
	}
	section.banner-section-three.style-two .wrapper-box {
		padding-bottom: 160px;
		padding-top: 250px;
	}
	.check-availability.style-four li.date {
		max-width: none;
		width: auto;
	}	
	.check-availability.style-four {
		position: relative;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 0;
	}	
	.banner-section.style-four .content-box {
		min-height: 900px;
		padding-bottom: 0;
	}
	section.welcome-section-four .right-column {
		padding-left: 0;
	}
	header.main-header.header-style-six {
		top: 20px;
	}
	section.banner-section.style-six .bg {
		left: 0;
		right: 0;
		top: 0;
	}
	.mobile-nav-toggler.style-three {
		bottom: auto;
		height: 70px;
		position: absolute;
		top: 15px;
		left: 15px;
	}
	section.banner-section.style-seven .banner-outer-box {
		margin-left: 0;
	}
}	

@media only screen and (max-width: 1199px){
	.banner-section .banner-slider-nav {
		display: none;
	}
	.header-top .right-column {
	    display: none;
	}
	.header-top .left-column {
		width: 100%;
		justify-content: center;
	}
	.banner-section .content-box h1 {
		font-size: 60px;
		line-height: 70px;
	}
	.main-header.header-style-three .header-upper .right-column {
		display: none;
	}
	
	.main-header.header-style-three .nav-outer {
		width: 100%;
		justify-content: space-between;
	}

	section.feature-section .image {
		display: block;
	}
	
	section.feature-section .bg {
		display: none;
	}
	
	section.aminities-section-two .bg {
		display: none;
	}
	
	section.aminities-section-two .image {
		display: block;
	}
	section.aminities-section-two .content-block {
		padding-left: 30px;
	}
	
	section.feature-section .inner-box {
		padding-left: 30px;
	}
	.check-availability.style-four .left-side>ul>li {
		width: 100%;
		margin-right: 0;
	}

	.check-availability.style-four .left-side {
		width: 100%;
	}

	.check-availability.style-four .right-side {
		width: 100%;
		padding: 0;
	}

	.check-availability.style-four br {
		display: none;
	}

	.check-availability.style-four button {
		width: 100%;
		border-radius: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding: 20px;
	}
	section.banner-section-three.style-three .bg {
		width: 100%;
	}
	.left-outer-container {
		padding: 0 15px;
	}
}

@media only screen and (max-width: 1139px){
	/* Default */
	.main-header .nav-outer .main-menu,
	.sticky-header{
		display: none !important;
	}
	.nav-outer .mobile-nav-toggler{
		display: block;
	}
	header.main-header.header-style-eight,
	header.main-header.header-style-six,
	header.main-header.header-style-five {
		top: 0;
	}
}

@media only screen and (min-width: 992px){

}

@media only screen and (max-width: 991px){


	.banner-section.style-two .content-box h1,
	h1.banner-title,
	.page-title h1,
	.banner-section .content-box h1 {
		font-size: 50px;
		line-height: 60px;
	}
	section.activities-section .inner-box {
		margin-left: auto;
		margin-right: auto;
	}
	section.testimonials-section.style-two:before {
		width: 100%;
	}
	section.about-section-two .title-text {
		margin-bottom: 30px;
	}
	section.banner-section-three .wrapper-box {
		background-position: center right;
	}
	section.welcome-section-seven .content-block {
		max-width: 100%;
	}
	section.team-section-two .text {
		margin-top: 0;
		margin-bottom: 50px;
		width: 100%;
	}
	section.contact-form-section .inner-box {
		margin-left: 0;
	}
	
	section.contact-form-section .text-two {
		margin-left: 0;
	}
	
	section.contact-form-section .text-three {
		margin-left: 0;
	}
	
	section.contact-form-section .icon-box {
		margin-left: 0;
	}

	.style-two .filter-tabs li {
		margin-bottom: 10px;
	}
	
	section.team-section-two .title-box .sec-title {
		margin-bottom: 20px;
	}
	.we-invite-section .contact-block {
		padding-left: 30px;
		margin-left: 0;
	}
	
	section.we-invite-section .award {
		position: relative;
		margin-top: 50px;
	}
	.comment-form {
		margin-left: 0;
		margin-bottom: 30px;
	}
	.service-block-three .content {
		margin-left: 0;
		padding: 30px;
		min-height: auto;
	}
	.service-block-three .link-btn {
		position: relative;
		right: 0;
		bottom: 0;
		padding-left: 0;
		margin-top: 10px;
	}
	.featured-block {
		margin-bottom: 30px;
	}
	.news-block-two .lower-content {
		padding-left: 0;
		padding-right: 0;
	}
	
	.blog-post-share-icon {
		margin-left: 0;
	}
	section.welcome-section-four .inner-box {
		margin-right: 0;
	}
	.room-block-two {
		margin-bottom: 30px;
	}
	.news-block-two .lower-content {
		margin-left: 0;
	}
	section.welcome-section-six .author-info {
		margin-bottom: 30px;
	}

	section.why-choose-us-section {
		padding-bottom: 0;
	}
	
	.why-choose-us-block {
		margin-bottom: 50px;
	}
	
	section.why-choose-us-section .text-two {
		margin-top: 70px;
		margin-left: 0;
	}
	
	section.why-choose-us-section .icon-list {
		margin-left: 0;
	}

	section.aminities-section-four .link-btn {
		margin-bottom: 30px;
	}

	section.about-section-two .image {
		margin: 0 0 30px;
	}
	
	section.about-section-two .content {
		margin-left: 0;
	}

	section.room-section-three .curve-text {
		position: relative;
		left: 0;
		transform: inherit;
		margin-left: 0;
	}
	
	section.room-section-three .outer-box {
		padding-left: 15px;
		padding-right: 15px;
	}

	section.welcome-section-five .image-three {
		margin-bottom: 30px;
	}
	section.welcome-section-five .image {
		margin-top: 30px;
	}

	.contact-map {
		margin-bottom: 30px;
	}

	.welcome-block-three {
		margin-bottom: 30px;
	}

	section.welcome-section-seven .content-block {
		margin-top: 30px;
		margin-left: 0;
	}

	.Welcome-block {
		margin-bottom: 30px;
	}

	section.about-section .content-block {
		margin-bottom: 50px;
	}

	.offer-block-two {
		margin-bottom: 30px;
	}
	
	.offer-block-three {
		padding-left: 15px;
	}
	
	.service-block-one .image-block {
		margin-bottom: 30px;
	}
	
	.news-block .inner-box {
		margin-bottom: 30px;
	}
	
	.news-block .date br {
		display: block;
	}
	
	section.about-us-section .content-block {
		margin-top: 0;
		margin-right: 0;
	}
	.news-block .inner-box,
	.room-block .inner-box {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
	section.testimonials-section .content-block {
		margin-bottom: 30px;
		margin-right: 0;
	}
	section.testimonials-section .award {
		position: relative;
		right: 0;
		top: 0;
		margin-top: 30px;
	}
	section.funfact-section .count-outer.count-box {
		text-align: center;
	}	
	section.funfact-section .text {
		text-align: center;
	}
	footer.main-footer .gallery-overlay {
		margin-bottom: 30px;
	}
	section.about-us-section .content-block .link-btn {
		position: relative;
		margin-top: 30px;
	}	
	section.about-us-section .content-block {
		padding-left: 20px;
		padding-right: 20px;
	}	
	section.about-us-section .content-block .award {
		position: relative;
		top: 0;
		left: 0;
		margin-top: 30px;
	}
	section.about-us-section .image {
		margin-left: 0;
	}
	section.room-section .top-content .right-column {
		width: 100%;
	}
	
	section.room-section .top-content {
		margin-bottom: 50px;
	}
	
	section.service-section-two .content-block {
		padding: 100px 30px;
	}
}

@media only screen and (min-width: 768px){
	.main-menu .navigation > li > ul,
	.main-menu .navigation > li > ul > li > ul{
		display:block !important;
		visibility:hidden;
		opacity:0;
	}
}

@media only screen and (max-width: 767px){	
	header.main-header.header-style-seven {
		top: 5px;
	}
	
	header.main-header.header-style-seven .logo-box {
		width: 100%;
		text-align: right;
	}
	
	header.main-header.header-style-seven .auto-container {
		padding: 0 0;
	}
	br {
		display: none;
	}
	.main-header .header-upper .right-column,
	.main-header .header-upper .navbar-right {
		display: none;
	}

	.testimonial-block.style-two {
		background: #f5f6f7;
		padding: 35px 20px;
	}

	.testimonial-block h3 {
		line-height: 1.2em;
		margin-top: -15px;
		margin-bottom: 20px;
	}
	
	section.testimonials-section-two h3 .quote {
		line-height: 1.2em;
	}

	.check-availability.style-five {
		position: relative;
	}
	
	section.accomodation-section.style-two h3 {
		margin-bottom: 20px;
	}

	section.welcome-section-two .image-two {
		position: relative;
		top: 0;
		left: 0;
		border: 0;
		margin-top: 20px;
	}

	.map-section .content-column {
		padding: 30px;
	}
	
	.feature-block-two .inner-box {
		margin: 0 auto;
	}

	.check-availability.style-three {
		position: relative;
	}

	section.why-choose-us-section .text-three br {
		display: block;
	}

	section.service-section .content-block {
		padding-left: 15px;
		padding-right: 15px;
	}

	section.testimonials-section .owl-dot-style-one .owl-dots {
		text-align: right;
		margin-top: -75px;
	}
	

	.award h4 br {
		display: block;
	}

	.sec-title {
		font-size: 36px;
		line-height: 50px;
	}
	
	.check-availability .left-side>ul>li {
		width: 100%;
	}

	.check-availability .left-side {
		width: 100%;
		padding-left: 30px;
	}

	.check-availability .right-side {
		width: 100%;
		padding: 0 30px;
		padding-bottom: 30px;
	}

	.main-header .header-upper .search-toggler,
	.check-availability br {
		display: none;
	}
	.check-availability.style-two button,
	.check-availability.style-six button,
	.check-availability button {
		width: 100%;
		border-radius: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding: 20px;
	}
	.main-header.header-style-six .header-upper .inner-container {
		padding-left: 0;
	}	
	header.main-header.header-style-six .mobile-nav-toggler.style-two {
		left: auto;
		right: 0;
	}	
	.mobile-menu.style-two .menu-box {
		padding-left: 20px;
	}	
	.banner-section.style-six .content-box {
		padding-left: 15px;
	}
	.history-block {
		padding-left: 0;
	}	
	.history-block .years {
		position: relative;
	}	
	.history-block:before {
		opacity: 0;
	}
	.comming-soon .emailed-form .form-group input[type="email"] {
		width: 100%;
		margin-bottom: 20px;
	}
	

}

@media only screen and (max-width: 575px){
	.why-choose-section .list li {
		width: 100%;
	}
	.owl_nav_style_one.owl-theme .owl-nav {
		display: none;
	}
	.news-block .lower-content {
		margin-left: 0;
	}
	.news-block-two .lower-content {
		padding-left: 0;
		padding-right: 0;
	}
	.news-block-two ul li {
		margin-left: 0;
	}
	.comments-area .comment {
		padding-left: 0;
	}	
	.comments-area .comment-box .author-thumb {
		position: relative;
	}	
	.sidebar-page-container .author-box {
		padding: 30px;
	}	
	.sidebar-page-container .author-box .image {
		position: relative;
		left: 0;
		top: 0;
		margin-bottom: 30px;
	}	
	.post-tag .social-icon {
		margin-top: 15px;
	}	
	section.welcome-section-six .signature {
		margin-top: 30px;
	}
	.blog-post-share-icon .share-icon {
		margin-bottom: 20px;
	}	
	.blog-post-share-icon ul.social-icon li {
		margin-bottom: 22px;
	}
	.news-block-two .date {
		margin-right: 20px;
	}
	section.welcome-section-two ul li,
	section.why-choose-us-section ul li,
	section.about-section ul li {
		width: 100%;
	}
	section.why-choose-us-section .image-block {
		position: relative;
		bottom: 0;
		left: 0;
	}
	.offer-block-two .content {
		max-width: 100%;
	}
	section.we-invite-section .award {
		left: 0;
	}
	section.testimonials-section-two .award {
		position: relative;
		top: 0;
		margin-top: 20px;
	}
}


@media only screen and (max-width: 350px) {

}
/* rtl.css */

/* RTL Css */


.rtl {
	direction: rtl;
	text-align: right;
}

.rtl .owl-carousel {
	direction: ltr;
}

.rtl .main-menu .navigation>li {
    margin-right: 0;
    margin-left: 35px;
}

.rtl .main-menu .navigation>li>ul {
    left: auto;
    right: 0;
    text-align: right;
}

.rtl .main-menu .navigation>li>ul>li>ul {
    left: auto;
    right: 100%;
    text-align: right;
}

.rtl .main-menu .navigation>li>ul>li>a {
    text-align: right;
}

.rtl .main-menu .navigation>li>ul>li:after {
    right: auto;
    left: 0;
}

.rtl .main-menu .navigation>li>ul>li.dropdown>a:after {
    right: auto;
    left: 0;
}

.rtl .main-menu .navigation>li>ul>li>ul>li>a {
    text-align: right;
}

@media only screen and (max-width:1600px) {

    .rtl .main-menu .navigation>li:nth-last-child(-n+3)>ul>li>ul {
        right: auto;
        left: 100%;
    }
    .rtl .main-menu .navigation > li:nth-last-child(-n+2) > ul {
        right: auto;
        left: 0;
    }
    .rtl .main-menu .navigation > li:nth-last-child(-n+2) > ul > li > ul {
        right: auto;
        left: 100%;
    }   
}

.rtl .swiper-container {
    direction: ltr;
}

.rtl .banner-section .banner-slider-nav {
    direction: ltr;
}

.rtl .welcome-section .image-one:before {
    left: auto;
    right: -70px;
}

.rtl .testimonials-section .testimonial-thumbs {
    margin-left: auto;
    margin-right: 0;
}

.rtl .testimonials-section .icon-box .icon {
    margin-right: 0;
    margin-left: 20px;
}

.rtl .newsletter-section .icon-box {
    padding-left: 0;
    padding-right: 95px;
}

.rtl .newsletter-section .icon {
    left: auto;
    right: 0;
}

.rtl .news-widget .image {
    margin-right: 0;
    margin-left: 20px;
}

@media only screen and (min-width: 992px) {
	.rtl .links-widget {
		position: relative;
		left: 0;
		right: 70px;
	}
}

.rtl .main-header.header-style-two .header-upper .link-box {
    margin-right: 0;
    margin-left: -61px;
}

.rtl .banner-section.style-two .content-box .inner {
    padding-right: 40px;
    padding-left: 0;
}

.rtl .banner-section.style-two .content-box .inner:before {
    left: auto;
    right: 0;
}

.rtl .styled-pagination li span:before {transform: rotate(180deg);display: inline-block;}

.rtl .sidebar-page-container .author-box {
    padding-left: 60px;
    padding-right: 260px;
}

.rtl .sidebar-page-container .author-box .image {
    left: auto;
    right: 60px;
}

.rtl .comments-area .comment-box .reply-comment-btn {
    right: auto;
    left: 0;
}

.rtl .comments-area .comment {
    padding-left: 0;
    padding-right: 170px;
}

.rtl .comments-area .comment-box .author-thumb {
    right: 0;
    left: auto;
}

.rtl .header-top ul.contact-info li {
    padding-right: 0;
    margin-right: 0;
    padding-left: 20px;
    margin-left: 20px;
}

.rtl .main-header .header-upper .navbar-right {
    margin-right: 50px;
    margin-left: 0;
}

.rlt .sidebar .search-box .form-group button {
    right: auto;
    left: 0;
}

.rtl .page-title .bread-crumb li {
    margin-right: 0;
    margin-left: 15px;
    padding-right: 0;
    padding-left: 20px;
}

.rtl .page-title .bread-crumb li:before {
    right: auto;
    left: 0;
}

.rtl .offset-lg-2 {
    margin-left: 0;
    margin-right: 16.6666666667%;
}

.rtl section.contact-info-section .icon_box .inner-box {
    padding-left: 40px;
    padding-right: 105px;
}

.rtl section.contact-info-section .icon_box .icon {
    left: auto;
    right: 40px;
}

.rtl .main-header .header-upper .contact-info {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid #eee;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.rtl .main-header .header-upper .contact-info .icon {
    margin-right: 0;
    margin-left: 15px;
}

.rtl .banner-section .video-btn {
    margin-left: 39px;
}

.rtl .check-availability button {
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    right: 0;
}

.rtl .check-availability .left-side {
    padding-right: 60px;
}

.rtl section.about-us-section .content-block .award {
    right: auto;
    left: -92px;
}

.rtl section.about-us-section .content-block .link-btn {
    right: auto;
    left: 0;
}

.rtl .amenities-block .inner-box {
    padding-left: 0;
    padding-right: 95px;
}

.rtl .amenities-block .icon {
    left: auto;
    right: 0;
}

.rtl section.service-section .image-block {
    padding-right: 0;
    padding-left: 70px;
}

.rtl section.service-section .content-block:before {
    left: 0;
    right: -250px;
}

.rtl .text-end {
    text-align: left !important;
}

.rtl section.service-section .text-one {
    left: auto;
    right: 61%;
    margin-left: 0;
    margin-right: -20px;
    transform: rotate(-90deg) translateX(-0) translateY(0);
}

.rtl section.service-section .text-two {
    left: 109%;
}

.rtl .testimonial-block h3 {
    padding-right: 75px;
}

.rtl .testimonial-block h3 .quote {
    left: auto;
    right: -17px;
}

.rtl .testimonial-block .author-info {
    justify-content: flex-end;
}

.rtl section.testimonials-section .content-block {
    margin-right: 0;
    margin-left: 87px;
}

.rtl section.testimonials-section .award {
    right: auto;
    left: -40px;
}

.rtl .news-block .date {
    right: auto;
    left: 20px;
}

.rtl .newsletter-widget button {
    right: auto;
    left: 17px;
}

.rtl .search-popup .search-form fieldset input[type="submit"] {
    right: auto;
    left: 0;
}

.rtl .search-popup .search-form fieldset input[type="search"] {
    padding-right: 30px;
    padding-left: 220px;
}

.rtl section.why-choose-us-section li i {
    display: inline-block;
    margin-right: 0;
    margin-left: 20px;
}

.rtl section.about-section li i {
    display: inline-block;
    padding: 0;
    margin-left: 7px;
    vertical-align: middle;
}

.rtl .owl_nav_style_one.owl-theme .owl-nav {
    right: auto;
    left: -15px;
}

.rtl .owl_nav_style_one.owl-theme .owl-nav .owl-prev {
    right: auto;
    left: 85px;
}

.rtl .owl_nav_style_one.owl-theme .owl-nav .owl-next {
    right: auto;
    left: 16px;
}

.rtl .owl-carousel .room-block-two ul {
    justify-content: flex-end;
}

.rtl .room-block-two li {
    margin-right: 0;
    margin-left: 40px;
}

.rtl section.feature-section .inner-box {
    padding-right: 90px;
}

.rtl section.aminities-section-two .content-block {
    padding-right: 90px;
}

.rtl section.testimonials-section .content-block {
    padding-left: 20px;
    padding-right: 45px;
}

.rtl section.feature-section ul li i {
    display: inline-block;
    margin-left: 20px;
}

.rtl .header-phone-number {
    margin-right: 0;
    margin-left: 30px;
}

.rtl section.welcome-section-two li i {
    display: inline-block;
    margin-right: 0;
    margin-left: 10px;
}

.rtl .feature-block-two ul.list-two li i {
    display: inline-block;
    margin-right: 0;
    margin-left: 10px;
}

.rtl .sub-title {
    padding-right: 0;
}

.rtl .map-section h4 span {
    margin-right: 20px;
    margin-left: 0;
}

.rtl .contact-widget table tr td.lebel {
    padding-right: 0;
    padding-left: 20px;
}

.rtl section.welcome-section-three .author-info .author-wrap {
    margin-right: 0;
    margin-left: 30px;
}

.rtl section.welcome-section-four .inner-box {
    margin-right: 0;
    margin-left: 40px;
}

.rtl section.welcome-section-five blockquote {
    border-left: 0;
    border-right: 2px solid #0e2a4e;
    padding-right: 40px;
}

.rtl section.room-section-three .curve-text {
    left: auto;
    right: 72%;
}

.rtl section.testimonials-section-two .content-block {
    margin-right: 0;
    margin-left: 85px;
}

.rtl section.testimonials-section-two .award {
    right: auto;
    left: -40px;
}

.rtl .mobile-menu {
    text-align: left;
    direction: ltr;
}

.rtl section.aminities-section-four {
    direction: ltr;
}

.rtl section.testimonials-section.style-two:before {
    left: auto;
    right: 0;
}

.rtl section.testimonials-section.style-two:after {
    right: auto;
    left: 0;
}

.rtl section.our-story-section .text-two {
    padding-right: 130px;
    padding-left: 0;
}

.rtl section.welcome-section-six .author-wrap {
    margin-right: 0;
    margin-left: 40px;
}

.rtl section.about-section-two .image {
    margin-right: 0;
    margin-left: 150px;
}

.rtl .history-section h3:before {
    left: auto;
    right: 0;
}

.rtl .history-section h3 {
    padding-right: 55px;
}

.rtl .history-block {
    padding-left: 0;
    padding-right: 173px;
}

.rtl .history-block:before {
    left: auto;
    right: 55px;
}

.rtl .history-block .years {
    left: auto;
    right: 0;
}

.rtl .history-block .image-box .image {
    margin-right: 0;
    margin-left: 30px;
}

.rtl .accordion-box.style-three .block .acc-btn {
    padding-right: 30px;
}

.rtl .accordion-box.style-three .block .icon-outer {
    left: auto;
    right: 20px;
}

.rtl .testimonial-block {
    direction: ltr;
}

.rtl .main-menu .navigation>li>ul>li.dropdown>a:after {
    display: none;
}

.rtl section.restaurant-section .date {
    border-right: 0;
    border-left: 1px solid #ddd;
}

.rtl section.holetic-section .text {
    padding-right: 0;
}

.rtl section.contact-form-section h5 {
    padding-left: 0;
    padding-right: 30px;
}

.rtl section.contact-form-section .icon i {
    left: auto;
    right: 0;
}
/* style.css */


/* Bluebell HTML Template  */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset css
3. Global css
4. Header Section
5. Banner Section
6. About Section
7. Info section
8. Funfacts Section
9. Working Process
10. CTA Section
11. Testimonials Section
12. Brand Logo Section
13. Blog Section
14. Course Section
15. Projects Section
16. Course Package Section
17. CTA Section
18. Two Column Section
19. Team Section 
20. Pricing Section
21. Mission Section
22. History Section
23. Contact Form Section
24. Blog Page



/*** 

====================================================================
        Fonts
====================================================================

***/



/* @font-face {
    font-family: GlacialIndifference-Regular;
    src: url(../fonts/GlacialIndifference-Regular.otf);
  } */
  
  /* @font-face {
    font-family: GlacialIndifference-Bold;
    src: url(../fonts/GlacialIndifference-Bold.otf);
  } */

/*** 

====================================================================
    Reset
====================================================================

***/

* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    font-size: 100%;
    line-height: inherit;
}

/*** 

====================================================================
    Global Settings
====================================================================

***/

/* 

font-family: 'Playfair Display', serif;
font-family: 'Roboto', sans-serif;

*/

body {
    font-size: 16px;
    font-size: 18px;
    line-height: 28px;
    /* color: var(--theme-color); */
    -webkit-font-smoothing: antialiased;
    background: rgb(255, 255, 255);
    font-family: GlacialIndifference-Regular !important;
}

.page-wrapper {
    position: relative;
    width: 100%;
    min-width: 300px;
    z-index: 9;
    margin: 0px auto;
    overflow: hidden;
}

a {
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus,
a:visited {
    text-decoration: none !important;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-weight: normal;
    line-height: 1.25em;
    margin: 0px;
    background: none;
    color: #222;
    font-family: 'Playfair Display', serif;
}

textarea {
    overflow: hidden;
}

button {
    outline: none !important;
    cursor: pointer;
}

.text {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #555555;
    margin: 0px 0px 15px;
}

::-webkit-input-placeholder {
    color: inherit;
}

::-moz-input-placeholder {
    color: inherit;
}

::-ms-input-placeholder {
    color: inherit;
}


.btn-light:not(:disabled):not(.disabled).active:focus, 
.btn-light:not(:disabled):not(.disabled):active:focus, 
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: none;
    outline: none;
}

.btn-light:not(:disabled):not(.disabled).active, 
.btn-light:not(:disabled):not(.disabled):active, 
.show>.btn-light.dropdown-toggle {
    background-color: inherit;
    border-color: inherit;
    color: inherit;
    border-radius: 0;
}

.bootstrap-select .dropdown-menu li a span.text {
    margin-bottom: 0;
}

.bootstrap-select .dropdown-menu li.active a span.text {
    color: #fff;
}

.bootstrap-select .dropdown-toggle .filter-option:after {
    font-family: 'Font Awesome 5 Pro';
    content: "\f107";
    position: absolute;
    right: 15px;
    top: 7px;
    display: block;
    line-height: 30px;
    font-size: 17px;
    text-align: center;
    z-index: 5;
    font-weight: 400;
    color: #fff;
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    z-index: 9;
    overflow: hidden;
}

.auto-container {
    position: static;
    max-width: 1420px;
    padding: 0px 15px;
    margin: 0 auto;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

figure {
    margin-bottom: 0;
}

.owl-carousel img {
    width: auto !important;
    display: inline-block !important;
}

.theme-btn {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.centered {
    text-align: center !important;
}

.gray-bg {
    background-color: #f4f4f4 !important;
}

.light-bg {
    background-color: #fff !important;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.dropdown-toggle::after {
    display: none;
}
.fa {
	line-height: inherit;
}
.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #fff;
}
.owl-nav, .owl-dots {
	display: none;
}
.row {
	margin: 0 -15px;
}
.row>* {
	padding-left: 15px;
	padding-right: 15px;
}
.row.no-gutters {
    margin: 0;
}
.row.no-gutters>* {
	padding-left: 0;
	padding-right: 0;
}

.row.gutters-5 {
	margin: 0 -5px;
}
.row.gutters-5>* {
	padding-left: 5px;
	padding-right: 5px;
}

.opacity_0 {
    opacity: 0;
}
.opacity_1 {
    opacity: 1;
}
.opacity_10 {
    opacity: .10;
}
.opacity_20 {
    opacity: .20;
}
.opacity_30 {
    opacity: .30;
}
.opacity_40 {
    opacity: .40;
}
.opacity_50 {
    opacity: .50;
}
.opacity_60 {
    opacity: .60;
}
.opacity_70 {
    opacity: .70;
}
.opacity_80 {
    opacity: .80;
}
.opacity_90 {
    opacity: .90;
}
.tx_50 {
    transform: translateX(50%);
}
.tx__50 {
    transform: translateX(-50%);
}
.ty_50 {
    transform: translateY(50%);
}
.ty__50 {
    transform: translateY(-50%);
}

/* Btn style */

.theme-btn {
    display: inline-block;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}


/*  Scroll To Top style */

.scroll-to-top {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 65px;
    height: 65px;
    font-size: 18px;
    line-height: 65px;
    text-align: center;
    z-index: 100;
    cursor: pointer;
    border-radius: 50%;
    margin-left: -26px;
    display: none;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    background: var(--theme-color);
    color: #fff;
}

.scroll-to-top.style-two {
    background-color: #2d3247;
}

.scroll-to-top:hover {
    color: #ffffff;
}

/*Btn Style One*/

.btn-style-one {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    overflow: hidden;
    text-transform: uppercase;
    vertical-align: middle;
    border-radius: 3px;
    transition: .5s;
    z-index: 0;
    background: var(--theme-color);
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Roboto";
}

.btn-style-one:hover {
    color: #fff;
}

.btn-style-one:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    content: "";
    background-color: #222;
    transition: .5s;
    opacity: 0;
    border-top-right-radius: 33px;
    border-bottom-right-radius: 33px;
    z-index: -1;
    border-top-left-radius: 33px;
}

.btn-style-one:hover:before {
    width: 100%;
    border-radius: 0;
    opacity: 1;
}

.btn-style-one.btn-md {
    padding: 12px 25px;
    font-size: 13px;
    letter-spacing: 2px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
}

/* Style Two */

.btn-style-two {
    position: relative;
    display: inline-block;
    padding: 15px 40px;
    overflow: hidden;
    vertical-align: middle;
    transition: .5s;
    z-index: 1;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 28px;
    color: #000000;
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

.btn-style-two:hover {
    color: #fff;
}

.btn-style-two:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    content: "";
    background-color: var(--theme-color);
    transition: .5s;
    border-top-right-radius: 33px;
    border-bottom-right-radius: 33px;
    z-index: -1;
}

.btn-style-two:hover:before {
    width: 100%;
    border-radius: 0;
}

.theme-btn i {
    font-size: 18px;
    vertical-align: middle;
    right: -10px;
    position: relative;
    top: 2px;
}


.main-header {
    position: absolute;
    display: block;
    width: 100%;
    z-index: 9999;
    top: 25px;
    left: 0px;
    background: transparent;
    clear: both;
}
.navbar {
  background-color: black !important;
}
.navbar-nav .nav-link.active {
  color: #EABC4C !important; /* Adjust to your desired highlight color */
}

/* .navbar-nav .nav-link {
  color: white !important;
} */
.main-header .inner-container {
    background-color: #fff;
}
a{
  text-decoration: none !important;
}

/* Header Upper */

.main-header .header-upper .navbar-right {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 50px;
}

.main-header .header-upper {
    position: relative;
}

.main-header .header-upper .inner-container {
    position: relative;
    min-height: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    border-radius: 6px;
}

.main-header .header-upper .logo-box {
    z-index: 10;
}

.main-header .header-upper .logo-box .logo {
    position: relative;
    display: block;
    padding: 23px 0px;
}

.main-header .header-upper .left-column,
.main-header .header-upper .right-column {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.main-header .header-upper .search-btn {
    margin-left: 35px;
    padding-left: 20px;
    line-height: 20px;
    border-left: 1px solid #919191;
    margin-right: 20px;
}

.main-header .header-upper .link-btn a {
    vertical-align: middle;
}

.main-header .header-upper .search-toggler {
    color: #212121;
    background: transparent;
    font-size: 14px;
    margin-left: 37px;
    position: relative;
    cursor: pointer;
}

.main-header .header-upper .search-toggler:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 11px;
    background-color: #b0b0b0;
    left: -15px;
    top: 7px;
}

.main-header .nav-outer {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.navbar-right-info .mobile-nav-toggler {
    position: relative;
    width: 50px;
    height: 50px;
    line-height: 44px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 20px;
    margin-left: 30px;
    cursor: pointer;
    border-radius: 50%;
}

.main-header .nav-outer .main-menu {
    position: relative;
}

.main-menu .inner-container {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: -25px;
    background: #fff;
}

.main-menu .navbar-collapse {
    padding: 0px;
    display: block !important;
}

.main-menu .navigation {
    position: relative;
    margin: 0px;
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.main-menu .navigation>li {
    position: relative;
    padding: 28.5px 0px;
    margin-right: 35px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-menu .navigation>li.current>a {
    text-decoration: underline !important;
}

.main-menu .navigation>li.current>a:hover {
    text-decoration: underline;
}

.main-menu .navigation>li:last-child {
    margin-right: 0px;
}

.main-menu .navigation>li>a {
    position: relative;
    display: block;
    text-align: center;
    text-transform: uppercase;
    opacity: 1;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 28px;
    color: #242424;
    font-weight: 700;
    font-family: "Roboto";
}

.main-menu .navigation>li>a:hover {
    color: var(--theme-color);
}

.main-menu .navigation>li>ul {
    position: absolute;
    left: 0px;
    top: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 240px;
    z-index: 100;
    display: none;
    opacity: 0;
    visibility: hidden;
    background-color: var(--theme-color);
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    transition: .5s;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul.from-right {
    left: auto;
    right: 0px;
}

.main-menu .navigation>li>ul>li {
    position: relative;
    width: 100%;
}

.main-menu .navigation>li>ul>li:last-child {
    border-bottom: none;
}

.main-menu .navigation>li>ul>li:before {
    position: absolute;
    content: '';
    right: 0px;
    top: 0px;
    width: 0%;
    height: 100%;
    display: block;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.main-menu .navigation>li>ul>li>a {
    position: relative;
    display: block;
    padding: 16px 30px;
    border-bottom: 1px solid #e57777;
    line-height: 24px;
    text-transform: capitalize;
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-size: 16px;
    color: #454456;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Roboto";
}

.main-menu .navigation>li>ul>li>a:hover {
    color: #ffa7a7;
    padding-left: 45px;
}

.main-menu .navigation>li>ul>li>a:before {
    position: absolute;
    content: "\f105";
    left: 30px;
    top: 15px;
    display: block;
    line-height: 24px;
    font-size: 18px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    opacity: 0;
}

.main-menu .navigation>li>ul>li>a:hover:before {
    opacity: 1;
}

.main-menu .navigation>li>ul>li:last-child>a {
    border-bottom: 0px;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
    position: absolute;
    content: "\f105";
    right: 20px;
    top: 14px;
    display: block;
    line-height: 24px;
    font-size: 17px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
}

.main-menu .navigation>li>ul>li>ul {
    position: absolute;
    left: 100%;
    top: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 240px;
    z-index: 100;
    display: none;
    background-color: var(--theme-color);
    transition: .5s;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.main-menu .navigation>li>ul>li>ul.from-right {
    left: auto;
    right: 0px;
}

.main-menu .navigation>li>ul>li>ul>li {
    position: relative;
    width: 100%;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
    border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 0%;
    height: 100%;
    display: block;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
    border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a {
    position: relative;
    display: block;
    padding: 16px 30px;
    line-height: 24px;
    font-weight: 600;
    border-bottom: 1px solid #e57777;
    font-size: 15px;
    text-transform: capitalize;
    color: #2a2a2a;
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-size: 14px;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Roboto";
}

.main-menu .navigation>li>ul>li>ul>li:last-child>a {
    border-bottom: 0;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
    color: #ffa7a7;
    padding-left: 45px;
}

.main-menu .navigation>li>ul>li>ul>li>a:before {
    position: absolute;
    content: "\f105";
    left: 30px;
    top: 15px;
    display: block;
    line-height: 24px;
    font-size: 18px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    opacity: 0;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover:before {
    opacity: 1;
}

.main-menu .navigation>li>ul>li>ul>li.dropdown>a:after {
    font-family: 'Font Awesome 5 Pro';
    content: "\f105";
    position: absolute;
    right: 30px;
    top: 12px;
    display: block;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    z-index: 5;
}

.main-menu .navigation>li.dropdown:hover>ul {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.main-menu .navigation li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 34px;
    height: 30px;
    border: 1px solid #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    cursor: pointer;
    z-index: 5;
    display: none;
}

.main-header .header-upper .contact-info {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid #eee;
    margin-left: 20px;
}

.main-header .header-upper .contact-info .icon {
    position: relative;
    font-size: 24px;
    text-align: center;
    line-height: 68px;
    margin-right: 15px;
    color: var(--theme-color);
    transition: .5s;
}

.main-header .header-upper .contact-info:hover .icon {
    color: #fff;
    background-color: var(--theme-color);
}

.main-header .header-upper .contact-info .icon:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-transform: scale(.7);
    -ms-transform: scale(.7);
    transform: scale(.7);
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.main-header .header-upper .contact-info:hover .icon:before {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.main-header .header-upper .contact-info .icon span {
    position: relative;
}

.main-header .header-upper .contact-info h5 {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
    color: #818188;
    font-weight: 400;
    font-family: 'DM Sans', sans-serif;
}

.main-header .header-upper .contact-info h4 {
    font-size: 24px;
    line-height: 24px;
    color: #212121;
    font-weight: 400;
}

.main-header .header-upper .contact-info h4 a {
    color: #222;
}

/* Header Style one */
.main-header.header-style-one .auto-container {
    max-width: 100%;
    /* padding: 0 60px; */
}

/* Header style two */

.main-header.header-style-two .auto-container {
    max-width: 100%;
    padding: 0 60px;
}

header.main-header.header-style-two {
    top: 0;
}

.main-header.header-style-two .header-upper .inner-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


/*** 

====================================================================
    Search Popup
====================================================================

***/

.search-popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.90);
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.search-popup .popup-inner {
    width: 100%;
}

.search-popup.popup-visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.search-popup .overlay-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}

.search-popup .close-search {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
    z-index: 5;
}

.search-popup .close-search:hover {
    opacity: 0.70;
}

.search-popup .search-form {
    position: relative;
    padding: 0px 15px 0px;
    max-width: 1024px;
    margin: 100px auto;
    margin-bottom: 100px;
    transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.search-popup .search-form fieldset {
    position: relative;
    border: 7px solid rgba(255, 255, 255, 0.50);
    border-radius: 12px;
}

.search-popup .search-form fieldset input[type="search"] {
    position: relative;
    height: 70px;
    padding: 20px 220px 20px 30px;
    background: #ffffff;
    line-height: 30px;
    font-size: 24px;
    color: #233145;
    border-radius: 7px;
}

.search-popup .search-form fieldset input[type="submit"] {
    position: absolute;
    display: block;
    right: 0px;
    top: 0px;
    text-align: center;
    width: 220px;
    height: 70px;
    padding: 20px 10px 20px 10px;
    color: #ffffff !important;
    line-height: 30px;
    font-size: 20px;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 0px 7px 7px 0px;
    background-color: var(--theme-color);
}

.search-popup h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
    letter-spacing: 1px;
    text-align: center;
    display: none;
}

.search-popup .recent-searches {
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    display: none;
}

.search-popup .recent-searches li {
    display: inline-block;
    margin: 0px 10px 10px 0px;
}

.search-popup .recent-searches li a {
    display: block;
    line-height: 24px;
    border: 1px solid #ffffff;
    padding: 7px 15px;
    color: #ffffff;
    border-radius: 3px;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.search-popup .search-form fieldset input[type="search"]:focus {
    border-color: #ddd;
    -webkit-box-shadow: none;
            box-shadow: none;
}

/*** 

====================================================================
                Sticky Header
====================================================================

***/

.sticky-header {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px 0px;
    z-index: -1;
    background: #ffffff;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
    -webkit-transition: top 300ms ease;
    -o-transition: top 300ms ease;
    transition: top 300ms ease;
}

.fixed-header .sticky-header {
    opacity: 1;
    z-index: 99901;
    visibility: visible;
    background: #fff;
}


/*** 

====================================================================
            Mobile Menu
====================================================================

***/

.nav-outer .mobile-nav-toggler {
    position: relative;
    height: 50px;
    line-height: 44px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    border-radius: 50%;
    float: right;
    margin-left: 15px;
    display: none;
}

.mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 999999;
}

.mobile-menu .mCSB_scrollTools {
    right: -6px;
}

.mobile-menu .mCSB_inside>.mCSB_container {
    margin-right: 5px;
}

.mobile-menu .navbar-collapse {
    display: block !important;
}

.mobile-menu .nav-logo {
    position: relative;
    padding: 30px 25px;
    text-align: left;
    margin-bottom: 100px;
    margin-top: 25px;
}

.mobile-menu-visible {
    overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
    opacity: 1;
    visibility: visible;
}

.mobile-menu .menu-backdrop {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
    transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
    background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
    opacity: 0.30;
    visibility: visible;
    -webkit-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.mobile-menu .menu-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    background: #202020;
    padding: 0px 0px;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    border-radius: 0px;
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.mobile-menu .close-btn {
    position: absolute;
    right: 20px;
    top: 15px;
    line-height: 30px;
    width: 24px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    transition: all 0.9s ease;
}

.mobile-menu .navigation {
    position: relative;
    display: block;
    width: 100%;
    float: none;
}

.mobile-menu .navigation li {
    position: relative;
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>ul>li:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-menu .navigation li ul li>a {
    font-size: 15px;
    margin-left: 20px;
    text-transform: capitalize;
}

.mobile-menu .navigation li>a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-menu .navigation li.current>a:before {
    height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.10);
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul {
    display: none;
}

.mobile-menu .social-links {
    position: relative;
    text-align: center;
    padding: 30px 25px;
}

.mobile-menu .social-links li {
    position: relative;
    display: inline-block;
    margin: 0px 10px 10px;
}

.mobile-menu .social-links li a {
    position: relative;
    line-height: 32px;
    font-size: 16px;
    color: #ffffff;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}


/* Hidden Bar */

.hidden-sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 99999;
    width: 100%;
    max-width: 446px;
    height: 100%;
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    direction: rtl;
}

.hidden-sidebar-close {
    position: absolute;
    bottom: 0;
    right: -70px;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
}

.hidden-sidebar .logo {
    margin-bottom: 35px;
}

.hidden-sidebar .wrapper-box {
    height: 100%;
}

.hidden-sidebar .content-wrapper {
    padding: 80px 30px;
    margin-right: 70px;
    direction: ltr;
    position: relative;
}

.hidden-sidebar .sidebar-widget {
    margin-bottom: 35px;
    padding: 0;
    background-color: transparent;
}

.hidden-sidebar .text-widget .text {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 0;
}

.pdf-widget {
    position: relative;
}

.pdf-widget .row {
    margin: 0 -7.5px;
}

.pdf-widget .column {
    padding: 0 7.5px;
}

.pdf-widget .content {
    background-color: rgb(255 255 255 / 96%);
    text-align: center;
    padding: 30px 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.pdf-widget .content .icon {
    margin-bottom: 15px;
}

.pdf-widget .content h4 {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.contact-widget {
    position: relative;
    margin-bottom: 30px;
}

.contact-widget .icon-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.contact-widget .icon {
    width: 35px;
    font-size: 18px;
    margin-top: 5px;
    color: #fff;
}

.contact-widget .text {
    font-size: 17px;
    line-height: 44px;
    font-weight: 400;
    color: #b3b3b3;
}

.contact-widget .text a {
    color: #ffffff;
}

.contact-widget .text strong {
    color: #fff;
    font-weight: 700;
    display: block;
}

.nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    width: 100%;
    display: none;
    background: rgba(20, 20, 20, 0.70);
    overflow: hidden;
    cursor: none;
}

/* Cursor Style */

.cursor {
    position: absolute;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -o-transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    visibility: hidden;
}

.cursor {
    visibility: visible;
}

.cursor.active {
    opacity: 0.5;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.cursor.hovered {
    opacity: 0.08;
}

.cursor-follower {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -o-transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    visibility: hidden;
}

.cursor-follower {
    visibility: visible;
}

.cursor-follower.active {
    opacity: 0.7;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.cursor-follower.hovered {
    opacity: 0.08;
}

.cursor-follower.close-cursor:before {
    position: absolute;
    content: '';
    height: 25px;
    width: 2px;
    background: #fff;
    left: 48%;
    top: 12px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: inline-block;
}

.cursor-follower.close-cursor:after {
    position: absolute;
    content: '';
    height: 25px;
    width: 2px;
    background: #fff;
    right: 48%;
    top: 12px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/*** 

====================================================================
        Banner Section
====================================================================

***/

.banner-section {
    position: relative;
}

.banner-section .background-text {
    position: absolute;
    right: -280px;
    bottom: 190px;
    z-index: 99;
    text-transform: uppercase;
    font-size: 240px;
    font-weight: 700;
    letter-spacing: -5px;
    line-height: 200px;
    color: rgb(255 255 255 / 0.1);
    transform: rotate(-30deg);
}

.banner-section .background-text .text-2 {
    position: absolute;
    top: -5px;
    left: 15px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255 255 255 / 0.2);
    color: transparent;
}

.three-item-carousel,
.banner-slider {
    position: relative;
    height: 100% !important;
    z-index: 9;
}

.banner-section .swiper-slide {
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.banner-section .swiper-slide:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    opacity: 0.0;
    content: "";
}

.banner-section .content-outer {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    display: table;
    vertical-align: middle;
}

.banner-section .content-box {
    position: relative;
    padding: 120px 15px 10px;
    min-height: 900px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.banner-section .content-box .inner {
    position: relative;
    opacity: 0;
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
}

.banner-section .swiper-slide-active .content-box .inner {
    opacity: 1;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}

.banner-section .content-box h1 {
    position: relative;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    margin-bottom: 30px;
}

.banner-section .content-box h1:before {
    position: absolute;
    content: '';
    height: 3px;
    width: 76px;
    bottom: 0;
    left: 0;
}

.banner-section .content-box.justify-content-center h1:before {
    left: 50%;
    transform: translateX(-50%);
}

.banner-section .swiper-slide-active .content-box h1 {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 800ms;
    -o-transition-delay: 800ms;
    transition-delay: 800ms;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.banner-section .content-box h4 {
    position: relative;
    display: block;
    line-height: 1.2em;
    margin-bottom: 30px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    color: var(--theme-color);
    font-size: 24px;
    font-weight: 500;
    font-family: "DM Sans";
}

.banner-section .swiper-slide-active h4 {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 1200ms;
    -o-transition-delay: 1200ms;
    transition-delay: 1200ms;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.banner-section .content-box .text {
    position: relative;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
    margin-bottom: 40px;
}

.banner-section .swiper-slide-active .text {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 1600ms;
    -o-transition-delay: 1600ms;
    transition-delay: 1600ms;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}

.banner-section .link-box {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transition-delay: 2000ms;
    -o-transition-delay: 2000ms;
    transition-delay: 2000ms;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    margin: 0 -10px;
}

.banner-section .swiper-slide-active .link-box {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.banner-section .link-box a {
    -webkit-transition: .5s ease;
    -o-transition: .5s ease;
    transition: .5s ease;
    margin: 0 10px 10px;
}


.banner-section .video-box {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transition-delay: 2000ms;
    -o-transition-delay: 2000ms;
    transition-delay: 2000ms;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
}

.banner-section .swiper-slide-active .video-box {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.banner-section .video-btn {
    position: relative;
}

.banner-section .video-btn span {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    border-radius: 50%;
}

.banner-section .video-btn a {
    position: relative;
	height: 67px;
	width: 67px;
	line-height: 70px;
	border-radius: 50%;
	color: #111;
	text-align: center;
	font-size: 20px;
	display: inline-block;
	position: relative;
	background-color: #fff;
	transition: .5s;
	margin-right: 52px;
    transition: .5s;
}

.banner-section .video-btn a:hover {
    color: #fff;
    background-color: var(--theme-color);
}

.banner-section .video-btn a:before {
    position: absolute;
    content: '';
    width: 92px;
    height: 92px;
    border-radius: 46px;
    border: 1px solid rgba(255,255,255,0.35);
    left: -12px;
    top: -12px;
}


.banner-section .video-btn .ripple:after {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-webkit-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.banner-section .video-btn .ripple:after {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
}

@-webkit-keyframes ripple {
	70% {
		-webkit-box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
	}
}

@keyframes ripple {
	70% {
		-webkit-box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
	}
}

.banner-section .video-box span {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 47px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}



/* banner nav */

.banner-section .banner-slider-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.banner-section .banner-slider-button-next {
    position: relative;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 9;
    margin-right: 50px;
    font-size: 43px;
    background-color: transparent;
    transition: .5s;
    border-radius: 50%;
    border: 2px solid #fff;
}

.banner-section .banner-slider-button-next:hover {
    color: #fff;
    background: var(--theme-color);
    border-color: var(--theme-color);
}

.banner-section .banner-slider-button-prev {
    position: relative;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 9;
    margin-left: 50px;
    font-size: 43px;
    background-color: transparent;
    transition: .5s;
    transform: rotate(180deg);
    border-radius: 50%;
    border: 2px solid #fff;
}

.banner-section .banner-slider-button-prev:hover {
    color: #fff;
    background: var(--theme-color);
    border-color: var(--theme-color);
}

/* Style Two */
.banner-section .banner-slider-nav.style-two {
    left: 0;
    right: auto;
    top: auto;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-prev {
    width: 106px;
    height: 53px;
    border-radius: 0;
    margin: 0;
    line-height: 53px;
    background: #293e9c;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-next {
    height: 53px;
    width: 106px;
    border-radius: 0;
    margin: 0;
    line-height: 53px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-next i {
    margin-left: 10px;
    position: relative;
    top: 1px;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-prev i {
    margin-right: 10px;
    top: 1px;
    position: relative;
}

/* nav style three */

.banner-section .banner-slider-nav.style-three {
    left: auto;
    right: 60px;
    max-width: 68px;
    height: 160px;
    bottom: 45px;
    top: auto;
    flex-direction: column-reverse;
}

.banner-section .banner-slider-button-prev {
    margin: 0;
}

.banner-section .banner-slider-button-next {
    margin: 0;
}

/* nav style four */

.banner-section .banner-slider-nav.style-four {
    left: auto;
    right: 55px;
    max-width: 136px;
    bottom: 315px;
    top: auto;
}

.banner-section .banner-slider-nav.style-four .banner-slider-button-prev {
    border-radius: 0;
    border-left: 1px solid #fff;
}

.banner-section .banner-slider-nav.style-four .banner-slider-button-next {
    border-radius: 0;
    border-left: 1px solid #fff;
}

/* nav style five */

.banner-section .banner-slider-nav.style-five {
    left: auto;
    right: 90px;
    max-width: 68px;
    height: 160px;
    flex-direction: column-reverse;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
}


/* Title */
.sub-title {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 1.6em;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
    padding-left: 53px;
    padding-right: 53px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
}

/* .text-light .sub-title{
    color: #fff;
} */

.sub-title:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 45px;
    background: var(--theme-color);
    left: 0;
    bottom: 7px;
}

.text-center .sub-title:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 45px;
    background: var(--theme-color);
    right: 0;
    bottom: 7px;
}

@media (max-width:700px){
  .sec-title {
    padding: 0px 5px;

    font-size: 32px;
    line-height: 60px;
    color: #0e2a4e;
    font-weight: 800;
    margin-bottom: 50px;
    position: relative;
}

}
@media (min-width:700px){
  .sec-title {
    font-size: 48px;
    line-height: 60px;
    color: #0e2a4e;
    font-weight: 800;
    margin-bottom: 50px;
    position: relative;
}

}
@media (max-width:700px){
  .sec-title.small {
    font-size: 32px;
}
}
@media (min-width:700px){
  .sec-title.small {
    font-size: 50px;
}
}

.sec-title .style-two {
    font-size: 40px;
    line-height: 60px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Playfair Display";
}

section.service-section .title-box {
    margin-bottom: 45px;
}

/* .text-light .sec-title{
    color:#fff
} */

@media (max-width:700px){
  h2.banner-title {
    font-size: 30px;
    letter-spacing: 2px;
    line-height: 50px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display";
}
}
@media (min-width:700px){
  h2.banner-title {
    font-size:  53px;
    letter-spacing: -2px;
    line-height: 89px;
    color: #ffffff; 
    font-weight: 700;
    font-family: "Playfair Display";
}
}
.tsbor{
  color: #797979 !important;
}
/* .dark_bg{
    background-color: #0e1720;
    background-image: url("../Images/bg-2.jpg") !important;
} */

.light-bg {
    background-color: #fff;
}

@media only screen and (min-width:1320px) {
    .mx-60 {
        margin-left: 0px;
        margin-right: -77px;
    }
}

/* about section */
@media (max-width:700px){
  section.about-us-section {
    position: relative;
    padding: 0px 0 130px;
    z-index: 11;
}
}
@media (min-width:700px){
  section.about-us-section {
    position: relative;
    padding: 160px 0 130px;
    z-index: 11;
}
}

.award {
    position: relative;
    background: var(--theme-color);
    width: 153px;
    height: 165px;
    text-align: center;
    padding-top: 36px;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
}

.award:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: -10px;
    width: 10px;
    height: 10px;
    background: var(--theme-color);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}

@media (min-width:700px) {
  section.about-us-section .content-block .award {
    position: absolute;
    top: 0;
    right: -92px;
}
}
@media (max-width:700px) {
  section.about-us-section .content-block .award {
    /* position: absolute; */
    top: 0;
    right: -92px;
}
}

@media (max-width:700px){
  section.about-us-section .content-block {
    position: relative;
    padding: 70px 65px 64px 28px;
    background: #f1f3f6;
    margin-right: -82px;
    z-index: 5;
    margin-top: 80px;
}
}
@media (min-width:700px){
  section.about-us-section .content-block {
    position: relative;
    padding: 70px 49px 64px 60px;
    background: #f1f3f6;
    margin-right: -82px;
    z-index: 5;
    margin-top: 80px;
}
}

section.about-us-section .content-block:before {
    position: absolute;
    content: '';
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    background-color: rgba(255,255,255,0.28);
    z-index: -1;
}

.award h4 {
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
    margin-top: 14px;
    text-transform: uppercase;
}

section.about-us-section .image {
    /* margin-left: -135px; */
}
@media (max-width:700px){
  .invite-img{
    padding: 0px 0px 0px 0px;
  }
}
@media (min-width:700px){
  .invite-img{
    /* padding: 0px 0px 0px 0px; */
  }
}

@media (max-width:700px){
  section.about-us-section .content-block .text {
    padding: 0px 20px 0px 0px;
    font-size: 18px;
    font-family: GlacialIndifference-Regular !important;
    line-height: 28px;
    color: #797979;
    text-align: justify;
    font-weight: 400;
}
}
@media (min-width:700px){
  section.about-us-section .content-block .text {
    font-size: 18px;
    text-align: justify;
    font-family: GlacialIndifference-Regular !important;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
}
}
section.about-us-section .content-block .signature {
    margin-top: 50px;
}

@media (max-width:700px){
  section.about-us-section .content-block .link-btn {
    /* position: absolute; */
    right: 0;
    bottom: 0;
}
}
@media (min-width:700px){
  section.about-us-section .content-block .link-btn {
    position: absolute;
    right: 0;
    bottom: 0;
}
}

@media (max-width:700px){
  section.about-us-section .content-block .link-btn .btn-style-two {
    padding: 15px 10px;
    border-radius: 0px;
}
}
@media (min-width:700px){
  section.about-us-section .content-block .link-btn .btn-style-two {
    padding: 20px 50px;
    border-radius: 0px;
}
}

/* amenities section */
@media (max-width:700px){
  section.amenities-section {
    margin-top: 10%;
      position: relative;
      padding-bottom: 60px;
  }
}
@media (min-width:700px){
  section.amenities-section {
    margin-top: 5%;
      position: relative;
      padding-bottom: 60px;
  }
}

.layer-one {
    position: relative;
    margin: 0 60px;
    border-radius: 8px;
    background: #fff;
    margin-top: 15px;
    z-index: 1;
    margin-bottom: 15px;
}

.layer-one:before {
    position: absolute;
    content: '';
    left: 15px;
    right: 15px;
    bottom: -15px;
    top: -15px;
    background: rgb(255 255 255 / 15%);
    border-radius: 8px;
}

.border-shape-top {
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.border-shape-top:before {
    position: absolute;
    content: '';
    left: 15px;
    right: 15px;
    top: -13px;
    background: rgb(255 255 255 / 15%);
    height: 13px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.border-shape-bottom {
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.border-shape-bottom:before {
    position: absolute;
    content: '';
    left: 15px;
    right: 15px;
    bottom: -13px;
    background: rgb(255 255 255 / 15%);
    height: 13px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.amenities-block .inner-box {
    position: relative;
    padding-left: 95px;
    margin-bottom: 50px;
}
.amenities-block  .ami-con{
  padding-left: 25px !important;
} 

.amenities-block .icon {
    /* position: absolute; */
    left: 0;
    top: 0;
    font-size: 65px;
    color: #0e2a4e;
}

.amenities-block h1 {
    font-size: 22px;
    line-height: 21px;
    color: #0e2a4e;
    font-family: var(---font2);
    margin-bottom: 15px;
}

.amenities-block .text {
    font-size: 18px;
    line-height: 30px;
    color: #8a8b96;
}

/* room section */

@media (max-width:700px){
  section.room-section {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    /* padding-top: 120px;
    padding-bottom: 120px; */
}
}
@media (min-width:700px){
  section.room-section {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}
}
section.room-section .top-content {
    display: flex;
    flex-wrap: wrap;
    animation: initial;
    justify-content: space-between;
}

section.room-section .top-content .text {
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 0px;
}

section.room-section .top-content .text-two {
    font-size: 20px;
    line-height: 30px;
    color: #797979;
    margin-bottom: 0px;
}

section.room-section .top-content .view-all-btn {
    font-size: 20px;
    line-height: 30px;
    text-decoration: underline;
    color: #dc2e2e;
}

.room-block .inner-box {
    position: relative;
    margin-bottom: 30px;
}

.room-block .image {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.room-block .image:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #000000;
    opacity: .2;
    z-index: 11;
}

.room-block .image img {
    transition: .5s;
}

.room-block .inner-box:hover .image img {
    transform: scale(1.1);
}

.room-block .text-two {
    font-size: 20px;
    line-height: 35px;
    color: #ffffff;
    font-weight: 700;
}

.room-block .image img {
    border-radius: 8px;
}

.room-block h3 {
    font-size: 30px;
    line-height: 35px;
    color: #ffffff;
    font-weight: 800;
    font-family: "Playfair Display";
    margin-bottom: 5px;
}

.room-block h3 a {
    color: #fff;
    transition: .5s;
}

.room-block h3 a:hover {
    color:var(--theme-color);
}

.room-block .content {position: absolute;left: 35px;bottom: 20px;z-index: 99;}

/* room section style two */
section.room-section.style-two .auto-container {
    max-width: 1600px;
}

/* service one */

section.service-section {
    padding-top: 90px;
    padding-bottom: 120px;
    position: relative;
}

section.service-section .image-block {
    padding-right: 70px;
    position: relative;
    z-index: 1;
}

section.service-section .image {
    position: relative;
}

section.service-section .image-two {
    text-align: right;
    margin-top: -218px;
    z-index: 1;
    position: relative;
}

section.service-section .text-one {
    font-size: 15px;
    letter-spacing: 8px;
    line-height: 47px;
    color: rgb(112 112 112 / 50%);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
    position: absolute;
    left: 100%;
    bottom: 0;
    transform: rotate(
-90deg) translateX(0) translateY(0);
    transform-origin: bottom left;
    width: max-content;
    margin-left: -20px;
    margin-bottom: 20px;
}

section.service-section .text-two {
    font-size: 15px;
    letter-spacing: 8px;
    line-height: 28px;
    color: rgb(112 112 112 / 50%);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: rotate(-90deg) translateX(-100%) translateY(0);
    transform-origin: bottom left;
    margin-bottom: 6px;
    margin-left: -9px;
}

section.service-section .image-two img {
    border: 18px solid #f1f3f6;
}

section.service-section .content-block {
    position: relative;
    background: #f1f3f6;
    padding: 80px 30px;
    margin-top: 113px;
}

section.service-section .content-block:before {
    position: absolute;
    content: '';
    left: -250px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #f1f3f6;
}

section.service-section .content-block .time {
    font-size: 18px;
    line-height: 30px;
    color: #8a8b96;
    margin-bottom: 39px;
    position: relative;
}

/* service two */

section.service-section-two {
    position: relative;
    padding-bottom: 90px;
}

section.service-section-two .content-block {
    position: relative;
    background: #f1f3f6;
    padding: 80px 0 125px;
    padding-left: 95px;
}

section.service-section-two .image-block {
    position: relative;
    margin-top: 100px;
    padding-bottom: 50px;
}

section.service-section-two .content-block .text {
    position: relative;
    font-size: 18px;
    line-height: 30px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 0;
}


section.service-section-two .content-block:before {
    position: absolute;
    content: '';
    right: -250px;
    top: 0;
    left: 0;
    bottom: 0;
    background: #f1f3f6;
}

section.service-section-two .image-two {z-index: 1;position: absolute;left: 0;bottom: 0;}

section.service-section-two .image-two {
 border: 18px solid #f1f3f6;
 display: inline-block;
}

section.service-section-two .image-block .image {
    position: relative;
    text-align: right;
}

section.service-section-two .content-block h4 {
    font-size: 18px;
    line-height: 30px;
    color: #797979;
    font-weight: 400;
    font-family: inherit;
}

section.service-section-two .content-block h4 a {
    color: #797979;
}

/* cta section */

section.cta-section {
    position: relative;
    padding-top: 130px;
    padding-bottom: 160px;
    background-repeat: no-repeat;
}

section.cta-section .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
}

section.cta-section .text {
    margin: 0px 0px 50px;
    font-size: 18px;
    line-height: 35px;
    color: #c1c1c1;
    font-weight: 800;
    position: relative;
}

section.cta-section .offer-image {
    position: absolute;
    left: 43%;
    top: -33px;
}

/* cta section two */

section.cta-section-two{
position: relative;
padding-top: 130px;
padding-bottom: 160px;
background-repeat: no-repeat;

}

section.cta-section-two .bg-image{
position: absolute;
top: -245px;
left: 0;
right: 0;
bottom: 0;
background-repeat: no-repeat;
}

section.cta-section-two .offer-image {
    position: absolute;
    left: 43%;
    top: -21px;
}

section.cta-section-two .text {
    margin-bottom: 51px;
    font-size: 22px;
    line-height: 35px;
    color: #c1c1c1;
    font-weight: 400;
}

/* cta section three */

section.cta-section-three{
    position: relative;
    padding-top: 130px;
    padding-bottom: 160px;
    background-repeat: no-repeat;    
}

section.cta-section-three .bg-image{
    position: absolute;
    top: -412px;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    }

section.cta-section-three .offer-image{
    position: absolute;
    left: 43%;
    top: -21px;    
}

section.cta-section-three .text {
    margin-bottom: 51px;
    font-size: 22px;
    line-height: 35px;
    color: #c1c1c1;
    font-weight: 400;
}



/* testimonials */

@media (max-width:700px){
  section.testimonials-section {
  padding-bottom: 45px;
  padding-top: 40px;
  position: relative;
}
}
@media (min-width:700px){
  section.testimonials-section {
  padding-bottom: 45px;
  padding-top: 100px;
  position: relative;
}
}

.owl-dot-style-one .owl-dots {
    text-align: right;
    margin-top: -30px;
}

@media (max-width:700px){
  section.testimonials-section .award {
    position: absolute;
    top: 301px;
    right: 93px;
    background: var(--theme-color);
    width: 153px;
    height: 165px;
    text-align: center;
    padding-top: 36px;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
    padding-left: 5px;
    padding-right: 5px;
}
}
@media (min-width:700px){
  section.testimonials-section .award {
    position: absolute;
    top: 70px;
    right: -40px;
    background: var(--theme-color);
    width: 153px;
    height: 165px;
    text-align: center;
    padding-top: 36px;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
    padding-left: 5px;
    padding-right: 5px;
}
}

section.testimonials-section .award:before {
    position: absolute;
    content: '';
    right: -.5px;
    bottom: -9px;
    width: 10px;
    height: 10px;
    background: var(--theme-color);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}

section.testimonials-section .award h4 {
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
    margin-top: 14px;
    text-transform: uppercase;
}
@media (max-width:700px){
  section.testimonials-section .content-block {
    position: relative;
    margin-right: -2px;
    background-size: cover;
    border-radius: 8px;
    padding-left: 45px;
    padding-bottom: 202px;
    padding-top: 58px;
}
}
@media (min-width:700px){
  section.testimonials-section .content-block {
    position: relative;
    margin-right: 87px;
    background-size: cover;
    border-radius: 8px;
    padding-left: 45px;
    padding-bottom: 116px;
    padding-top: 58px;
}
}


section.testimonials-section .content-block .sub-title:before {
    background-color: #fff;
}

/* testimonials section two */

section.testimonials-section-two {
    padding-top: 95px;
    padding-bottom: 115px;
    position: relative;
}

section.testimonials-section-two .award {
    position: absolute;
    top: 70px;
    right: -40px;
    background: var(--theme-color);
    width: 153px;
    height: 165px;
    text-align: center;
    padding-top: 36px;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
}

section.testimonials-section-two .award:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: -10px;
    width: 10px;
    height: 10px;
    background: var(--theme-color);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}

section.testimonials-section-two .award h4 {
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    color: #fff;
    font-weight: 600;
    font-family: "Poppins";
    margin-top: 14px;
    text-transform: uppercase;
}

section.testimonials-section-two .content-block {
    position: relative;
    margin-right: 85px;
    background-size: cover;
    border-radius: 8px;
    padding-left: 45px;
    padding-bottom: 60px;
    padding-top: 85px;
}

section.testimonials-section-two h3 .quote {
    font-size: 150px;
    letter-spacing: 15px;
    line-height: 135px;
    font-weight: 700;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--theme-color);
    color: transparent;
    font-family: "Roboto";
    position: absolute;
    top: -4px;
    left: 0;
}

section.testimonial-section-two .text {
    font-size: 18px;
    line-height: 28px;
    color: #f53030;
    font-weight: 400;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

section.testimonial-section-two .author-info {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

section.testimonial-section-two .author-thumb {
    margin-right: 20px;
}

section.testimonial-section-two .name {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 47px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

/* funfact section */

section.funfact-section {
    position: relative;
    padding-top: 100px;
    padding-bottom: 120px;
}

section.funfact-section span.count-text {
    font-size: 55px;
    line-height: 45px;
    color: #172460;
    font-weight: 700;
    font-family: "Playfair Display";
}

span.plus {color: var(--theme-color);font-size: 55px; font-weight: 100;}

section.funfact-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
   
}
.boder-bottom-two {
    border-bottom: 2px dashed #c1c1c1;
}

@media(max-width:700px){
  section.funfact-section .count-outer.count-box {
    margin-bottom: 20px;
    justify-content: center;
}
}
@media(min-width:700px){
  section.funfact-section .count-outer.count-box {
    margin-bottom: 20px;
}
}

/* news section */

@media (max-width:700px){
  section.news-section {
    position: relative;
    padding-top: 120px;
    /* padding-bottom: 30px; */
    background-color: #f8f9fa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
}
@media (min-width:700px){
  section.news-section {
    position: relative;
    padding-top: 120px;
    padding-bottom: 30px;
    background-color: #f8f9fa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
}

.news-block .inner-box {
    border-radius: 10px;
}

.news-block .date {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 25px;
    color: #ffffff;
    font-family: GlacialIndifference-Bold;
    text-align: center;
    width: 84px;
    height: 84px;
    border-radius: 42px;
    background-color: var(--theme-color);
    padding-top: 17px;
    transform: translateY(-50%);
    line-height: 24px;
    padding-left: 5px;
    padding-right: 5px;
}
@media (max-width:700px){
  .testimonial-slider{
    margin-top: 23px;
  }
  
}
@media (min-width:700px){
  .testimonial-slider{
    margin-top: 0px;
  }
  
}
.food-img{
  height: 130px !important;
}

@media (max-width:700px){
  .news-block .scroll4 {
    /* height: 650px; */
      padding: 25px 35px 40px;
      height: 430px;
      margin-bottom: 30px;
      overflow-y: scroll;
      margin-left: 25px;
      filter: drop-shadow(0px 12px 12.5px rgba(0,0,0,0.04));
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 10px;
  
  }
}
@media (min-width:700px){
  .news-block .scroll4 {
    height:  350px;
    overflow-y: scroll;
      padding: 25px 35px 40px;
      margin-bottom: 30px;
      margin-left: 25px;
      filter: drop-shadow(0px 12px 12.5px rgba(0,0,0,0.04));
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 10px;
  
  }
  
}
@media (max-width:700px){
  .news-block .lower-content {
    /* height: 650px; */
      padding: 25px 35px 40px;
      height: 635px;
      /* overflow-y: scroll; */
      margin-left: 25px;
      filter: drop-shadow(0px 12px 12.5px rgba(0,0,0,0.04));
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 10px;
  
  }
}
@media (min-width:700px){
  .news-block .lower-content {
    height:  540px;
    /* overflow-y: scroll; */
      padding: 25px 35px 40px;
      margin-left: 25px;
      filter: drop-shadow(0px 12px 12.5px rgba(0,0,0,0.04));
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 10px;
  
  }
  
}
.news-block .image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.news-block .image img {
    border-radius: 10px;
    transition: .7s;
    height: 100% !important;
}

.news-block .inner-box:hover .image img {
    transform: scale(1.1);
}

.news-block .date span {
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
}

.news-block .post-meta {
  font-size: 16px;
  text-align: justify;
  line-height: 29px;
  color: #797979;
  margin-bottom: 10px;
}

.news-block h3 {
    font-size: 21px;
    color: #0e2a4e;
    font-weight: 800;
    margin-bottom: 30px;
}

.news-block h3 a {
    color: #0e2a4e;
    transition: .5s;
}

.news-block h3 a:hover {
    color: var(--theme-color);
}

.btn-style-three {
    border-radius: 19px;
    background-color: #f7f4f2;
    padding: 7px 28px;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 25px;
    color: var(--theme-color);
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins";
    transition: .5s;
}

.btn-style-three:hover {
    color: #fff;
    background-color: var(--theme-color);
}

/* map section */

.map-section {
    position: relative;
    padding-bottom: 140px;
}

.map-section .auto-container {
    max-width: 1600px;
}

.map-section .content-column  {
    background-color: #131c34;
    padding-left: 80px;
    padding-top: 100px;
    padding-right: 65px;
    padding-bottom: 37px;
}

.map-section .text {
    font-size: 20px;
    line-height: 30px;
    color: #aab9cc;
    font-weight: 400;
    margin-bottom: 50px;
}

.map-section h4 span {
    margin-left: 35px;
    font-size: 20px;
    color: #ffffff;
    font-weight: 400;
}

.map-section h4 {
    margin-bottom: 50px;
    font-family: inherit;
}

.map-section .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #aab9cc;
    font-weight: 400;
    margin-top: 20px;
}

.map-section h5 {
    font-size: 20px;
    line-height: 28px;
    color: #aab9cc;
    font-family: 'GlacialIndifference-Bold';
}

/* testimonial block */

section.testimonials-section .auto-container {
    position: relative;
    z-index: 9;
}

.testimonial-block {
    position: relative;
    margin-bottom: 30px;
}

@media (max-width:700px){
  .testimonial-block h3 {
    font-size: 25px;
    line-height: 21px;
    color: #0e2a4e;
    font-weight: 700;
    position: relative;
    padding-left: 74px;
    padding-top: 26px;
    margin-bottom: 35px;
}
}
@media (min-width:700px){
  .testimonial-block h3 {
    font-size: 30px;
    line-height: 21px;
    color: #0e2a4e;
    font-weight: 700;
    position: relative;
    padding-left: 74px;
    padding-top: 10px;
    margin-bottom: 35px;
}
}
@media (max-width:700px){
  .table-text{
    font-size: 17px;
  }
}

@media (max-width:700px){
  .testimonial-block h3 .quote {
    font-size: 108px;
    letter-spacing: 15px;
    line-height: 135px;
    font-weight: 700;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--theme-color);
    color: transparent;
    font-family: "Roboto";
    position: absolute;
    top: -4px;
    left: 0;
}
}
@media (min-width:700px){
  .testimonial-block h3 .quote {
    font-size: 150px;
    letter-spacing: 15px;
    line-height: 135px;
    font-weight: 700;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--theme-color);
    color: transparent;
    font-family: "Roboto";
    position: absolute;
    top: -4px;
    left: 0;
}
}

.testimonial-block .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.testimonial-block .author-info {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.testimonial-block .author-thumb {
    margin-right: 20px;
}

.testimonial-block .name {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 47px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

.testimonial-block.light-text h3 {
    color: #fff;
}

.testimonial-block.light-text .text {
    color: #9b9faa;
    border-color: #3b424a;
}

/* about section */

section.about-section {
    padding-top: 115px;
    padding-bottom: 130px;
    position: relative;
}

section.about-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 30px;
}

section.about-section .text-two {
    font-size: 20px;
    line-height: 28px;
    color: #191919;
    font-weight: 400;
    margin-bottom: 30px;
}

section.about-section .icon-list {
    font-size: 20px;
    line-height: 40px;
    color: #797979;
    font-weight: 400;
}

section.about-section ul li {width: 50%;}

section.about-section ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

section.about-section  li i {color: var(--theme-color);padding-right: 8px;}

section.about-section .content-block {
    padding-right: 50px;
}

/* why-choose us section */

@media (max-width:700px){
  section.why-choose-us-section {
    padding-top: 115px;
    background-color: #f1f3f6;
    padding-bottom: 0px;
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
}
@media (min-width:700px){
  section.why-choose-us-section {
    padding-top: 115px;
    background-color: #f1f3f6;
    padding-bottom: 115px;
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
}

section.why-choose-us-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 70px;
}

@media (max-width:700px){
  section.why-choose-us-section .text-two {
    font-size: 18px;
    font-family: GlacialIndifference-Regular;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    /* font-style: italic; */
    margin-bottom: 30px;
    margin-left: 5px;
}
}
@media (min-width:700px){
  section.why-choose-us-section .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    text-align: justify;
    /* font-style: italic; */
    margin-bottom: 30px;
    margin-left: 65px;
}
}


@media (max-width:700px){
  section.why-choose-us-section .icon-list {
    font-size: 12px;
    color: #797979;
    text-align: justify;
    font-weight: 400;
    margin-left: -14px;
}
}
@media (min-width:700px){
  section.why-choose-us-section .icon-list {
    font-size: 20px;
    color: #797979;
    font-weight: 400;
    margin-left: 65px;
}
}

section.why-choose-us-section ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

section.why-choose-us-section ul li {
    width: 50%;
    margin-bottom: 15px;
}

section.why-choose-us-section li i {
    color: var(--theme-color);
    padding-right: 18px;
    display: inline-block;
}

section.why-choose-us-section .image-block {
    position: absolute;
    left: 47px;
    text-align: right;
    background-color: #ffffff;
    border: 12px solid #ffffff;
    bottom: -80px;
    padding-bottom: 70px;
    filter: drop-shadow(0px 3px 28.5px rgba(0,0,0,0.09));
}

section.why-choose-us-section .text-three {
    position: absolute;
    left: 101px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
    font-weight: 600;
    font-family: 'GlacialIndifference-Bold';
    bottom: -61px;
}

section.why-choose-us-section span {
    font-size: 22px;
    line-height: 22px;
    color: #333333;
    font-weight: 700;
    font-family: 'GlacialIndifference-Bold';
}


.why-choose-us-block .inner-box {
    position: relative;
}

/* room service section */

section.room-service-section {
    position: relative;
    padding-top: 115px;
    padding-bottom: 120px;
}

/* funfact section two */

section.funfact-section-two {
    position: relative;
    padding-top: 80px;
    padding-bottom: 120px;
}


section.funfact-section span.count-text {
    font-size: 55px;
    line-height: 45px;
    color: #172460;
    font-weight: 700;
    font-family: "Playfair Display";
}


.boder-bottom-two {
    border-bottom: 2px dashed #c1c1c1;
}

/* gallery section */

section.gallery-section {
    position: relative;
    padding-top: 115px;
    padding-bottom: 115px;
}

.gallery-block {
    margin-bottom: 30px;
}

.boder-bottom-three {
    border-bottom: 2px dashed rgb(193 193 193 / 20%);
}

/* offer section */

section.offer-section {
    position: relative;
    padding-top: 120px;
    padding-bottom: 115px;
}

.offer-block .offer {
    font-size: 48px;
    line-height: 40px;
    color: #ffffff;
    font-weight: 700;
    width: 134px;
    height: 134px;
    border-radius: 67px;
    background-color: var(--theme-color);
    position: absolute;
    top: 20px;
    text-align: center;
    padding-top: 28px;
    text-transform: uppercase;
    left: 20px;
    font-family: GlacialIndifference-Bold;
}

.offer-block .inner-box {
    position: relative;
}

.offer-block .inner-box img {
    width: 100%;
}

.offer-block .offer span {
    font-size: 32px;
    letter-spacing: 1px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 700;
}

section.offer-section .lower-content {
    margin: 10px 10px;
    padding: 20px 30px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}

section.offer-section h3.wrapper {
    font-size: 28px;
    line-height: 26px;
    color: #0e2a4e;
    font-family: 'GlacialIndifference-Bold';
    margin-bottom: 9px;
}

section.offer-section .text-three {
    font-size: 18px;
    line-height: 22px;
    color: #797979;
    font-weight: 400;
    margin-top: 16px;
}

.offer-block-two .inner-box {
    position: relative;
}

.offer-block-two .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 160px;
    background: #0e2a4e;
    padding: 30px 20px;
}

.offer-block-two h3 {
    font-size: 22px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins";
    margin-bottom: 38px;
}


.offer-block-two .text {
    font-size: 48px;
    line-height: 40px;
    color: #ffffff;
    font-family: 'GlacialIndifference-Bold';
    margin-bottom: 60px;
}

.offer-block-two .text-two {
    font-size: 18px;
    line-height: 24px;
    color: rgb(255 255 255 / 60%);
    font-weight: 400;
}

.offer-block-two .text span {
    font-size: 32px;
    letter-spacing: 1px;
    line-height: 20px;
    color: #ffffff;
}

/*  */

section.promotions-offers-section .lower-content {
    margin: 10px 10px 10px;
    padding: 18px 30px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}

section.promotions-offers-section h4.wrapper {
    font-size: 28px;
    line-height: 26px;
    color: #0e2a4e;
    font-family: 'GlacialIndifference-Bold';
    margin-bottom: 9px;
}

section.promotions-offers-section .text-three {
    font-size: 18px;
    line-height: 22px;
    color: #797979;
    font-weight: 400;
    margin-top: 10px;
}


/* funfact style two */
section.funfact-section.style-two {
    padding-bottom: 90px;
}

section.funfact-section.style-two span.count-text {
    color: #fff;
}

.offer-block-three {
    padding-left: 45px;
}

.offer-block-three .text {
    font-size: 18px;
    line-height: 30px;
    color: #a2a8b2;
    font-weight: 400;
    margin-top: 27px;
}

/* service section */

section.service-section-three {
    padding-top: 120px;
    padding-bottom: 115px;
    position: relative;
}

.service-block-one .image-three img {
    border: 18px solid #ffffff;
}

.service-block-two .image-four img {
    border: 18px solid #ffffff;
}

section.service-section-three .image-three {
    text-align: right;
    position: relative;
    z-index: 1;
    margin-top: -218px;
    
}

.service-section-three .image-four {
    text-align: right;
    z-index: 1;
    margin-top: -228px;
    position: relative;
}

.service-section-three .time {
    font-size: 18px;
    line-height: 40px;
    color: #8a8b96;
    font-weight: 400;
    margin-bottom: 35px;
}

section.service-section-three .text {
    font-size: 18px;
    line-height: 30px;
    color: #797979;
    font-weight: 400;
    margin-top: 0px;
}

section.service-section-three h4 {
    font-size: 18px;
    line-height: 30px;
    color: #797979;
    font-weight: 400;
    font-family: inherit;
}

section.service-section-three h4 a {
    color: #797979;
}

section.service-section-three .text-two {
    font-size: 15px;
    letter-spacing: 8px;
    line-height: 28px;
    color: rgb(112 112 112 / 50%);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
    position: absolute;
    left: 13px;
    bottom: 100%;
    transform: rotate(
-90deg) translateX(-100%) translateY(0);
    transform-origin: bottom left;
    margin-bottom: 3px;
    margin-left: -22px;
}

.service-block-one .image-block {
    position: relative;
}

.service-block-one .title-box {
    margin-top: 80px;
}

.service-block-two .title-box {
    margin-top: 90px;
}

/* footer section */

.footer-gallery {
    padding-top: 110px;
}

.widgets-section {
    padding-top: 120px;
    position: relative;
    padding-bottom: 70px;
}

h3.widget-title {
    font-size: 24px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 7px;
}

.contact-widget .image {
    margin-bottom: 30px;
}

.contact-widget table {
    font-size: 18px;
    line-height: 22px;
    color: black;
    /* color: rgb(255 255 255 / 60%); */
    font-weight: 400;
}

.contact-widget table tr td {padding-bottom: 19px;vertical-align: text-bottom;}

.contact-widget table tr td a {
    /* color: rgb(255 255 255 / 60%); */
    color: black;
    transition: .5s;
}

.contact-widget table tr td a:hover {
    color: var(--theme-color);
}

.contact-widget table tr td.lebel {
    padding-right: 25px;
}

.widget.links-widget .widget-content ul li a {
    font-size: 18px;
    line-height: 22px;
    color: black;
    /* color: rgb(255 255 255 / 60%); */
    font-weight: 400;
    transition: .5s;
}

.widget.links-widget .widget-content ul li a:hover {
    color:var(--theme-color);
}

.boder-bottom-four {
    border-bottom: 2px dashed rgb(193 193 193 / 10%);
}

.footer-bottom .wrapper-box {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 400;
}

.footer-bottom .text {
    color: black;
}

.widget.links-widget .widget-content ul li {
    margin-bottom: 16px;
}

.newsletter-widget .newsletter-form {
    position: relative;
}

.newsletter-widget input#subscription-email {
    position: relative;
    width: 100%;
    background: transparent;
    border: 2px solid rgb(255 255 255 / 20%);
    height: 60px;
    color: rgb(255 255 255 / 56%);
    padding: 0 20px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.newsletter-widget button {
    font-size: 18px;
    position: absolute;
    color: rgb(255 255 255 / 50%);
    right: 35px;
    top: 11px;
    background-color: transparent;
}

/* welcome section */

section.Welcome-section {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
}

section.Welcome-section .auto-container {
    max-width: 1420px;
}

section.Welcome-section .sec-title.text-light {
    font-size: 50px;
    line-height: 60px;
    color: #ffffff;
    font-weight: 800;
    font-family: "Playfair Display";
}

section.Welcome-section .text {
    font-size: 22px;
    color: var(--theme-color);
    font-weight: 400;
    font-style: italic;
    margin-bottom: 30px;
}

section.Welcome-section .text-two {
    font-size: 18px;
    line-height: 30px;
    color: #afbac3;
    font-weight: 400;
}

section.Welcome-section .text-three {
    font-size: 18px;
    line-height: 30px;
    color: #afbac3;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 40px;
}

/* room section two */
section.room-section-two {
    position: relative;
    padding-top: 115px;
    padding-bottom: 120px;
}

.room-block-two .inner-box {
    max-width: 520px;
}

@media only screen and (min-width:992px) {
    
    .room-block-two:nth-child(even) .inner-box {
        margin: 0 auto;
    }
    
}

.room-block-two .text {
    position: absolute;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 53px;
    color: #0e2a4e;
    font-weight: 400;
    width: 202px;
    height: 55px;
    border-radius: 8px;
    background-color: #ffffff;
    left: 25px;
    bottom: 25px;
    text-align: center;
    margin-bottom: 0;
}

.room-block-two .image {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
    border-radius: 8px;
}

.room-block-two .image img {
    transition: .5s;
}

.room-block-two .image:hover img {
    transform: scale(1.1);
}

.room-block-two h3 {
    font-size: 32px;
    line-height: 40px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 20px;
}

.room-block-two h3 a {
    color: #0e2a4e;
    transition: .5s;
}

.room-block-two h3 a:hover {
    color: var(--theme-color);
}

.room-block-two .icon-list {
    font-size: 18px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
}

.room-block-two .icon-list {
    font-size: 18px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
}

.room-block-two ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.room-block-two li {margin-right: 40px;}

.room-block-two li i {
    margin-right: 10px;
    font-size: 24px;
    position: relative;
    vertical-align: middle;
}

.room-block-two .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 30px;
}

/* feature section */

section.feature-section .sec-title {
    margin-bottom: 24px;
}

section.feature-section .auto-container {
    max-width: 100%;
    padding: 0;
}

section.feature-section .inner-box {
    background-color: #eae7e7;
    padding-left: 90px;
    padding-top: 100px;
    padding-bottom: 100px;
}

section.feature-section {
    position: relative;
}

section.feature-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 20px;
}

section.feature-section .icon-list {
    font-size: 20px;
    line-height: 40px;
    color: #0e2a4e;
    font-weight: 400;
    margin-bottom: 30px;
}

section.feature-section ul li i {padding-right: 8px;color: var(--theme-color);vertical-align: middle;position: relative;top: 2px;}

section.feature-section .image img{
    width: 100%;
}

section.feature-section .image-column {
    position: relative;
    margin-bottom: 10px;
}

section.feature-section .image {
    display: none;
}

section.feature-section .bg {
    position: absolute;
    top: 0;
    right: 5px;
    left: 5px;
    bottom: 0;
    background-size: cover;
}

section.aminities-section-two .image-column {
    position: relative;
    margin-bottom: 10px;
}

section.aminities-section-two .bg {
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    left: 5px;
    background-size: cover;
}

section.aminities-section-two .image {
    display: none;
}

section.aminities-section-two .content-column {
    margin-bottom: 10px;
}

section.feature-section .content-column {
    margin-bottom: 10px;
}

/* aminities section two */

section.aminities-section-two {
    position: relative;
}

section.aminities-section-two .auto-container {
    max-width: 100%;
    padding: 0;
}

section.aminities-section-two .content-block {
    background-color: #84a0b8;
    padding-left: 90px;
    padding-top: 100px;
    padding-bottom: 50px;
}

/* News section two */
section.news-section-two {
    position: relative;
    background: #f8f9fa;
    padding: 120px 0 130px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
section.news-section-two .auto-container {
    max-width: 1400px;
    padding: 0;
}

/* aminities block style two */
.amenities-block.style-two .icon {
    color: #fff;
}

.amenities-block.style-two h1 {
    color: #fff;
}

.amenities-block.style-two .text {
    color: #fff;
}

/* welcome-section-two */

section.welcome-section-two {
    padding-top: 160px;
    padding-bottom: 130px;
    position: relative;
    z-index: 9;
}

section.welcome-section-two .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 40px;
}

section.welcome-section-two .text-three {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 35px;
}

section.welcome-section-two .icon-list {
    font-size: 20px;
    line-height: 40px;
    color: #797979;
    font-weight: 400;
}

section.welcome-section-two ul li {width: 50%;font-size: 18px;line-height: 32px;color: #181e44;margin-bottom: 7px;}

section.welcome-section-two ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 65px;
}

section.welcome-section-two  li i {color: var(--theme-color);margin-right: 8px;vertical-align: middle;border: 1px solid var(--theme-color);height: 16px;width: 16px;border-radius: 50%;text-align: center;display: inline-block;line-height: 15px;font-size: 13px;}


section.welcome-section-two .image-two {
    position: absolute;
    right: 34px;
    top: 231px;
    border: 20px solid #ffff;
}

section.welcome-section-two .image-three {
    margin-top: 20px;
}

.border-bottom-four {
    border-bottom: 1px solid #d9d9d9;
    max-width: 530px;
}

/* Amenities section two */
section.amenities-section-two {
    position: relative;
    padding-bottom: 80px;
    background-color: #fff;
}

.Welcome-block-two {
    position: relative;
}

/* Feature section two */
section.feature-section-two {
    position: relative;
    padding-top: 115px;
    padding-bottom: 80px;
}

.feature-block-two .inner-box {
    max-width: 490px;
}

@media only screen and (min-width:992px) {
    
    .feature-block-two:nth-child(even) .inner-box {
        margin: 0 auto;
        margin-right: 0;
    }
}


.feature-block-two .image {
    display: inline-block;
    position: relative;
    margin-bottom: 50px;
}

.feature-block-two .image:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    opacity: .15;
    width: 33%;
}

.feature-block-two .image:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: .15;
    width: 33%;
}

.feature-block-two h5 {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 40px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    margin-bottom: 5px;
}

.feature-block-two ul.list-one li {
    font-size: 18px;
    color: #8a8b96;
    margin-bottom: 5px;
}

.feature-block-two ul.list-one li span {
    color: #8a8b96;
    font-weight: 700;
}

.feature-block-two ul.list-two li {
    font-size: 20px;
    line-height: 32px;
    color: #797979;
    margin-bottom: 6px;
}

.feature-block-two ul.list-two.light-text li {
    color: #9b9faa;
}

.feature-block-two ul.list-two li i {
    position: relative;
    font-size: 28px;
    vertical-align: middle;
    margin-right: 10px;
    color: var(--theme-color);
    top: 3px;
}

.feature-block-two {
    margin-bottom: 30px;
}
/* feature section three  */

section.feature-section-three {
    position: relative;
    padding-bottom: 45px;
    padding-top: 115px;
}

/* team section */

section.team-section {
    position: relative;
    padding-top: 120px;
    padding-bottom: 90px;
    background-color: #f8f9fa;
}

section.team-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 70px;
}

/* gallery section two */

section.gallery-section-two {
    padding-bottom: 120px;
    padding-top: 120px;
}

section.gallery-section-two .auto-container {
    max-width: 1600px;
}

/* gallery section three */

section.gallery-section-three {
    position: relative;
    padding-bottom:115px;
    padding-top: 115px;
}

section.gallery-section-three .auto-container {
    max-width: 1600px;
}

/* welcome section seven */

section.welcome-section-seven {
    position: relative;
    padding-bottom: 140px;
}

section.welcome-section-seven .sec-title {
    margin-bottom: 5px;
}

section.welcome-section-seven a.read-more-btn {
    font-size: 24px;
    line-height: 32px;
    color: var(--theme-color);
    display: inline-block;
    margin-top: 30px;
    transition: .5s;
}

section.welcome-section-seven a.read-more-btn i {
    margin-left: 8px;
}

section.welcome-section-seven a.read-more-btn:hover {
    color: #fff;
}

section.welcome-section-seven .content-block h3 {
    font-size: 32px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 35px;
    line-height: 45px;
}

section.welcome-section-seven .content-block .icon {
    color: #fff;
    text-align: center;
    font-size: 66px;
}

section.we-invite-section .award {
    position: absolute;
    top: -79px;
    left: -180px;
}


section.welcome-section-seven .text-two {
    font-size: 20px;
    line-height: 28px;
    color: hsl(240deg 29% 97% / 70%);
    font-weight: 400;
    text-align: center;
}

section.welcome-section-seven .content-block .link-btn {
    text-align: center;
    margin-top: 40px;
}

section.welcome-section-seven .content-block {
    max-width: 318px;
    background-color: #891913;
    padding: 10px 10px 10px;
    margin: 0 auto;
    margin-right: 0;
}
section.welcome-section-seven .content-block .inner-box {
    border: 1px solid #9e423d;
    padding-top: 35px;
    padding-bottom: 45px;
}

section.welcome-section-seven .price {
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 53px;
    color: #ffffff;
    font-weight: 400;
    font-family: 'Poppins';
}

section.welcome-section-seven .text {
    font-size: 20px;
    line-height: 32px;
    color: rgb(246 246 250 / 60%);
    font-weight: 400;
    margin-top: 40px;
}

section.welcome-section-seven .content-block .btn-style-two {
    padding: 8px 25px;
}

section.welcome-section-seven .content-block .btn-style-two:before {
    background: #0f1925;
}

section.welcome-section-seven .content-block .btn-style-two:hover {
    color: #fff;
}


/* we invite section */

.we-invite-section {
    position: relative;
    padding-top: 115px;
    padding-bottom: 140px;
}

.we-invite-section .contact-block {
    position: relative;
    background: #fff;
    padding-top: 115px;
    padding-left: 130px;
    padding-right: 75px;
    padding-bottom: 85px;
    margin-left: -80px;
    box-shadow: 0px 12px 30px rgb(0 0 0 / 5%);
}

.we-invite-section .image-block {
    position: relative;
    margin-top: 80px;
    background: #f1f3f6;
}

section.we-invite-section .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

section.we-invite-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 40px;
}
section.we-invite-section .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 50px;
}

section.we-invite-section .image-two {
    position: absolute;
    right: 0;
    bottom: -14px;
    z-index: 9;
}

section.we-invite-section .link-btn {
    position: absolute;
    right: 0;
    bottom: 0;
}

section.we-invite-section a.theme-btn.btn-style-one {
    background-color: #dde3ea;
    color: #000000;
    font-weight: 700;
    border-radius: 0;
    padding: 20.5px 47px;
}

section.we-invite-section a.theme-btn.btn-style-one:hover {
    color: #fff;
}

section.we-invite-section .award {
    position: absolute;
    top: 0;
    left: -90px;
}

/* accomodation section */

section.accomodation-section {
    padding-top: 115px;
    position: relative;
    padding-bottom: 115px;
}

section.accomodation-section .auto-container {
    max-width: 925px;
    padding: 0;
}

section.accomodation-section .owl-carousel {
    position: inherit;
}

section.accomodation-section .owl-carousel .owl-stage-outer {
    overflow: visible;
    position: inherit;
}

section.accomodation-section .owl-item .content {
    opacity: 0;
    -webkit-transition: .9s;
	-o-transition: .9s;
	transition: .9s;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: perspective(1200px) rotateX(90deg) translateZ(-40px);
	transform: perspective(1200px) rotateX(90deg) translateZ(-40px);
    transition-delay: .5s;
}

section.accomodation-section .owl-item.active .content {
    opacity: 1;
    -webkit-transform: perspective(1200px) rotateX(0deg) translateZ(0px);
	transform: perspective(1200px) rotateX(0deg) translateZ(0px);
}

section.accomodation-section .content {
    margin-top: 50px;
}

section.accomodation-section .price {
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 1.2em;
    color: var(--theme-color);
    font-weight: 700;
    margin-bottom: 10px;
}

section.accomodation-section h3 {
    font-size: 32px;
    line-height: 1.2em;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display";
}

section.accomodation-section h3 a {
    color: #fff;
    transition: .5s;
}

section.accomodation-section h3 a:hover {
    color: var(--theme-color);
}

section.accomodation-section .info-list {
    font-size: 18px;
    line-height: 35px;
    color: #acb6c1;
    font-weight: 400;
    margin-bottom: 20px;
}

section.accomodation-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #acb6c1;
    font-weight: 400;
}
section.accomodation-section .info-list li {
    display: inline-block;
    margin-right: 25px;
    color: #acb6c1;
}

section.accomodation-section .info-list li i {
    margin-right: 10px;
    font-size: 30px;
    vertical-align: middle;
}

section.accomodation-section .image {
    position: relative;
    background-color: #0d1626;
    border-radius: 8px;
}

section.accomodation-section .image img {
    border-radius: 8px;
    opacity: .5;
}

section.accomodation-section  .owl-item.active .image img {
    opacity: 1;
}

.accomodation-section .owl-theme .owl-nav {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
    width: 100%;
    display: block;
}

.accomodation-section .owl-theme .owl-nav .owl-prev {
	position: absolute;
	left: 40px;
	top: 5px;
	background: rgb(255 255 255 / 0%);
	height: 70px;
	width: 70px;
	border-radius: 50%;
	border: none;
	text-align: center;
	color: rgba(0, 0, 0, 0);
	line-height: 70px;
	font-size: 0px;
	opacity: 1;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.accomodation-section .owl-theme .owl-nav .owl-prev:after {
	font-family: "Flaticon";
	content: "\f120";
	position: absolute;
	top: 0;
	width: 70px;
	height: 70px;
	line-height: 70px;
	left: 0;
	color: #ffffff;
	font-size: 45px;
	-webkit-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.accomodation-section .owl-theme .owl-nav .owl-prev:hover:after {
	left: 0;
	margin-left: 0;
}

.accomodation-section .owl-theme .owl-nav .owl-next {
	position: absolute;
	right: 40px;
	top: 5px;
	background: rgb(255 255 255 / 0%);
	height: 70px;
	width: 70px;
	border: none;
	text-align: center;
	line-height: 70px;
	color: rgba(0, 0, 0, 0);
	font-size: 0px;
	opacity: 1;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}

.accomodation-section .owl-theme .owl-nav .owl-next:after {
	font-family: "Flaticon";
	content: "\f120";
	position: absolute;
	top: 0;
	width: 70px;
	height: 70px;
	line-height: 70px;
	right: 0;
	color: #fff;
	font-size: 45px;
	-webkit-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	transform: rotate(180deg);
}

.accomodation-section .owl-theme .owl-nav .owl-next:hover:after {
	right: 0;
	margin-right: 0;
}

.accomodation-section .owl-theme .owl-nav .owl-prev:hover:after,
.accomodation-section .owl-theme .owl-nav .owl-next:hover:after {
    opacity: 1;
}

.accomodation-section .owl-theme .owl-nav .owl-prev:hover,
.accomodation-section .owl-theme .owl-nav .owl-next:hover {
    opacity: 1;
}

.accomodation-section.nav-style-two .owl-theme .owl-nav .owl-prev:after {
    background: #222;
    font-size: 20px;
    border-radius: 50%;
}

.accomodation-section.nav-style-two .owl-theme .owl-nav .owl-prev:hover:after {
    background-color: var(--theme-color);
}

.accomodation-section.nav-style-two .owl-theme .owl-nav .owl-next:after {
    background: #222;
    font-size: 20px;
    border-radius: 50%;
}

.accomodation-section.nav-style-two .owl-theme .owl-nav .owl-next:hover:after {
    background-color: var(--theme-color);
}


/* Banner section three */

section.banner-section-three .wrapper-box {
    position: relative;
    padding: 229px 0;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
}

section.banner-section-three {
    position: relative;
    margin: 60px 60px 0;
}

section.banner-section-three .text {
    font-size: 22px;
    line-height: 32px;
    color: #ffffff;
    position: relative;
    margin-bottom: 35px;
}

section.banner-section-three h1.banner-title {
    margin-bottom: 35px;
}

section.banner-section-three a.theme-btn {
    padding: 12px 30px;
}

/* welcome section three */

section.welcome-section-three .text {
    font-size: 31px;
    line-height: 60px;
    color: #3e79c5;
    font-weight: 400;
    font-style: italic;
}

section.welcome-section-three {
    position: relative;
    margin-top: 115px;
    margin-bottom: 115px;
}

section.welcome-section-three .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #a4a7ba;
    font-weight: 400;
    margin-bottom: 40px;
}

section.welcome-section-three .name {
    font-size: 22px;
    line-height: 1.2em;
    color: #7990aa;
    font-weight: 700;
    margin-bottom: 8px;
}

section.welcome-section-three .designation {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 22px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

section.welcome-section-three .author-info {
    display: flex;
    flex-wrap: wrap;
}

section.welcome-section-three .author-info .author-wrap {
    position: relative;
    padding-left: 90px;
    padding-top: 16px;
    margin-right: 40px;
}

section.welcome-section-three .author-info .author-thumb {
    position: absolute;
    left: 0px;
    top: 0;
}

section.welcome-section-three .shape {
    position: absolute;
    top: 100px;
    z-index: -1;
}

/* welcome section four */


/* our menu section */

section.our-menu-section {
    position: relative;
    padding-top: 115px;
    padding-bottom: 90px;
}

section.our-menu-section .image-box {
    position: relative;
    min-height: 106px;
    margin-bottom: 30px;
}

section.our-menu-section .image {
    position: absolute;
    top: 0;
    left: 0;
}

section.our-menu-section ul {
    flex-wrap: wrap;
    padding-left: 142px;
    display: flex;
    font-size: 16px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
}

section.our-menu-section .text {
    padding-left: 142px;
    margin-bottom: 5px;
    padding-top: 20px;
    font-size: 18px;
    line-height: 28px;
    color: #242424;
    font-weight: 700;
    font-family: "Playfair Display";
}

section.our-menu-section h5 {
    font-size: 24px;
    line-height: 38px;
    color: #0e2a4e;
    font-family: GlacialIndifference-Bold;
}

section.our-menu-section .time {
    font-size: 14px;
    letter-spacing: 3px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    margin-bottom: 45px;
}


/* faclilites section */

section.faclilites-section {
    position: relative;
    padding-bottom: 120px;
}

.faclilites-section .auto-container {
    max-width: 100%;
    padding: 0;
    overflow: hidden;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.faclilites-block {
    position: relative;
}

.faclilites-block .image {
    overflow: hidden;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.faclilites-block .image:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: 25%;
    z-index: 1;
    border-right: 1px solid #ffffff24;
    background: rgb(255 255 255 / 8%);
}

.faclilites-block .image:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    background: rgb(255 255 255 / 4%);
    width: 33%;
    border-left: 1px solid rgb(255 255 255 / 12%);
}

.faclilites-block .image img {
    transition: .5s;
}

.faclilites-block:hover .image img {
    transform: scale(1.1);
}

section.faclilites-section .text {
    font-size: 40px;
    line-height: 50px;
    color: #ffffff;
    font-weight: 400;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
}

.faclilites-block-with-text {
    background: var(--theme-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.faclilites-block-with-text .text {
    position: relative;
}

.faclilites-block-with-text .text-two {
    position: relative;
    margin-bottom: 30px;
    font-size: 25px;
    line-height: 35px;
    color: #ffffff;
}

.faclilites-block-with-text .inner-box {
    text-align: center;    
    padding: 50px 30px;
}

.faclilites-block-with-text h4 {
    position: relative;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 47px;
    color: #ffffff;
    font-family: GlacialIndifference-Bold;
}

.faclilites-block-with-text .btn-style-two:before {
    background: #0b2848;
}

.faclilites-block-with-text .btn-style-two:hover {
    color: #fff;
}


/* welcome four section  */

section.welcome-section-four {
    position: relative;
}

section.welcome-section-four .inner-box {
    position: relative;
    margin-right: 45px;
    display: flex;
    flex-wrap: wrap;
}

section.welcome-section-four .image {
    background-size: cover;
    width: calc(100% - 320px);
    border-top-left-radius: 8px;
}

section.welcome-section-four .image img {
    display: none;
}

section.welcome-section-four .content {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 320px;
    background-color: #283862;
    padding: 140px 45px 90px;
}

section.welcome-section-four .auto-container {
    padding: 0;
    max-width: 100%;
}

section.welcome-section-four .text {
    font-size: 28px;
    line-height: 42px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 70px;
}

section.welcome-section-four .text-two {
    font-size: 18px;
    line-height: 28px;
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
    margin-bottom: 65px;
}

section.welcome-section-four .welcome-section-five {
    padding-left: 318px;
}

section.welcome-section-four .text-three {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 20px;
}

section.welcome-section-four .text-four {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 60px;
}

section.welcome-section-four .right-column {
    max-width: 630px;
    padding-left: 45px;
}

section.welcome-section-four .signature {
    margin-bottom: 114px;
}

.border-bottom-five {
    position: relative;
    height: 6px;
}

.border-bottom-five:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50px;
    right: 0;
    height: 1px;
    background: #cccccc;
}

.border-bottom-five:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50px;
    right: 0;
    height: 1px;
    background: #cccccc;
}

.border-bottom-five:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 50px;
    left: 0;
    height: 1px;
    background: #cccccc;
}


/* welcome section six */

section.welcome-section-six {
    padding-top: 115px;
    padding-bottom: 0px;
    position: relative;
}

section.welcome-section-six .text {
    font-size: 31px;
    line-height: 60px;
    color: #0c141f;
    font-weight: 400;
    font-style: italic;
}

section.welcome-section-six .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-family: GlacialIndifference-Regular;
    text-align: justify;
    font-weight: 400;
    margin-bottom: 40px;
}

section.welcome-section-six .text-three {
    font-size: 18px;
    line-height: 28px;
    font-family: GlacialIndifference-Regular;
    color: #797979;
    font-weight: 400;
    margin-bottom: 55px;
}


section.welcome-section-six .image {
    text-align: right;
    position: relative;
    display: inline-block;
}

section.welcome-section-six .image img {
    border-radius: 8px;
}

section.welcome-section-six .image:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    background: #8a8a8a;
    opacity: .25;
    width: 100px;
    border-right: 1px solid #fff;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

section.welcome-section-six .image:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    background: #8a8a8a;
    opacity: .25;
    width: 100px;
    border-left: 1px solid #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}


section.welcome-section-six .author-info {
    display: flex;
    flex-wrap: wrap;
}

section.welcome-section-six .author-wrap {
    position: relative;
    padding-left: 100px;
    padding-top: 4px;
    margin-right: 40px;
}

section.welcome-section-six .author-thumb {
    position: absolute;
    left: 0;
    top: 0;
}

section.welcome-section-six .name {
    font-size: 22px;
    line-height: 1.6em;
    color: #0e2a4e;
    font-family: 'GlacialIndifference-Bold';
    margin-bottom: 3px;
}

section.welcome-section-six .designation {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 22px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
}



/* Meet Our Team */

.team-section {
    position: relative;
    padding: 100px 0 70px;
    z-index: 1;
} 

.team-block-one {
    position: relative;
}

.team-block-one .inner-box {
    position: relative;
    margin-bottom: 30px;
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
}

.team-block-one .image {
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
}

.team-block-one .image img {
    width: 100%;
}

.team-block-one h4 {
    position: relative;
    display: inline-block;
    font-size: 22px;
    line-height: 22px;
    color: #0c1b28;
    font-family: 'GlacialIndifference-Bold';
}

.team-block-one h4:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 41px;
    right: -60px;
    bottom: 8px;
}

.team-block-one .designation {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 22px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
}

.team-block-one .overlay-box{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height:100%;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    overflow: hidden;
    background-color:rgba(0,0,0,0.50);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.team-block-one .inner-box:hover .overlay-box{
    opacity: 1;
    visibility: visible;
}

.team-block-one .social-links{
    position: absolute;
    left:0;
    top:50%;
    width:100%;
    margin-top:-25px;
    padding:0px 10px;
    text-align:center;
    opacity:0;
    visibility:hidden;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
}

.team-block-one .inner-box:hover .social-links{
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 300ms ease 100ms;
    -moz-transition: all 300ms ease 100ms;
    -ms-transition: all 300ms ease 100ms;
    -o-transition: all 300ms ease 100ms;
    transition: all 300ms ease 100ms;
}

.team-block-one .social-links li{
    position: relative;
    display: inline-block;
    margin: 0 2px;
}

.team-block-one .social-links li a{
    position: relative;
    display: block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    border-radius:50%;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.team-block-one .social-links li a:hover{
    color: #ffffff;
    background-color: var(--theme-color);
}

.team-block-one .content {
    position: relative;
    background-color: #fff;
    margin-top: -45px;
    margin-right: 23px;
    padding: 20px 40px;
}


/* amenities section three */


section.amenities-section-three {
    padding-top: 115px;
    position: relative;
    padding-bottom: 95px;
}

section.amenities-section-three h1 {
    color: #ffff;
}

section.amenities-section-three .icon {
    color: #d53b3b;
}




/* team two section */

section.team-section-two .top-content {
    display: flex;
    flex-wrap: wrap;
    animation: initial;
    justify-content: space-between;
}

section.team-section-two {
    position: relative;
    padding-bottom: 115px;
    padding-top: 130px;
}

section.team-section-two .text {
    color: #8a8b96;
    margin-top: 50px;
}


section.team-section-two .content {
    position: relative;
    background-color: #0f1825;
}

section.team-section-two h4 {
    color: #fff;
}

/* Testimonials section three */

section.testimonials-section-three {
    padding: 110px 0 80px;
}

section.testimonials-section-three .title-box .text {
    font-size: 18px;
}

.testimonial-block.style-two {
    background: #f5f6f7;
    padding: 50px 55px;
    border-radius: 8px;
}

.testimonial-block.style-two .text {
    border-color: #e1e2e2;
}

/* Accomodation style two */

section.accomodation-section.style-two {
    overflow: hidden;
    background: #fff;
}

section.accomodation-section.style-two h3 {
    color: #0e2a4e;
}

section.accomodation-section.style-two h3 a {
    color: #0e2a4e;
    transition: .5s;
}

section.accomodation-section.style-two h3 a:hover {
    color: var(--theme-color);
}

section.accomodation-section.style-two .info-list li {
    color: #797979;
}

section.accomodation-section.style-two .text {
    color: #797979;
}


/* check availability */

.check-availability {
    position: relative;
    z-index: 99;
}

.check-availability form.form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    background: #fff;
    filter: drop-shadow(0px 13px 35px rgba(0,0,0,0.08));
    max-width: 885px;
    margin: 0 auto;
    margin-bottom: -60px;
    margin-top: -60px;
    position: relative;
    border-radius: 8px;
}

.check-availability ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.check-availability .left-side {
    position: relative;
    padding-left: 58px;
    padding-top: 25px;
    padding-bottom: 20px;
    width: calc(100% - 220px);
}

.check-availability p {
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 32px;
    color: #575757;
    font-weight: 600;
    font-family: "Poppins";
    margin: 0;
}

.check-availability input {
    font-size: 16px;
    line-height: 32px;
    color: #000000;
}

.check-availability .nice-select {
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    border: 0;
    padding: 0;
    padding-right: 30px;
}

.check-availability .nice-select:after {
    border-color: #797979;
    border-width: 1px;
    height: 13px;
    width: 13px;
    top: 11px;
    right: 0;
}

.check-availability button {
    font-size: 15px;
    letter-spacing: 2px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Roboto";
    text-align: center;
    background: var(--theme-color);
    padding: 32px 49.5px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-transform: uppercase;
    position: relative;
    right: -1px;
    transition: .5s;
}

.check-availability button:hover {
    background-color: #222;
}

.check-availability .left-side ul li {
    min-width: 90px;
}

/* about section two */

section.about-section-two .auto-container {
    max-width: 1500px;
}
@media (max-width:700px){
  section.about-section-two {
    position: relative;
    padding-top: 0px;
    padding-bottom: 115px;
}
}
@media (min-width:700px){
  section.about-section-two {
    position: relative;
    padding-top: 115px;
    padding-bottom: 115px;
}
}

@media (max-width:700px){
  section.about-section-two .text {
    font-size: 18px;
    text-align: justify;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 110px;
}

}
@media (min-width:700px){
  section.about-section-two .text {
    font-size: 18px;
    padding-right: 216px;
    text-align: justify;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 110px;
}

}
@media (max-width:700px){
  section.about-section-two .title-text {
    margin-bottom: 50px;
}
}
@media (min-width:700px){
  section.about-section-two .title-text {
    margin-bottom: 110px;
}
}

section.about-section-two .text-two {
    font-size: 18px;
    text-align: justify;
    line-height: 32px;
    color: #ffffff;
    font-weight: 400;
    margin-top: 70px;
    margin-bottom: 40px;
}

@media (max-width:700px){
  section.about-section-two .image {
    margin-right: 150px;
    width: 100%
}
}
@media (min-width:700px){
  section.about-section-two .image {
    margin-right: 150px;
}
}

section.about-section-two .view-all-btn {
    color: var(--theme-color);
}

section.about-section-two .content {
    margin-left: 30px;
}


/* aminities service section */

section.aminities-section-four {
    padding-top: 120px;
    padding-bottom: 120px;
}
section.aminities-section-four .inner-box {
    position: relative;
    margin-bottom: 30px;
}

section.aminities-section-four h5 {
    font-size: 24px;
    line-height: 1.4em;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    position: absolute;
    left: 37px;
    bottom: 40px;
    z-index: 9;
}

section.aminities-section-four .text {
    font-size: 18px;
    line-height: 30px;
    color: #9499a3;
    font-weight: 400;
    margin-bottom: 40px;
}

.aminities-section-four .image {
    position: relative;
    overflow: hidden;
}

.aminities-section-four .image:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    background: #8a8a8a;
    opacity: .25;
    width: 50px;
    border-right: 1px solid #fff;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: 1;
}

.aminities-section-four .image:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    background: #8a8a8a;
    opacity: .25;
    width: 50px;
    border-left: 1px solid #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1;
}

.aminities-section-four .image img {
    width: 100%;
    transition: .5s;
}

.aminities-section-four .image:hover img {
    transform: scale(1.1);
}

/* accomodation section style two */

section.accomodation-section.style-two .content-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

section.accomodation-section .content-box h3 {
    margin-right: 30px;
}

/* our story section */

section.our-story-section {
    position: relative;
    padding-top: 115px;
    padding-bottom: 55px;
}

section.our-story-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #969faf;
    font-weight: 400;
}

section.our-story-section .text-two {
    font-size: 28px;
    letter-spacing: 2px;
    line-height: 60px;
    color: #ffffff;
    font-weight: 400;
    padding-left: 135px;
    margin-top: 20px;
    position: relative; 
}

section.our-story-section .text-two:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 105px;
    background: #fff;
    left: 0;
    bottom: 20px;
}

section.our-story-section .column {
    margin-bottom: 60px;
    padding: 0 30px;
}

section.our-story-section .column .image {
    position: relative;
    overflow: hidden;
}

section.our-story-section .column .image img {
    position: relative;
    transition: .5s;
}

section.our-story-section .column .image:hover img {
    transform: scale(1.1);
}

section.our-story-section .outer-box {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
}

section.our-story-section .title-column {
    position: relative;
    margin-bottom: 60px;
    padding: 0 30px;
}

section.our-story-section .title-column h2.sec-title.text-light {
    font-size: 40px;
}

section.our-story-section .auto-container {
    max-width: 1420px;
}

/* welcome section five */

section.welcome-section-five {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
}

section.welcome-section-five .text {
    font-size: 18px;
    line-height: 30px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 60px;
}

section.welcome-section-five .image {
    text-align: right;
    position: relative;
    display: inline-block;
}

section.welcome-section-five .image img {
    border-radius: 8px;
}

section.welcome-section-five .image:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    background: #8a8a8a;
    opacity: .25;
    width: 100px;
    border-right: 1px solid #fff;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

section.welcome-section-five .image:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    background: #8a8a8a;
    opacity: .25;
    width: 100px;
    border-left: 1px solid #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

section.welcome-section-five .text-three {
    font-size: 18px;
    line-height: 30px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 45px;
}

section.welcome-section-five blockquote {
    position: relative;
    margin-bottom: 60px;
    padding-left: 40px;
    border-left: 2px solid #0e2a4e;
}

section.welcome-section-five blockquote .text-two {
    position: relative;
    font-size: 22px;
    line-height: 30px;
    color: #0e2a4e;
    margin-bottom: 30px;
}

section.welcome-section-five blockquote cite {
    font-size: 22px;
    line-height: 30px;
    color: var(--theme-color);
    font-style: normal;
}

section.welcome-section-five blockquote cite span {
    font-size: 18px;
    line-height: 30px;
    color: #888888;
}

section.welcome-section-five .author-info {
    display: flex;
    flex-wrap: wrap;
}

section.welcome-section-five .author-wrap {
    position: relative;
    padding-left: 100px;
    padding-top: 16px;
    margin-right: 40px;
}

section.welcome-section-five .author-thumb {
    position: absolute;
    left: 0;
    top: 0;
}

section.welcome-section-five .name {
    font-size: 22px;
    line-height: 1.2em;
    color: #0e2a4e;
    font-weight: 700;
}

section.welcome-section-five .designation {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 22px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
}


/* about page */

.page-title{
    position:relative;
    padding: 300px 0 221px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:right center;
}

/* .page-title h1 {
    font-size: 85px;
    letter-spacing: -2px;
    line-height: 96px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display";
    text-align: center;
} */
@media (min-width: 700px) {
  .page-title h1 {
    font-size: 85px;
    letter-spacing: -2px;
    line-height: 96px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display";
    text-align: center;
}
}
@media (max-width: 700px) {
  .page-title h1 {
    font-size: 43px;
    letter-spacing: -2px;
    line-height: 96px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display";
    text-align: center;
}
}

/* gallery section four */

section.gallery-section-four {
    position: relative;
    padding-top: 115px;
    padding-bottom: 115px;
}

/* restaurant section */

section.restaurant-section {
    position: relative;
    padding-top: 115px;
    padding-bottom: 115px;
}

section.restaurant-section .text {
    font-size: 24px;
    line-height: 28px;
    color: #0e2a4e;
    font-weight: 400;
    margin-top: 15px;
}

section.restaurant-section .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 40px;
}

section.restaurant-section .date {
    font-size: 20px;
    line-height: 35px;
    color: #0e2a4e;
    font-weight: 400;
    border-right: 1px solid #e1e1e1;
}

section.restaurant-section .time {
    margin-left: 60px;
    font-size: 20px;
    line-height: 35px;
    color: #0e2a4e;
    font-weight: 400;
}

section.restaurant-section .inner-box {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 40px;
}

section.restaurant-section .content {
    font-size: 20px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
    margin-top: 47px;
}

section.restaurant-section .link-btn {
    margin-top: 40px;
}

/* our specilities section */

section.our-specilities-section {
    position: relative;
    padding-top: 115px;
    padding-bottom: 90px;
}

section.our-specilities-section h5 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 21px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 20px;
    margin-top: 30px;
}

section.our-specilities-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
}


/* room section three */

section.room-section-three {
    position: relative;
    background: #eae7e7;
}

section.room-section-three .auto-container {
    max-width: 100%;
    padding: 0;
}

section.room-section-three .image {
    background-size: cover;
}

section.room-section-three .image img {width: 100%;}

section.room-section-three h4 {
    font-size: 32px;
    line-height: 1.4em;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 10px;
}

section.room-section-three .count {
    font-size: 150px;
    line-height: 1em;
    color: rgb(255 255 255 / 50%);
    font-weight: 700;
    font-family: "Playfair Display";
    position: absolute;
    left: 35px;
    top: -28px;
}

section.room-section-three .info-list {
    font-size: 18px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
}

section.room-section-three li.nav-item {
    width: 100%;
    border: none;
}

section.room-section-three .nav-link {
    background: transparent;
    width: 100%;
    text-align: left;
}

section.room-section-three li.nav-item button {
    position: relative;
    padding: 28px 0;
    padding-left: 160px;
    border: none;
    border-bottom: 1px solid #d2c8c8 !important;
    margin-bottom: 0px;
}

section.room-section-three li.nav-item button:hover,
section.room-section-three li.nav-item button:focus {
    border-bottom: 1px solid #d2c8c8 !important;
}

section.room-section-three li.nav-item button.active {
    background: #f0eeee;
    border-bottom-color: #d2c8c8;
}


section.room-section-three .nav-tabs {
    max-width: 690px;
    border: 1px solid #d2c8c8;
    border-bottom: 0;
}

section.room-section-three .outer-box {
    background-color: #eae7e7;
    padding-top: 143px;
    padding-bottom: 143px;
}

section.room-section-three .outer-box .nav-tab-wrapper {
    position: relative;
    max-width: 690px;
}

section.room-section-three .curve-text {
    text-transform: uppercase;
    position: absolute;
    left: 100%;
    bottom: 0;
    transform: rotate(
-90deg) translateX(0) translateY(0);
    transform-origin: bottom left;
    width: max-content;
    margin-left: 57px;
    margin-bottom: 0;
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 47px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
}

section.room-section-three .curve-text:before {
    position: absolute;
    content: '';
    top: 27px;
    right: -55px;
    width: 40px;
    height: 1px;
    background: var(--theme-color);
}

section.room-section-three .info-list {
    font-size: 18px;
    line-height: 35px;
    font-weight: 400;
}

section.room-section-three .text {
    font-size: 18px;
    line-height: 28px;
    color: #acb6c1;
    font-weight: 400;
}
section.room-section-three .info-list li {
    display: inline-block;
    margin-right: 25px;
    color: #797979;
}

section.room-section-three .info-list li i {
    margin-right: 10px;
    font-size: 30px;
    vertical-align: middle;
    color: #222;
}

/* activities section */

section.activities-section {
    position: relative;
    padding-top: 115px;
}

section.activities-section .lower-contant {
    padding: 55px 50px;
    margin-left: 65px;
    background-color: #f1f3f6;
    margin-top: -235px;
    margin-right: 0;
    position: relative;
}

section.activities-section .text {
    position: relative;
    font-size: 18px;
    margin-bottom: 30px;
}

section.activities-section .text-two {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 1.2em;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

section.activities-section h5 {
    font-size: 28px;
    color: #0e2a4e;
    font-weight: 800;
    font-family: "Playfair Display";
    margin-bottom: 25px;
    margin-top: 8px;
}

section.activities-section .text-three {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
}

section.activities-section .inner-box {
    padding-bottom: 120px;
    max-width: 456px;
}

@media only screen and (min-width:992px) {
    
    section.activities-section .col-lg-6:nth-child(even) .inner-box {
        margin: 0 auto;
    }
    
}





/*** 

====================================================================
    Accordion Style
====================================================================

***/

.accordion-box{
    position:relative;
}

.accordion-box .block{
    position: relative;
    padding: 8px 0px;
    margin-bottom:10px;
    border:1px solid #dedede;
    background-color:#ffffff;
}

.accordion-box .block .acc-btn{
    position:relative;
    font-size:18px;
    cursor:pointer;
    line-height:30px;
    color: #001659;
    font-weight: 500;
    padding:0px 10px 0px 64px;
    transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
}

.accordion-box .block .icon-outer{
    position:absolute;
    left:14px;
    top:0px;
    font-size: 18px;
    color: #242424;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.accordion-box .block .icon-outer .icon{
    position: absolute;
    width: 28px;
    height: 28px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
}

.accordion-box .block .icon-outer .icon-plus{
    font-size: 15px;
    line-height: 28px;
    color: #00091f;
    font-weight: 700;
    left: 0;
}

.accordion-box .block .icon-outer .icon-minus{
    opacity:0;
    font-weight:300;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-plus{
    opacity: 0;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-minus{
    opacity: 1;
}

.accordion-box .block .acc-content{
    position:relative;
    display:none;
}

.accordion-box .block .acc-content .content-text{
    padding-bottom:6px;
}

.accordion-box .block .acc-content.current{
    display:block;  
}

.accordion-box .block .content{
    position:relative;
    font-size:14px;
    padding: 10px 30px 0px 30px;
}

.accordion-box .block .content .text{
    margin-bottom:20px;
    line-height:1.8em;
}

.accordion-box .block .content p:last-child{
    margin-bottom:0px;  
}

/*Accordian Faq Form*/

.accordion-box.faq .block{
    padding:0px;
    border:0px;
    margin-bottom:40px;
}

.accordion-box.faq .block .acc-btn{
    padding-left:30px;
    font-weight:600;
    font-size:20px;
    font-family: 'Poppins', sans-serif;
}

.accordion-box.faq .block .icon-outer{
    left:-10px;
    color:#242424;
}

.accordion-box.faq .block .acc-btn.active .icon-outer .icon-minus{
    background:none;
    color:#242424;
}

.accordion-box.faq .block .content{
    padding:14px 30px 0px 30px;
}

.accordion-box.faq .block .content .text{
    font-size:17px;
    line-height:1.6em;
}

.accordion-box.style-three .block{
    margin-bottom: 25px;
    border: transparent;
    background: transparent;
}

.accordion-box.style-three .accordion.active-block{
    background-color: transparent;
}

.accordion-box.style-three .block .icon-outer{
    left: -6px;
    color:#242424;
}

.accordion-box.style-three .block .acc-btn.active .icon-outer .icon-minus{
    background:none;
    line-height: 28px;
    color: #00091f;
    font-weight: 700;
}

.accordion-box.style-three .block .content{
    padding-left: 30px;
}

.accordion-box.style-three .block .acc-btn{font-size: 22px;color: #0e2a4e;font-family: GlacialIndifference-Bold;padding-left: 30px;}

.accordion-box.style-three .block .content .text{
    margin-bottom:5px;
    margin-top: 7px;
}


/* Faq Section */

.faq-section {
    position: relative;
    padding: 115px 0 80px;
}

.faq-section .sear-form {
    position: relative;
    margin-bottom: 30px;
}

.faq-section .sear-form input[type="search"] {
    position: relative;
    width: 100%;
    height: 60px;
    border: 1px solid #e0e0e0;
    padding: 0 25px;
    border-radius: 3px;
    color: rgb(0 0 0 / 0.5);
}

.faq-section .sear-form button {
    position: absolute;
    background: transparent;
    right: 21px;
    top: 16px;
    color: rgb(0 0 0 / 0.50);
}


.faq-form-section {
    position: relative;
    padding: 100px 0 85px;
    background-size: cover;
}

.faq-form-section h4 {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 40px;
}

.faq-form .form-group {
    position: relative;
    margin-bottom: 15px;
}

.faq-form input, .faq-form textarea {
    position: relative;
    width: 100%;
    background: transparent;
    color: #fff;
    font-size: 18px;
    border-bottom: 2px solid #f78684;
    height: 60px;
}

.faq-form textarea {
    height: 182px;
}

.faq-form button {
    position: relative;
    background: #fff;
    padding: 18px 35px;
    font-size: 16px;
    font-weight: 700;
    color: #001659;
    text-transform: uppercase;
    transition: .5s;
}

.faq-form button i {
    margin-left: 20px;
}

.faq-form button:hover {
    background: #081336;
    color: #fff;
}

.faq-section h5 {
    font-size: 35px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
}

.faq-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-top: 25px;
}

.faq-section .faq-title {
    font-size: 34px;
    line-height: 36px;
    color: #0e2a4e;
    font-weight: 800;
    font-family: "Playfair Display";
}

.faq-section span.shape {
    width: 40px;
    height: 2px;
    background-color: #0e2a4e;
    display: inline-block;
}

.faq-section .accordian-boxed {
    margin-top: 60px;
}

.faq-section .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 32px;
}

.faq-section .text-three {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
}


/* resort section */

section.resort-section {
    padding-top: 115px;
    padding-bottom: 115px;
    position: relative;
}

section.resort-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-top: 50px;
}

section.resort-section .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-top: 40px;
}

section.resort-section .image {
    padding-left: 60px;
}

/* holetic section */

section.holetic-section {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
}

section.holetic-section .image-two {
    margin-top: 30px;
}

section.holetic-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    padding-right: 40px;
}


/* featured section */

section.featured-section {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
}

section.featured-section .bg {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 600px;
    background-color: #0e1720;
    /* background-image: url("../Images/bg-2.jpg") !important; */
    z-index: 0;
}

section.featured-section h4 {
    font-size: 28px;
    line-height: 34px;
    color: #242424;
    font-family: GlacialIndifference-Bold;
    margin-bottom: 10px;
}

section.featured-section .text-two {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6em;
    color: #898989;
    margin-bottom: 20px;
}

section.featured-section .pricing {
    font-size: 28px;
    line-height: 28px;
    color: #242424;
    font-family: 'GlacialIndifference-Bold';
}

section.featured-section span {
    font-size: 22px;
    line-height: 28px;
    color: #969595;
    font-family: 'GlacialIndifference-Regular';
}

section.featured-section .text {
    margin-bottom: 70px;
    position: relative;
}


/* testimonials section two */
section.testimonials-section-two .testimonials-block {
    background: #f5f6f7;
}

section.testimonials-section-two .testimonials-block {
    background: #f5f6f7;
    padding: 50px 55px;
    border-radius: 8px;
}

section.testimonials-section-two .testimonials-block {
    background: #f5f6f7;
    padding: 50px 55px;
    border-radius: 8px;
    margin-bottom: 30px;
}

section.testimonials-section-two .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    border-bottom: 1px solid #e1e2e2;
    padding-bottom: 30px;
    margin-bottom: 20px;
}

/* room grid section */

section.room-list-section {
    padding-top: 120px;
    padding-bottom: 0px;
    position: relative;
}

section.room-list-section .pricing {
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 35px;
    color: var(--theme-color);
    font-weight: 400;
}


section.room-list-section .text {
    font-size: 20px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 115px;
}

section.room-list-section h3 {
    font-size: 32px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 10px;
}

section.room-list-section h3 a {
    color: #0e2a4e;
    transition: .5s;
}

section.room-list-section h3 a:hover {
    color: var(--theme-color);
}

section.room-list-section .icon-list {
    font-size: 18px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
    display: flex;
}

section.room-list-section .icon-list ul {
    display: flex;
    font-family: var(---font2);
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-left: 0px;
}

section.room-list-section .icon-list ul li {
    margin-right: 30px;
}

section.room-list-section .icon-list ul li i {
    margin-right: 10px;
    position: relative;
    font-size: 24px;
    vertical-align: middle;
}
section.room-list-section .text-two {
    font-size: 18px;
    line-height: 28px;
    font-family: var(---font2);
    color: #797979;
    font-weight: 400;
    margin-bottom: 35px;
}

section.room-list-section .image {
    margin-bottom: 30px;
}

section.room-list-section .link-btn {
    margin-bottom: 30px;
}

.room-block-three {
    margin-bottom: 90px;
}

/* room grid section */

section.room-grid-section {
    padding-top: 120px;
    padding-bottom: 120px;
}
section.room-grid-section .room-block-two{
    margin-bottom: 95px;
}

section.room-grid-section .title-box {
    margin-bottom: 95px;
}

section.room-grid-section .title-box .text {
    font-size: 20px;
    line-height: 35px;
    color: #797979;
}

/* promotions offers section */

section.promotions-offers-section {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}

section.promotions-offers-section .text-box {
    position: absolute;
    bottom: -20px;
    left: 0;
    background: #fff;
    right: 40px;
    padding: 30px;
    padding-bottom: 10px;
}

section.promotions-offers-section .image-box {
    position: relative;
}

section.promotions-offers-section .text-box h3 {
    font-size: 28px;
    line-height: 22px;
    color: #0e2a4e;
    font-weight: 700;
    margin-bottom: 10px;
}

section.promotions-offers-section .text-four {
    font-size: 18px;
    line-height: 20px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 20px;
}

section.promotions-offers-section .text-box .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 35px;
}

section.promotions-offers-section .text-three {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
}

/* Header style three */
header.main-header.header-style-three {
    top: 80px;
    background: transparent;
}

header.main-header.header-style-three .auto-container {
    max-width: 100%;
    padding: 0 110px;
}

.main-header.header-style-three .header-upper .inner-container {
    padding: 0;
    background: transparent;
}

.header-style-three .main-menu .navigation>li>a {
    color: #fff;
}

.main-header.header-style-three .header-upper .search-toggler {
    color: #fff;
}

header.main-header.header-style-three .sticky-header {
    background: #0b2848;
}

/* Check availability style two */
.check-availability.style-two {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 1200px;
    background: #fff;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}

.check-availability.style-two form.form {
    margin-bottom: 0;
    margin-right: 0;
    filter: none;
    margin-top: 0;
}

.check-availability.style-two .left-side {
    padding-top: 19px;
    padding-bottom: 14px;
}

.check-availability.style-two button {
    border-bottom-right-radius: 0;
    padding: 26px 49.5px;
}


/* Header style Four */

.main-header.header-style-four .auto-container {
    max-width: 100%;
    padding: 0 60px;
}

header.main-header.header-style-four {
    top: 0;
}
p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

.main-header.header-style-four .header-upper .inner-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.header-phone-number {
    position: relative;
    margin-right: 30px;
}

.header-phone-number a {
    font-size: 17px;
    line-height: 55px;
    color: #0e1721;
    font-weight: 600;
    font-family: "Poppins";
}

.header-phone-number i {
    margin-right: 5px;
    font-size: 24px;
    vertical-align: middle;
    position: relative;
    display: inline-block;
    line-height: 24px;
}

/* Header style five */

.main-header.header-style-five .auto-container {
    max-width: 100%;
    padding: 0 60px;
}

header.main-header.header-style-five {
    top: 20px;
}

.main-header.header-style-five .header-upper .inner-container {
    background: transparent;
    padding: 0;
}

.main-header.header-style-five .main-menu .navigation>li>a {
    color: #fff;
}

.main-header.header-style-five .header-phone-number a {
    color: #fff;
}

header.main-header.header-style-five .sticky-header {
    background: #0b2848;
}

/* check avalability style three */
.check-availability.style-three {
    position: absolute;
    left: 0;
    bottom: 55px;
    right: 0;
}

.check-availability.style-three form.form {
    margin-bottom: 0;
    margin-left: 0;
}

/* Banner style two */
.banner-section.style-two .content-box {
    min-height: 915px;
    padding-bottom: 130px;
}

.btn-style-four {
    font-size: 22px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 400;
    position: relative;
    transition: .5s;
}

.btn-style-four:hover {
    color: var(--theme-color);
}

.btn-style-four i {
    margin-left: 10px;
    position: relative;
    top: 3px;
}

.btn-style-four:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 4px;
    height: 1px;
    background: #fff;
    right: 35px;
}

.banner-section .banner-slider-nav.style-one {
    left: 60px;
    right: 60px;
}

/* Banner section three style two */
section.banner-section-three.style-two {
    margin: 0;
}

section.banner-section-three.style-two .wrapper-box {
    padding-top: 360px;
    padding-bottom: 465px;
    background-position: bottom center;
}

/* banner section style three */

.banner-section.style-three .content-box {
    min-height: 925px;
    padding-bottom: 50px;
}

/* Banner style four */

.banner-section.style-four .content-box {
    min-height: 1165px;
    padding: 100px 0 320px;
}

/* check availability style four  */
.check-availability.style-four {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 1540px;
    margin: 0 auto;
    margin-right: 0;
    background-color: #0e1720;
    /* background-image: url("../Images/bg-2.jpg") !important; */
    padding: 60px 70px;
}

.check-availability.style-four form.form {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    max-width: none;
    background: transparent;
}

.check-availability.style-four h4 {
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 35px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding-right: 110px;
    margin-bottom: 30px;
}

.check-availability.style-four h4:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 8px;
    width: 88px;
    height: 1px;
    background: #6e757b;
}

.check-availability.style-four .left-side {
    padding: 0;
}

.check-availability.style-four .list-two {
    justify-content: space-between;
}

.check-availability.style-four .list-two>li {
    height: 81px;
    border-radius: 5px;
    background-color: rgba(2,6,17,0.23137254901960785);
    border: 1px solid rgba(255,255,255,0.1);
    padding: 0 20px;
    margin-right: 10px;
    min-width: 140px;
}

.check-availability.style-four input {
    background: transparent;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
}

.check-availability.style-four p {
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 24px;
    color: rgb(255 255 255 / 56%);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
    margin-top: 14px;
}

.check-availability.style-four .nice-select {
    width: 100%;
    background: transparent;
    color: #fff;
}

.check-availability.style-four .nice-select:after {
    border-color: #fff;
}

.check-availability.style-four li.date {width: 100%;max-width: 350px;}

.nice-select .list {
    display: block;
    width: 100%;
    min-width: 80px;
}

.nice-select .list li.option {
    color: #222;
}

.check-availability.style-four button {
    border-radius: 8px;
    padding: 26.5px 49.5px;
}


/* Banner style five */
.banner-section.style-five .content-box {
    min-height: 927px;
    padding-bottom: 200px;
}


/* check avalability style five */
.check-availability.style-five {
    position: absolute;
    left: 0;
    bottom: 90px;
    right: 0;
}

.check-availability.style-five form.form {
    margin-bottom: 0;
    margin-left: 0;
}

/* Header style six */

header.main-header.header-style-six {
    top: 70px;
}

header.main-header.header-style-six .auto-container {
    max-width: 100%;
    padding: 0 60px;
}

.main-header.header-style-six .header-upper .inner-container {
    background: transparent;
    padding-left: 85px;
}

header.main-header.header-style-six .mobile-nav-toggler.style-two {
    position: absolute;
    left: 0;
    top: 22px;
    cursor: pointer;
}

.header-style-six .header-phone-number a {
    color: #fff;
}

.header-style-six .sticky-header {
    background: #0b2848;
}

.mobile-menu.style-two {
    right: auto;
    left: 0;
    width: 100%;
    max-width: 480px;
    transition: .5s;
}

.mobile-menu.style-two .menu-box {
    background: #dfe4e9;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 0;
    padding-left: 120px;
    -webkit-transform: translateX(-101%);
    -ms-transform: translateX(-101%);
    transform: translateX(-101%);
    transition: .5s;
}

.mobile-menu-visible .mobile-menu.style-two .menu-box {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.mobile-menu.style-two .menu-box .menu-outer {
    width: 100%;
}

.mobile-menu.style-two .navigation li>a {
    font-size: 30px;
    color: #242424;
    font-weight: 700;
    font-family: "Playfair Display";
    line-height: 1.1em;
    text-transform: capitalize;
}

.mobile-menu.style-two .navigation li>a:hover {
    color: var(--theme-color);
    text-decoration: underline !important;
}

.mobile-menu.style-two .navigation li>ul>li>a {
    font-size: 24px;
}

.mobile-menu.style-two .navigation li.dropdown .dropdown-btn {
    color: #222;
}

.mobile-menu.style-two .navigation li {
    border: none;
}

.mobile-menu.style-two .close-btn {
    color: #222;
    font-size: 30px;
}

.mobile-menu.style-two .navigation li.dropdown .dropdown-btn {
    right: 25px;
}

/* Banner style six */
section.banner-section.style-six .bg {
    position: absolute;
    top: 55px;
    right: 60px;
    bottom: 130px;
    left: 715px;
    background-size: cover;
}

.banner-section.style-six .content-box {
    max-width: 100%;
    padding-left: 140px;
}

/* Header style seven */

header.main-header.header-style-seven {
    top: 30px;
}

header.main-header.header-style-seven .auto-container {
    max-width: 100%;
    padding: 0 60px;
}

.main-header.header-style-seven .header-upper .inner-container {
    background: transparent;
    padding-left: 60px;
}

header.main-header.header-style-seven .mobile-nav-toggler.style-two {
    position: absolute;
    left: 0;
    top: 22px;
    cursor: pointer;
}

.header-style-seven .header-phone-number a {
    color: #fff;
}

.header-style-seven .sticky-header {
    background: #0b2848;
}

.mobile-nav-toggler.style-three {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 70px;
    background: #dfe4e9;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    cursor: pointer;
}

/* banner section three style three */
section.banner-section-three.style-three {
    margin: 0;
} 

section.banner-section-three.style-three .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 61%;
    background-size: cover;
}

section.banner-section-three.style-three .wrapper-box {
    padding: 220px 0 0;
}

.banner-section-three .video-box {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
}

section.banner-section-three .video-image {
    position: relative;
    display: inline-block;
    margin-top: 120px;
}

section.banner-section-three .video-image:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(150 11 11 / 90%);
}

.banner-section-three .video-btn {
    position: relative;
}

.banner-section-three .video-btn span {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    border-radius: 50%;
}

.banner-section-three .video-btn a {
    position: relative;
    height: 67px;
    width: 67px;
    line-height: 70px;
    border-radius: 50%;
    color: #111;
    text-align: center;
    font-size: 20px;
    display: inline-block;
    position: relative;
    background-color: #fff;
    transition: .5s;
    transition: .5s;
}

.banner-section-three .video-btn a:hover {
    color: #fff;
    background-color: var(--theme-color);
}

.banner-section-three .video-btn a:before {
    position: absolute;
    content: '';
    width: 92px;
    height: 92px;
    border-radius: 46px;
    border: 1px solid rgba(255,255,255,0.35);
    left: -12px;
    top: -12px;
}

.banner-section-three .video-btn .ripple:after {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-webkit-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.banner-section-three .video-btn .ripple:after {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
}

/* Header style eight */

.main-header.header-style-eight .auto-container {
    max-width: 100%;
    padding: 0 40px;
}

header.main-header.header-style-eight {
    top: 20px;
}

.main-header.header-style-eight .header-upper .inner-container {
    background: transparent;
    padding: 0;
}

.main-header.header-style-eight .main-menu .navigation>li>a {
    color: #fff;
}

.main-header.header-style-eight .header-phone-number a {
    color: #fff;
}

header.main-header.header-style-eight .sticky-header {
    background: #0b2848;
}

/* Banner style seven */

section.banner-section.style-seven {
    background: #060c19;
}

section.banner-section.style-seven .banner-outer-box {
    margin-left: 265px;
}

section.banner-section.style-seven .content-box {
    min-height: 950px;
    padding-bottom: 120px;
}

section.banner-section.style-seven h1.banner-title {
    font-weight: normal;
}

section.banner-section.style-seven .content-box .text {
    font-size: 22px;
}

.banner-section.style-seven .banner-slider-button-next {
    border-radius: 0;
    border-bottom: 1px solid #fff;
}

.banner-section.style-seven .banner-slider-button-prev {
    border-radius: 0;
    border-bottom: 1px solid #fff;
}

.banner-section.style-seven .banner-slider-nav.style-three {
    height: 136px;
}


/* Check availability style six */
.check-availability.style-six {
    position: relative;
    width: 100%;
    max-width: 1390px;
    background: #060c19;
}

.check-availability.style-six form.form {
    margin-bottom: 0;
    margin-right: 0;
    filter: none;
    background: #060c19;
    max-width: 980px;
}

.check-availability.style-six .left-side {
    padding-top: 40px;
    padding-bottom: 40px;
}

.check-availability.style-six button {
    border-radius: 0;
    padding: 49px 49px;
}

.check-availability.style-six input {
    background: transparent;
    color: #fff;
}

.check-availability.style-six p {
    color: var(--theme-color);
    text-transform: uppercase;
    letter-spacing: 0;
}

.check-availability.style-six .nice-select {
    background: transparent;
    color: #fff;
    min-width: 50px;
}

.check-availability.style-six li {
    min-width: 95px;
}

.check-availability.style-six .nice-select.open .list {
    width: 100%;
}

/* testimonials section style two */
section.testimonials-section.style-two:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 43%;
    background: #f5f6f7;
}

section.testimonials-section.style-two:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 125px;
    background: #f5f6f7;
}

/*  single-room-section */

section.single-room-section {
    padding-top: 115px;
    padding-bottom: 115px;
    position: relative;
}


/* room-details-section */

section.room-details-section {
    position: relative;
    padding-bottom: 100px;
    padding-top: 120px;
}

section.room-details-section .single-items-carousel {
    margin-bottom: 120px;
}


section.room-details-section .pricing {
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 35px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Glacial Indifference";
}

section.room-details-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 35px;
}

section.room-details-section .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 70px;
}

.block-six h3 {
    font-size: 35px;
    line-height: 1.2em;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 45px;
}

.block-six {
    border-radius: 8px;
    background-color: #f1f3f6;
    padding: 50px 45px 50px;
}

.block-six .theme-btn {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.block-seven {
    position: relative;
}

.block-six .theme-btn.dark-btn {
    background-color: #0e2a4e;
}

.block-seven {
    position: relative;
}

.block-seven .inner-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 55px;
    text-align: center;
}

.block-seven h3 {
    font-size: 35px;
    color: #ffffff;
    font-family: 'GlacialIndifference-Bold';
    margin-bottom: 15px;
}

.block-seven .inner-box span {
    font-size: 18px;
    text-decoration: underline;
    color: var(--theme-color);
    font-family: 'GlacialIndifference-Bold';
    text-transform: uppercase;
}

section.room-details-section ul.list-one {
    font-size: 20px;
    color: #575757;
    font-weight: 400;
    margin-bottom: 45px;
}

section.room-details-section ul.list-two {
    font-size: 20px;
    color: #575757;
    font-weight: 400;
    margin-bottom: 45px;
}

section.room-details-section ul.list-one li {
    margin-bottom: 10px;
}

section.room-details-section ul.list-two li {
    margin-bottom: 15px;
}

section.room-details-section .icon-box {
    background: #f1f3f6;
    padding: 70px 35px;
    border-radius: 8px;
}

section.room-details-section ul li i {
    font-size: 30px;
    margin-right: 20px;
    vertical-align: middle;
    position: relative;
    top: 3px;
}


section.room-details-section ul .flaticon-phone-ringing:before {
    font-weight: bold !important;
}

section.room-details-section ul .flaticon-facebook-logo:before {
    font-weight: bold !important;
}

section.room-details-section h4 {
    font-size: 28px;
    line-height: 60px;
    color: #0e2a4e;
    font-weight: 700;
    margin-bottom: 15px;
}

section.room-details-section .check {
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 47px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

section.room-details-section .check {
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 47px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

section.room-details-section .time {
    font-size: 18px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
}

section.room-details-section .subject {
    font-size: 18px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
}

section.room-details-section .age {
    font-size: 18px;
    line-height: 35px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 75px;
}

section.room-details-section h5 {
    font-size: 18px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: GlacialIndifference-Bold;
}

section.room-details-section table {
    width: 100%;
}

section.room-details-section td {
    background: #f7f7f7;
    font-size: 16px;
    color: #242424;
    font-weight: 400;
    border: 2px solid #ffff;
    padding: 13px 20px;
}


/* checkout-section */

section.checkout-section {
    padding-top: 115px;
    padding-bottom: 115px;
    position: relative;
    background-color: #f1f3f6;
}


/* History Section*/

.history-section {
    position: relative;
    padding-bottom: 90px;
    padding-top: 120px;
    background: #fff;
}

.history-block {
    position: relative;
    padding-bottom: 35px;
    padding-left: 173px;
}

.history-block .years {
    position: absolute;
    left: 0;
    top: 0;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 20px;
    background: var(--theme-color);
    font-size: 30px;
    color: #ffffff;
    font-family: GlacialIndifference-Bold;
}

.history-block:before {
    position:absolute;
    content: "";
    left: 55px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #d2d2d2;
}

.history-block:last-child:before {
    display: none;
}

.history-block h4 {
    position: relative;
    margin-bottom: 17px;
    font-family: 'GlacialIndifference-Bold';
    font-size: 30px;
    line-height: 1.2em;
    color: #0e2a4e;
}

.history-block .image-box {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 60px;
}

.history-block .image-box .image {
    position: relative;
    margin-bottom: 30px;
    margin-right: 30px;
}

.history-section h3 {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 28px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
    padding-left: 55px;
    margin-bottom: 10px;
}

.history-section h2 {
    font-size: 48px;
    line-height: 60px;
    color: #0e2a4e;
    font-weight: 800;
    margin-bottom: 35px;
}

.history-section h3:before {
    position: absolute;
    left: 0;
    bottom: 8px;
    width: 45px;
    height: 1px;
    background: var(--theme-color);
    content: '';
}

.history-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
}

/* blog section */

section.blog-section {
    padding-top: 120px;
    position: relative;
    padding-bottom: 120px;
}

section.blog-section .lower-content {
    padding: 35px 30px;
    margin-left: 40px;
    background-color: #ffffff;
    margin-top: -55px;
    position: relative;
}

section.blog-section .post-meta .date {
    font-size: 22px;
    letter-spacing: 0px;
    color: var(--theme-color);
    font-weight: 700;
}

section.blog-section .post-meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

section.blog-section .post-meta ul li {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 37px;
    color: #a3a3a3;
    font-weight: 400;
    margin-left: 45px;
}

section.blog-section h3 {
    font-size: 34px;
    color: #0e2a4e;
    font-weight: 800;
    font-family: "Playfair Display";
    padding-top: 35px;
    padding-bottom: 35px;
}

section.blog-section h3 a {
    color: #0e2a4e;
    transition: .5s;
}

section.blog-section h3 a:hover {
    color: var(--theme-color);
}

section.blog-section a.view-all-btn {
    font-size: 20px;
    line-height: 32px;
    color: var(--theme-color);
    text-decoration: underline;
    transition: .5s;
}

section.blog-section .blog-text {
    font-size: 20px;
    line-height: 32px;
    color: #0e2a4e;
    font-weight: 400;
    margin-bottom: 30px;
}

section.blog-section .inner-box {
    margin-bottom: 65px;
}

section.blog-section .inner-box .image {
    overflow: hidden;
}

section.blog-section .inner-box .image img {
    transition: .5s;
}

section.blog-section .inner-box:hover .image img {
    transform: scale(1.1);
}


/* gallery overlay */
.gallery-overlay{
    position: relative;
    overflow: hidden;
}
.gallery-overlay.b-radius-8 {
    overflow: hidden;
    border-radius: 8px;
}
.gallery-overlay .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	opacity:1;
	color:#ffffff;
	text-align:center;
	background:rgba(0,0,0,0.0);
}

.gallery-overlay  .overlay-box:before{
	content:'';
	position:absolute;
	left:0px;
	top:0px;
	width:50%;
	height:100%;
	opacity:0;
	background: rgb(14 24 36 / 70%);
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
	-webkit-transform:translateY(-100%);
	-ms-transform:translateY(-100%);
	-o-transform:translateY(-100%);
	-moz-transform:translateY(-100%);
	transform:translateY(-100%);
}

.gallery-overlay .overlay-box:after{
	content:'';
	position:absolute;
	right:0px;
	top:0px;
	width:50%;
	height:100%;
	opacity:0;
	background: rgb(13 23 35 / 70%);
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
	-webkit-transform:translateY(100%);
	-ms-transform:translateY(100%);
	-o-transform:translateY(100%);
	-moz-transform:translateY(100%);
	transform:translateY(100%);
}

.gallery-overlay .inner-box:hover .overlay-box:before,
.gallery-overlay .inner-box:hover .overlay-box:after{
	opacity:1;
	-webkit-transform:translateY(0%);
	-ms-transform:translateY(0%);
	-o-transform:translateY(0%);
	-moz-transform:translateY(0%);
	transform:translateY(0%);
}

.gallery-overlay .inner-box img {
    width: 100% !important;
}

.gallery-overlay .overlay-inner{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:table;
	vertical-align:middle;
	padding:10px 0px;
	z-index:1;
}

.gallery-overlay .overlay-inner .content{
	position:relative;
	display:table-cell;
	vertical-align:middle;
}

.gallery-overlay .overlay-inner .link{
	position:relative;
	font-size:18px;
	color:#ffffff;
	width:50px;
	height:50px;
	line-height:50px;
	border-radius:50%;
	display:inline-block;
	background-color: var(--theme-color);
	opacity:0;
	-webkit-transform:scale(0.5);
	-ms-transform:scale(0.5);
	transform:scale(0.5);
}

.gallery-overlay .inner-box:hover .overlay-inner .link{
	opacity:1;
	transition:all 500ms ease 500ms;
	-webkit-transition:all 500ms ease 500ms;
	-ms-transition:all 500ms ease 500ms;
	-o-transition:all 500ms ease 500ms;
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1);	
}

.gallery-overlay .overlay-inner .link .icon{
	position:relative;
}


.filter-tabs {
    padding: 0;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
}

.filter-tabs li {
    border-bottom: 1px solid transparent;
    margin: 0 6px 10px;
    cursor: pointer;
    background: #f7f7f7;
    padding: 10px 15px;
    min-width: 90px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 26px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Roboto";
    text-align: center;
    position: relative;
    transition: .5s;
}

.filter-tabs li.active {
    color: #fff;
    background-color: #0e2a4e;
}

.filter-tabs li:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -8px;
    width: 20px;
    height: 10px;
    border-radius: 9px;
    background-color: #0e2a4e;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    margin: 0 auto;
    right: 0;
    opacity: 0;
    transition: .5s;
}

.filter-tabs li.active:before {
    opacity: 1;
}

.gallery-overlay .inner-box {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.gallery-block-four{
    margin-bottom: 30px;
}

/* filter style two */
.style-two .filter-tabs li {
    background: transparent;
    padding: 0;
    margin: 0 20px;
    min-width: auto;
}

.style-two .filter-tabs li.active {
    background: transparent;
    color: var(--theme-color);
    text-decoration: underline !important;
}

.style-two .filter-tabs li:before {
    opacity: 0;
}

/* error section */

section.error-section {
    padding-top: 240px;
    padding-bottom: 235px;
    position: relative;
    background-size: cover;
}

.error-section h1 {
    position: relative;
    font-size: 200px;
    font-weight: 800;
    line-height: 1em;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
}

.error-section h1 {
    color: #ffffff;
}

.error-section h2 {
    position: relative;
    color: #ffffff;
    font-size: 66px;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 10px;
}


.error-section .text {
    position: relative;
    color: #ffffff;
    font-size: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 50px;
}

/* contact Form */

section.contact-form-section {
    padding-top: 115px;
    padding-bottom: 120px;
    position: relative;
}


@media (max-width:700px){
  section.contact-form-section h2 {
    font-size: 32px;
    color: #0e2a4e;
    font-weight: 800;
    font-family: "Playfair Display";
}
}
@media (min-width:700px){
  section.contact-form-section h2 {
    font-size: 48px;
    color: #0e2a4e;
    font-weight: 800;
    font-family: "Playfair Display";
}
}

section.contact-form-section .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-top: 35px;
}

.contact-form{
    position:relative;
}

.contact-form .form-group{
    position:relative;
    margin-bottom:30px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="password"],
.contact-form select,
.contact-form textarea{
    display:block;
    width:100%;
    line-height:28px;
    height: 56px;
    font-size:16px;
    padding:10px 22px;
    background:#ffffff;
    color:#000000;
    border:1px solid #dddddd;
    transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.contact-form textarea{
    height:208px;
    resize:none;
    font-size:16px;
    background:#ffffff;
}

.contact-form .theme-btn{
    margin-top: 30px;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error{
    border-color:#ff0000 !important;    
}

.contact-form label.error{
    display:block;
    line-height:24px;
    padding:5px 0px 0px;
    margin:0px;
    text-transform:uppercase;
    font-size:11px;
    color:#ff0000;
    font-weight:500;    
}

section.contact-form-section h4 {
    font-size: 24px;
    line-height: 31px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 15px;
}

section.contact-form-section .border-shap {
    width: 50px;
    height: 1px;
    background-color: #0e2a4e;
    margin-bottom: 35px;
}

@media (max-width:700px){
  section.contact-form-section .text-two {
    margin-left: 18px;
    font-size: 18px;
    line-height: 26px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 15px;
}
}
@media (min-width:700px){
  section.contact-form-section .text-two {
    margin-left: 45px;
    font-size: 18px;
    line-height: 26px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 15px;
}
}

section.contact-form-section .text-three {
    margin-left: 45px;
    font-size: 18px;
    line-height: 26px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 40px;
}

section.contact-form-section .text-three a {
    transition: .5s;
    color: #797979;
}

section.contact-form-section .text-three a:hover {
    color: var(--theme-color);
}

@media (max-width:700px){
  section.contact-form-section .inner-box {
    border-bottom: 1px solid #d4d4d4;
    width: 300px;
    margin-left: -2px;
    margin-bottom: 40px;
}
}
@media (min-width:700px){
  section.contact-form-section .inner-box {
    border-bottom: 1px solid #d4d4d4;
    width: 300px;
    margin-left: 70px;
    margin-bottom: 40px;
}
}

section.contact-form-section .text-four {
    margin-left: 45px;
    font-size: 18px;
    line-height: 31px;
    color: #797979;
    font-weight: 400;
}

@media (max-width:700px){
  section.contact-form-section h5 {
    font-size: 22px;
    line-height: 42px;
    color: #959595;
    font-weight: 700;
    padding-left: 18px;
    font-family: GlacialIndifference-Bold;
}
}
@media (min-width:700px){
  section.contact-form-section h5 {
    font-size: 22px;
    line-height: 42px;
    color: #959595;
    font-weight: 700;
    padding-left: 45px;
    font-family: GlacialIndifference-Bold;
}
}

section.contact-form-section h5 a {
    transition: .5s;
    color: #797979;
}

section.contact-form-section h5 a:hover {
    color: var(--theme-color);
}

section.contact-form-section .icon i {
    position: absolute;
    left: 9px;
    top: 10px;
    color: var(--theme-color);
    transform: rotate(90deg);
    font-size: 17px;
}

@media (max-width:700px){
  section.contact-form-section .icon-box {
    margin-left: -2px;
}
}
@media (min-width:700px){
  section.contact-form-section .icon-box {
    margin-left: 70px;
}
}

/* blog with sidebar section */

.sidebar-page-container {
    padding-top: 115px;
    position: relative;
    padding-bottom: 120px;
}

.sidebar-widget.search-box {
    margin-bottom: 30px;
    position: relative;
}

.sidebar-widget.search-box button {
    position: absolute;
    right: 15px;
    top: 17px;
    background: transparent;
    color: #7e7e7e;
    font-size: 15px;
    transition: .5s;
}

.sidebar-widget.search-box button:hover {
    color: var(--theme-color);
}

.sidebar-widget.search-box input[type="search"] {
    font-size: 18px;
    color: #909090;
    font-weight: 400;
    width: 100%;
    position: relative;
    margin: 0px;
    background-color: #ffffff;
    border: 1px solid #d3d3d3;
    padding: 16px 20px;
}

.sidebar-widget h2 {
    font-size: 28px;
    line-height: 28px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 25px;
    position: relative;
    display: inline-block;
}

.sidebar-widget h2:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 1px;
    background-color: #0e2a4e;
    bottom: 3px;
    right: -35px;
}

.cat-list li a {
    position: relative;
    color: #797979;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: block;
    border-bottom: 1px solid #e6e6e6;
    transition: .5s;
}

.cat-list li a:hover {
    color: var(--theme-color);
}

.sidebar-widget.sidebar-blog-category ul.cat-list {
    margin-bottom: 75px;
}

.sidebar .popular-posts .post .post-thumb {
    position: absolute;
    left: 0px;
    top: 4px;
    width: 70px;
    background-color: #000;
    overflow: hidden;
}

.sidebar .popular-posts .post {
    position: relative;
    font-size: 14px;
    color: #666666;
    padding: 0px 0px;
    padding-left: 90px;
    min-height: 85px;
    margin-bottom: 20px;
    border-bottom: 1px
   
}

.sidebar-widget.popular-posts .text {
    font-size: 18px;
    line-height: 20px;
    color: #0e2a4e;
    font-weight: 400;
    margin-bottom: 5px;
}

.sidebar-widget.popular-posts a {
    color: #0e2a4e;
    transition: .5s;
}

.sidebar-widget.popular-posts .post-info {
    font-size: 14px;
    line-height: 28px;
    color: #9a9a9a;
    font-weight: 500;
    font-family: "Poppins";
}

.sidebar .popular-posts .post a:hover {
    color: #ee0d09;
    text-decoration: underline !important;
}

.sidebar-widget.popular-posts {
    margin-bottom: 60px;
}

.sidebar .popular-tags a {
    position: relative;
    display: inline-block;
    line-height: 24px;
    padding: 6px 15px 6px;
    min-width: 100px;
    margin: 0px 6px 10px 0px;
    color: #a6a6a6;
    text-align: center;
    font-size: 18px;
    background: none;
    font-weight: 400;
    border: 1px
    solid #e4e4e4;
    text-transform: capitalize;
    transition: .5s;
}

.sidebar .popular-tags a:hover {
    border-color: #172b55;
    background-color: #172b55;
}
.sidebar .popular-tags a:hover {
    color: #ffffff;
}

.news-block-two .image {
    overflow: hidden;
    max-width: 840px;
}

.news-block-two .image img {
    transition: .5s;
}

.news-block-two .inner-box:hover .image img {
    transform: scale(1.1);
}

.news-block-two .lower-content {
    padding: 35px 30px;
    margin-left: 40px;
    background-color: #ffffff;
    margin-top: -55px;
    position: relative;
}


.news-block-two ul li {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 37px;
    color: #a3a3a3;
    font-weight: 400;
    margin-left: 45px;
    
}

.news-block-two .date {
    font-size: 22px;
    letter-spacing: 0px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: GlacialIndifference-Bold;
}

.news-block-two .post-meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.news-block-two h3 a {
    color: #0e2a4e;
    transition: .5s;
}

.news-block-two h3 a:hover {
    color: var(--theme-color);
}

.news-block-two h3 {
    font-size: 34px;
    color: #0e2a4e;
    font-weight: 800;
    font-family: "Playfair Display";
    padding-top: 30px;
    padding-bottom: 25px;
}

.news-block-two .blog-text {
    font-size: 20px;
    line-height: 32px;
    color: #0e2a4e;
    font-weight: 400;
    margin-bottom: 30px;
}

.news-block-two a.view-all-btn {
    font-size: 20px;
    line-height: 32px;
    color: var(--theme-color);
    text-decoration: underline;
    transition: .5s;
}

/* blog details section */

.sidebar-page-container .blog-text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 40px;
}

.sidebar-page-container .blog-text-three{
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 40px;

}

.sidebar-page-container .blog-text-four{
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 40px;
}

.sidebar-page-container .blog-text-five{
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
    margin-bottom: 40px;
}

.sidebar-page-container .group-title {
    position: relative;
    margin-bottom: 28px;
}

.comment-form .form-inner {
    position: relative;
    padding: 50px 35px 30px;
    background-color: #f7f7f7;
    border: 1px
    solid #eeeeee;
}

.comment-form {
    position: relative;
    margin-left: 70px;
}

.comment-form .form-group textarea {
    position: relative;
    display: block;
    width: 100%;
    line-height: 24px;
    padding: 20px 20px;
    color: #a5a5a5;
    height: 170px;
    font-size: 15px;
    resize: none;
    background-color: #ffffff;
    border: 1px
    solid #eeeeee;
}

.comment-form .form-group input[type="text"], .comment-form .form-group input[type="email"], .comment-form .form-group select {
    position: relative;
    display: block;
    width: 100%;
    line-height: 33px;
    padding: 10px 20px;
    height: 45px;
    color: #a5a5a5;
    font-size: 15px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
}

.comment-form .form-group {
    position: relative;
    margin-bottom: 25px;
}

.comment-form h2 {
    font-size: 30px;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
}

.calendar {
    position: relative;
    margin-left: -10px;
    margin-bottom: 50px;
}

/* services section four */

section.service-section-four {
    padding-top: 115px;
    padding-bottom: 0;
    position: relative;
}

section.service-section-four .service-title {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 47px;
    color: var(--theme-color);
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}

section.service-section-four .text {
    font-size: 24px;
    color: #0e2a4e;
    margin-bottom: 70px;
}

.service-block-three .content {
    background: #f1f3f5;
    padding: 95px 109px 67px 95px;
    margin-left: -160px;
    margin-top: 70px;
    position: relative;
    z-index: 5;
    margin-bottom: 115px;
    min-height: 585px;
}

.service-block-three .content:before {
    position: absolute;
    content: '';
    top: -12px;
    right: 0px;
    bottom: 0px;
    left: -12px;
    background-color: rgb(255 255 255 / 28%);
    z-index: -1;
}

.service-block-three .link-btn {
    position: absolute;
    right: -21px;
    bottom: -20px;
    padding: 20px 20px;
}

.service-block-three .text-two {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    font-weight: 400;
}

.service-block-three .time {
    font-size: 20px;
    line-height: 30px;
    color: var(--theme-color);
    font-weight: 400;
    margin-top: 35px;
}

.service-block-three .image {
    margin-right: -25px;
    overflow: hidden;
}

.service-block-three .image img {
    transition: .5s;
}

.service-block-three .image:hover img {
    transform: scale(1.1);
}

@media only screen and (min-width:992px) {
    
    .service-block-three:nth-child(even) .image-column {
        order: 2;
    }
    
    .service-block-three:nth-child(even) .content {
        margin-left: 0;
        margin-right: -160px;
    }
    
    .service-block-three:nth-child(even) .link-btn {
        right: auto;
        left: -20px;
    }
    
}



.owl-dot-style-one .owl-dots {
	position: relative;
	text-align: center;
    display: block;
}

.owl-dot-style-one .owl-dots .owl-dot {
	position: relative;
	display: inline-block;
	margin: 0px 5px;
}

.owl-dot-style-one .owl-dots .owl-dot span {
	position: relative;
	display: block;
	width: 14px;
	height: 14px;
	border: 2px solid #bababa;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	border-radius: 50%;
}

.owl-dot-style-one .owl-dots .owl-dot.active span,
.owl-dot-style-one .owl-dots .owl-dot:hover span {
	background: var(--theme-color);
	border-color: var(--theme-color);
}

section.testimonials-section .owl-dot-style-one .owl-dots {
    text-align: right;
    margin-top: -30px;
}

section.testimonials-section-two .owl-dot-style-one .owl-dots {
    position: absolute;
    right: 0;
    bottom: -15px;
}

section.testimonials-section-two .owl-dot-style-one .owl-dots .owl-dot span {
    border-color: #bababa;
}

section.testimonials-section-two .owl-dot-style-one .owl-dots .owl-dot.active span,
section.testimonials-section-two .owl-dot-style-one .owl-dots .owl-dot:hover span {
	background: var(--theme-color);
	border-color: var(--theme-color);
}

section.feature-section-three.dark_bg .feature-block-two ul.list-two li {
    color: #9b9faa;
}

.featured-block {
    position: relative;
}

.featured-block .image {
    position: relative;
    margin-bottom: 35px;
}

.featured-block img {
    border-radius: 8px;
}

/*Comming Soon*/

.comming-soon{
    position:fixed;
    width:100%;
    height:100%;
    display:block;
    overflow-y:auto;
    background-size:cover;
}

.comming-soon .content{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: table;
    text-align:center;
    vertical-align: middle;
}

.comming-soon .content .content-inner{
    position: relative;
    padding: 50px 15px;
    display: table-cell;
    vertical-align: middle;
}

.comming-soon .content .content-inner h2{
    position:relative;
    color:#ffffff;
    font-size: 80px;
    font-weight: 700;
    line-height:1.2em;
    margin-bottom:80px;
}

.comming-soon .content .content-inner .text{
    position:relative;
    color:#ffffff;
    font-size: 28px;
    line-height:1.6em;
    max-width: 900px;
    margin:0 auto;
    margin-top: 20px;
    margin-bottom:50px;
    display:inline-block;
    text-transform: capitalize;
    font-family: GlacialIndifference-Bold;
}

.comming-soon .content .content-inner .text span {
    font-family: "Playfair Display", serif;
    font-style: italic;
}

/*** 

====================================================================
    Countdown style
====================================================================

***/

.time-counter{
    position:relative;
}

.time-counter .time-countdown{
    position:relative;
}

.time-counter .time-countdown .counter-column{
    position:relative;
    margin:0px 15px 0px;
    width: 180px;
    height: 180px;
    text-align:center;
    display:inline-block;
    padding:20px 0px 10px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size:20px;
    line-height:30px;
    text-transform:uppercase;
    border-radius:50%;
    font-weight:600;
    margin-bottom:30px;
}

.time-counter .time-countdown .counter-column .count{
    position:relative;
    display:block;
    font-size:60px;
    line-height:40px;
    padding: 28px 10px 20px;
    color: #ffffff;
    letter-spacing:1px;
    font-weight:600;
}

.comming-soon .emailed-form .form-group{
    position:relative;
    display:block;
    max-width: 770px;
    margin:0 auto;
    width:100%;
    margin-bottom:20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.comming-soon .emailed-form .form-group input[type="text"],
.comming-soon .emailed-form .form-group input[type="tel"],
.comming-soon .emailed-form .form-group input[type="email"],
.comming-soon .emailed-form .form-group textarea{
    position:relative;
    display:block;
    width: calc(100% - 240px);
    line-height:20px;
    height: 76px;
    font-weight: 500;
    font-size: 18px;
    color: #242424;
    padding: 10px 36px;
    background:#ffffff;
    -webkit-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    transition:all 300ms ease;
    font-family: "Poppins", sans-serif;
}

.comming-soon .emailed-form .form-group input[type="submit"],
.comming-soon .emailed-form button{
    position: relative;
    right:0px;
    top:0px;
    padding: 25px 40px;
}

/* check-availability.style-seven */

.check-availability.style-seven {
    background: #f1f3f6;
    padding: 55px 40px 70px;
}

.check-availability.style-seven form.form {
    margin: 0;
    filter: none;
    padding: 0;
    background: transparent;
}

.check-availability.style-seven .left-side {
    padding: 0;
    width: 100%;
}

.check-availability.style-seven .left-side>ul>li {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-right: 5px;
}

.check-availability.style-seven input {
    background: transparent;
    padding-bottom: 10px;
}

.check-availability.style-seven .nice-select {
    width: 100%;
    background: transparent;
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 32px;
    color: #575757;
    font-weight: 600;
    font-family: "Poppins";
    text-transform: uppercase;
}

.check-availability.style-seven button {
    border-radius: 3px;
    width: 100%;
    padding: 12px 0;
    margin-top: 35px;
}

.check-availability.style-seven .right-side {
    width: 100%;
}

.check-availability.style-seven p {
    text-transform: uppercase;
}

.check-availability.style-seven h3 {
    font-size: 35px;
    line-height: 1.2em;
    color: #0e2a4e;
    font-weight: 700;
    font-family: "Playfair Display";
    margin-bottom: 45px;
}

/* map section */

section.map-section-two {position: relative;padding: 120px 0 0;}

section.map-section-two .auto-container {
    max-width: 100%;
    padding: 0;
}


/*** 

====================================================================
    Styled Pagination
====================================================================

***/

.styled-pagination{
    position:relative;
    margin-top: -40px;
    margin-left: 70px;
}

.sidebar-page-container .styled-pagination {
    margin-top: 50px;
}

.styled-pagination li{
    position:relative;
    display:inline-block;
    margin:0px 20px 0px 0px !important;
    vertical-align: middle;
}

.styled-pagination.text-center li {
    margin: 0 10px !important;
}

.styled-pagination li a{
    position:relative;
    display:inline-block;
    line-height: 45px;
    height: 47px;
    font-size:16px;
    min-width: 48px;
    color: #081336;
    font-weight:700;
    text-align:center;
    background:#ffffff;
    background-color: #ffffff;
    border: 2px solid rgba(14,42,78,0.34);
    text-transform:capitalize;
    transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    -moz-transition:all 500ms ease;
}

.styled-pagination li a:hover,
.styled-pagination li a.active{
    color:#ffffff;
    border-color: var(--theme-color);
    background-color: var(--theme-color);
}

.styled-pagination li a span {
    font-size: 20px;
    line-height: 50px;
}

.room-block-two.masonry-item {
    margin-bottom: 95px;
}

.title-desc {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
}

.title-desc.text-light {
    color: #fff;
}

/* Owl nav style one */
.owl_nav_style_one.owl-theme .owl-nav {
    position: absolute;
    top: -80px;
    right: -15px;
    margin: 0 auto;
    width: 100%;
    display: block;
}

.owl_nav_style_one.owl-theme .owl-nav .owl-prev {
    position: absolute;
    right: 85px;
    top: 0;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border: none;
    text-align: center;
    line-height: 56px;
    font-size: 0px;
    opacity: 1;
    margin-top: -30px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.owl_nav_style_one.owl-theme .owl-nav .owl-prev:before {
    font-family: "Flaticon";
    content: "\f110";
    position: absolute;
    top: 0;
    width: 56px;
    height: 56px;
    line-height: 54px;
    border: 2px solid #a8a8a8;
    left: 0;
    border-radius: 8px;
    background: #fff;
    color: #9ca6b6;
    font-size: 20px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.owl_nav_style_one.owl-theme .owl-nav .owl-next {
    position: absolute;
    right: 15px;
    top: 0;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border: none;
    text-align: center;
    line-height: 60px;
    color: rgba(0, 0, 0, 0);
    font-size: 0px;
    opacity: 1;
    margin-top: -30px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.owl_nav_style_one.owl-theme .owl-nav .owl-next:before {
    font-family: "Flaticon";
    content: "\f110";
    position: absolute;
    top: 0;
    width: 56px;
    height: 56px;
    line-height: 54px;
    border: 2px solid #a8a8a8;
    right: 0;
    color: #9ca6b6;
    border-radius: 8px;
    background: #fff;
    font-size: 20px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.owl_nav_style_one.owl-theme .owl-nav .owl-prev:hover:before,
.owl_nav_style_one.owl-theme .owl-nav .owl-next:hover:before {
    background-color: var(--theme-color);
    color: #fff;
    border-color: var(--theme-color)
}


/* Owl nav style two */
.owl_nav_style_two.owl-theme .owl-nav {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 30px;
}

.owl_nav_style_two.owl-theme .owl-nav .owl-prev {
    position: relative;
    right: 0;
    top: 0;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border: none;
    text-align: center;
    line-height: 56px;
    font-size: 0px;
    opacity: 1;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    margin-right: 6px;
}

.owl_nav_style_two.owl-theme .owl-nav .owl-prev:before {
    font-family: "Flaticon";
    content: "\f110";
    position: absolute;
    top: 0;
    width: 56px;
    height: 56px;
    line-height: 54px;
    border: 2px solid #a8a8a8;
    left: 0;
    border-radius: 8px;
    background: #fff;
    color: #9ca6b6;
    font-size: 20px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.owl_nav_style_two.owl-theme .owl-nav .owl-next {
    position: relative;
    top: 0;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border: none;
    text-align: center;
    line-height: 60px;
    color: rgba(0, 0, 0, 0);
    font-size: 0px;
    opacity: 1;
    margin-top: -30px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    margin-left: 6px;
}

.owl_nav_style_two.owl-theme .owl-nav .owl-next:before {
    font-family: "Flaticon";
    content: "\f110";
    position: absolute;
    top: 2px;
    width: 56px;
    height: 56px;
    line-height: 54px;
    border: 2px solid #a8a8a8;
    right: 0;
    color: #9ca6b6;
    border-radius: 8px;
    background: #fff;
    font-size: 20px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.owl_nav_style_two.owl-theme .owl-nav .owl-prev:hover:before,
.owl_nav_style_two.owl-theme .owl-nav .owl-next:hover:before {
    background-color: var(--theme-color);
    color: #fff;
    border-color: var(--theme-color)
}

.owl_nav_style_two.owl-theme.nav-light .owl-nav .owl-prev:before,
.owl_nav_style_two.owl-theme.nav-light .owl-nav .owl-next:before {
    border-color: #fff;
}

.owl_nav_style_two.owl-theme.nav-light .owl-nav .owl-prev:hover:before,
.owl_nav_style_two.owl-theme.nav-light .owl-nav .owl-next:hover:before {
    border-color: var(--theme-color)
}

.blog-post-share-icon {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 70px;
    margin-bottom: 60px;
}

.blog-post-share-icon ul.social-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.blog-post-share-icon ul.social-icon li {
    margin-right: 10px;
    margin-left: 0;
}

.blog-post-share-icon .share-icon {
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 46px;
    border: 2px solid #ddd;
    color: #a5a5a5;
    position: relative;
    margin-right: 44px;
}

.blog-post-share-icon .share-icon:before {
    position: absolute;
    content: '';
    top: 17px;
    right: -6px;
    width: 12px;
    height: 12px;
    border: 2px solid #ddd;
    transform: rotate(45deg);
    background: #fff;
    border-left-color: #fff;
    border-bottom-color: #fff;
}

.blog-post-share-icon ul.social-icon li a {
    padding: 14px 20px;
    color: #fff;
    background: var(--theme-color);
    transition: .5s;
}

.blog-post-share-icon ul.social-icon li a:hover {
    opacity: .9;
}

.blog-post-share-icon ul.social-icon li.twitter a {
    background-color: #4cc5f3;
}

.blog-post-share-icon ul.social-icon li.facebook a {
    background-color: #758ab7;
}

.blog-post-share-icon ul.social-icon li.google-plus a {
    background-color: #e78074;
}

.blog-post-share-icon ul.social-icon li.linkedin a {
    background-color: #4ca2cc;
}


section.banner-section:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -100px;
    width: 100%;
    background: #222;
    height: 100px;
    background: -moz-linear-gradient(0deg, rgba(238, 130, 238, 0) 0%, rgba(9, 16, 37, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:
    -webkit-linear-gradient(0deg, rgba(238, 130, 238, 0) 0%, rgba(9, 16, 37, 1) 100%);

    /* opera 11.10+ */
    background:
    -o-linear-gradient(0deg, rgba(238, 130, 238, 0) 0%, rgba(9, 16, 37, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EE82EE', endColorstr='#091025', GradientType=1 );

    /* ie 10+ */
    background:
    -ms-linear-gradient(0deg, rgba(238, 130, 238, 0) 0%, rgba(9, 16, 37, 1) 100%);

    /* global 94%+ browsers support */
    background:
    linear-gradient(0deg, rgba(238, 130, 238, 0) 0%, rgba(9, 16, 37, 1) 100%);
}

.amenities-icon{
  margin-bottom: 15px;
}

.icon-name {
  font-size: 22px;
  line-height: 21px;
  color: #0e2a4e;
  font-family: 'GlacialIndifference-Regular';
  margin-bottom: 15px;
}
/* Initially hide the post-meta content */
/* .news-block .lower-content .hidden-content .post-meta {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
} */

/* Increase height and display on hover */
/* .news-block .lower-content:hover .hidden-content .post-meta {
  height: 150px;
  opacity: 1;
  overflow: hidden;
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
} */
.contact-widget{
  position: relative;
}
.widget{
  padding: 3px;
}
.widget-title::before{
  content: "";
  position: absolute;
  bottom:  0;
  left: 0;
  height: 2px;
  margin-left: 8px;
  background-color: var(--theme-color);
  width: 20px;
}
.package-title{
  font-size: 27px !important;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #EABC4C !important;
    font-family: 'Playfair Display', serif;
    text-shadow: 0 0 0.25px currentcolor;
    font-size: 32px;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #EABC4C !important;
    pointer-events: none;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-tabs .ant-tabs-tab-btn {
  outline: none;
  font-family: 'Playfair Display', serif;
  font-size: 32px;
  transition: all 0.3s;
}
.logo-para{
  color: rgb(255 255 255 / 60%) !important;
    transition: .5s !important;
}
.seo-para{
  display: none;
}
.image-title{
  display: flex;
  text-align: center;
  font-weight: 20px;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  color: var(---title-color);
  justify-content: center;
  align-items: center;
}
/* .main-footer{
  background-color: black !important;
} */
.cuisine-slider{
  font-weight: 20px;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  color: var(---title-color);

}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, -6px) !important;
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}

a {
  text-decoration: none;
}
.news-block .lower-content {
  padding: 25px 35px 40px;
  margin-left: 25px;
  z-index: 10 !important;
  filter: drop-shadow(0px 12px 12.5px rgba(0,0,0,0.04));
  background-color: #ffffff;
  margin-top: -50px;
  border-radius: 10px;

}
.lower-content {
  position: relative;
  z-index: 1; /* Set the desired z-index value */
}

/* Add z-index to specific child elements if needed */
.lower-content .date,
.lower-content h3,
.lower-content .post-meta,
.lower-content .link-btn {
  position: relative;
  z-index: 1; /* Set the desired z-index value */
}
.main-footer{
  background-color: #f8f9fa;
}

.form-control{
  line-height: 2.5 !important;
}
.custom-list-style li {
  list-style-type: circle;
  margin-left: 19px;
}
.custom-list-styles li {
  list-style-type: circle;
  /* margin-left: 19px; */
}
ul {
  padding-left: 0rem !important;
}
.amenities-text{
  font-family: 'Playfair Display', serif !important;
  font-size: 20px !important;
      margin-top: 20px !important;
  color: black;
}
.footer{
  /* .auto-container { */
    position: static;
    max-width: 1510px !important;
    padding: 0px 15px;
    margin: 0 auto;
/* } */
}
.scroll4::-webkit-scrollbar {
  width: 10px;
}
 
.scroll4::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

.scroll4::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}
@media (max-width:700px){
  .book-nows{
    padding: 20px 0px 0px 0px;
  }
}
@media (min-width:700px){
  .book-nows{
    padding: 100px 0px 0px 0px;
  }
}
.standard-package-tile{
  padding-left: 8px;
}
@media (max-width:991px){
  .news-block .lower-content {
    height:  628px;
    /* overflow-y: scroll; */
      padding: 25px 35px 40px;
      margin-left: 25px;
      filter: drop-shadow(0px 12px 12.5px rgba(0,0,0,0.04));
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 10px;
  
  }
}
@media (max-width:991px){
  .book-nows{
    padding-top: 20px;
  }
}
@media (max-width:364px){
  .news-block .lower-content{
    height: 735px;
  }
}