:where(.css-dev-only-do-not-override-dkbvqv).ant-collapse {
    box-sizing: border-box;
    font-family: GlacialIndifference-Regular !important;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 8px;
}